import {Component, EventEmitter, Input, Output} from "@angular/core";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {colors} from "../../../core/const/colors";

@Component({
  selector: 'app-color-picker',
  templateUrl: './colorPicker.component.html',
  styleUrls: ['./colorPicker.component.scss']
})
export class ColorPickerComponent {
  _color: string;
  isOpen = false;
  isDefaultOpen = false;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  colors = colors;

  @Input() set color(value: string) {
    this._color = value;
  }

  @Output() colorChanged: EventEmitter<string> = new EventEmitter();

  togglePicker() {
    if (this.isOpen) this.colorChanged.emit(this._color);
    this.isOpen = !this.isOpen;
    this.isDefaultOpen = false;
  }

  toggleCustom() {
    this.isDefaultOpen = !this.isDefaultOpen;
  }

  setColor(color: string) {
    this._color = color;
    this.isOpen = false;
    this.isDefaultOpen = false;
    this.colorChanged.emit(color);
  }
}
