import { Component, Input } from "@angular/core";
import { WorkbookService } from "../../../../core/service/workbook.service";
import { Subscription } from "rxjs";
import {WorkbookActions} from "../../../../actions/workbook.actions";
import {IChapter, IChapterCategory} from "../../../../core/interfaces/IWorkbook";
import {StoreService} from "../../../../core/service/store.service";
import {colors} from "../../../../core/const/colors";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-category-editor',
  templateUrl: './categoryEditor.component.html',
  styleUrls: ['./categoryEditor.component.scss']
})
export class CategoryEditorComponent {
  _id: string;
  chapters: IChapter[];
  category: IChapterCategory;
  catModel: FormGroup;
  colors = colors;

  @Input() disabled: boolean;
  @Input() set id (value: string) {
    this._id = value;

    this.chapters = this.storeService.getData('workbookReducer', 'chapters').filter((chapter: IChapter) => {
      return chapter.parentId == value;
    });

    this.category = this.storeService.getData('workbookReducer', 'categories').filter((cat: IChapterCategory) => {
      return cat.id == value;
    })[0];

    if (this.category) {
      this.catModel.controls['title'].setValue(this.category.title);
      this.catModel.controls['description'].setValue(this.category.description);
    }

    this.workbookActions.changeCatProp({ id: this._id, prop: 'valid', value: this.catModel.valid });
  }
  @Input() index: number;

  chaptersSubscription: Subscription;
  categorySubscription: Subscription;

  formatOrderPos(pos: number): string {
    return pos < 9
      ? '0' + (pos*1 + 1)
      : '' + (pos*1 + 1);
  }

  get availPos(): string[] {
    return Object.keys(this.storeService.getData('workbookReducer', 'categories'))
      .filter((key: string) => {
        return key != '' + this.category.orderPosition;
      });
  }

  constructor(
    private workbookService: WorkbookService,
    private workbookActions: WorkbookActions,
    private storeService: StoreService,
    private _fb: FormBuilder
  ) {
    this.catModel = _fb.group({
      title: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(255),
      ])],
      description: [''],
    });

    this.chaptersSubscription = this.storeService.getDataSubscription('workbookReducer', 'chapters')
      .subscribe((chapters: IChapter[]) => {
        this.chapters = chapters.filter((chapter: IChapter) => chapter.parentId == this._id);
      });

    this.categorySubscription = this.storeService.getDataSubscription('workbookReducer', 'categories')
      .subscribe((categories: IChapterCategory[]) => {
        this.category = categories.filter((cat: IChapterCategory) => cat.id == this._id)[0];
      });
  }

  catColorChanged($event):void {
    this.workbookActions.changeCatProp({ id: this._id, prop: 'backgroundColor', value: $event });
  }

  changeCatTitle($event):void {
    this.workbookActions.changeCatProp({ id: this._id, prop: 'title', value: $event.target.value });
    this.workbookActions.changeCatProp({ id: this._id, prop: 'valid', value: this.catModel.valid });
  }

  changeDescription($event): void {
    this.workbookActions.changeCatProp({ id: this._id, prop: 'description', value: $event });
    this.catModel.controls['description'].setValue($event);
    this.workbookActions.changeCatProp({ id: this._id, prop: 'valid', value: this.catModel.valid });
  }

  changeOrderPosition(value): void {
    this.workbookService.changeCatOrderPosition(this.category.orderPosition, value);
  }

  addChapter():void {
    let cat = this.storeService.getData('workbookReducer', 'categories').filter((category: IChapterCategory) =>
        category.id == this._id
    )[0];

    let subscription = this.workbookService.createChapter(cat.id).subscribe(() => {
      subscription.unsubscribe();
    });
  }

  deleteCategory() {
    this.workbookService.deleteCategory(this._id);
  }

  ngOnDestroy() {
    this.categorySubscription.unsubscribe();
    this.chaptersSubscription.unsubscribe();
  }
}
