import { Component, Input } from "@angular/core";
import { IChapter } from "../../../../core/interfaces/IWorkbook";
import { StoreService } from "../../../../core/service/store.service";
import { Subscription } from "rxjs";
import { WorkbookActions } from "../../../../actions/workbook.actions";
import { actionsOptions } from "./actionsOptions";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { WorkbookService } from "../../../../core/service/workbook.service";
import { chapterTypes } from "../../../../core/const/chapterTypes";
import { IAttachment } from "../../../../core/interfaces/IAttachment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AttachmentService } from "../../../../core/service/attachment.service";
import { HttpEventType } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-chapter-editor',
  templateUrl: './chapterEditor.component.html',
  styleUrls: ['./chapterEditor.component.scss']
})
export class ChapterEditorComponent {
  _chapter: IChapter;
  _id: string;
  subchapters: IChapter[];
  actionOptions = actionsOptions;
  faAdd = faPlus;
  chapterTypes = chapterTypes;
  attachments: IAttachment[] = [];
  //attachedFiles: any[] = [];\
  isSubchapter = false;
  chapterModel: FormGroup;
  chPos: string;

  @Input() disabled: boolean;
  @Input() set id(value: string) {
    this._id = value;
    this._chapter = this.storeService.getData('workbookReducer', 'chapters')
      .filter((chapter: IChapter) => chapter.id == value)[0];

    if (this._chapter) {
      this.chapterModel.controls['title'].setValue(this._chapter.title);
      this.chapterModel.controls['description'].setValue(this._chapter.description);
    }

    let chaptersIds = this.storeService.getData('workbookReducer', 'chapters').map((ch: IChapter) => ch.id);

    this.isSubchapter = chaptersIds.indexOf(this._chapter.parentId) >= 0;

    if (!this.isSubchapter) this.chapterModel.controls['title'].setValidators(Validators.required);

    this.subchapters = this.storeService.getData('workbookReducer', 'chapters')
      .filter((chapter: IChapter) => chapter.parentId == value);

    this.attachments = this.storeService.getData('workbookReducer', 'attachments')
      .filter((att: IAttachment) => att.parentId == this._id);

    // let fetchAttachmentsSAubscription = this.attachmentService.fetchAttachmentsByParentId(value)
    //   .subscribe((attachments: IAttachment[]) => {
    //     // Clear current attachments in store, delete attachments which was previously stored for this chapter
    //     this.workbookActions.store_attachments(
    //       this.storeService.getData('workbookReducer', 'attachments')
    //         .filter((att: IAttachment) => att.parentId != this._id)
    //     );
    //
    //     // Store updated attachments
    //     this.workbookActions.add_few_attachments(attachments);
    //     fetchAttachmentsSAubscription.unsubscribe();
    //   });

    this.workbookActions.changeChapProp({ id: this._id, prop: 'valid', value: this.chapterModel.valid });
  }

  chaptersSubscription: Subscription;
  subchaptersSubscription: Subscription;
  attachmentsSubscription: Subscription;
  isSubchapterSubscription: Subscription;

  constructor(
    private storeService: StoreService,
    private workbookActions: WorkbookActions,
    private workbookService: WorkbookService,
    private _fb: FormBuilder,
    private attachmentService: AttachmentService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.chapterModel = this._fb.group({
      title: ['', Validators.compose([
        Validators.maxLength(255),
      ])],
      description: [''],
    });

    this.chaptersSubscription = this.storeService.getDataSubscription('workbookReducer', 'chapters')
      .subscribe((chapters: any[]) => {
        this._chapter = chapters.filter((chapter: any) => chapter.id == this._id)[0];
      });

    this.subchaptersSubscription = this.storeService.getDataSubscription('workbookReducer', 'chapters')
      .subscribe((chapters: IChapter[]) => {
        this.subchapters = chapters.filter((chapter: IChapter) => chapter.parentId == this._id);
      });

    this.attachmentsSubscription = this.storeService.getDataSubscription('workbookReducer', 'attachments')
      .subscribe((attachments: IAttachment[]) => {
        this.attachments = attachments.filter((att: IAttachment) => this._id && att.parentId == this._chapter.id);
      });

    this.isSubchapterSubscription = this.storeService.getDataSubscription('workbookReducer', 'chapters')
      .subscribe((chapters: IChapter[]) => {
        let chaptersIds = chapters.map((ch: IChapter) => ch.id);

        this.isSubchapter = this._chapter && chaptersIds.indexOf(this._chapter.parentId) >= 0;
      });
  }

  changeTitle($event): void {
    this.workbookActions.changeChapProp({ id: this._id, prop: 'title', value: $event.target.value });
    this.workbookActions.changeChapProp({ id: this._id, prop: 'valid', value: this.chapterModel.valid });
  }

  changeDescription($event):void {
    this.workbookActions.changeChapProp({ id: this._id, prop: 'description', value: $event });
    this.chapterModel.controls['description'].setValue($event);
    this.workbookActions.changeChapProp({ id: this._id, prop: 'valid', value: this.chapterModel.valid })
  }

  changeContent($event):void {
    this.workbookActions.changeChapProp({ id: this._id, prop: 'content', value: $event });
  }

  toggleHasNotes($event):void {
    this.workbookActions.changeChapProp({ id: this._id, prop: 'hasNotes', value: $event.target.checked })
  }

  addSubchapter():void {
    let subscription = this.workbookService.createSubChapter(this._id)
      .subscribe(() => {
        subscription.unsubscribe();
      });
  }

  deleteChapter() {
    // Delete chapter
    this.workbookActions.deleteChapter(
      this.storeService.getData('workbookReducer', 'chapters').filter((chapter: IChapter) =>
        chapter.id != this._id
      )
    );

    // Delete chapter subchapters
    this.workbookActions.deleteChapter(
      this.storeService.getData('workbookReducer', 'chapters').filter((chapter: IChapter) =>
        chapter.parentId != this._id
      )
    )
  }

  actionsInteraction(actionType: string): void {
    this.workbookActions.changeChapProp({ id: this._id, prop: 'type', value: actionType });
    this.workbookActions.changeChapProp({ id: this._id, prop: 'description', value: '' });
  }

  storeFiles($event) {

    let files: File[] = $event.target.files;
    let storeFilesSubscription: Subscription[] = [];

    for (let i = 0; i < files.length; i++) {
      let tmpFile = files[i];
      storeFilesSubscription.push(this.attachmentService.storeFiles(files[i])
        .subscribe((event: any) => {

          if (event.type === HttpEventType.Response) {

            this.workbookActions
              .add_attachment(
                {
                  filename: event.body.filename,
                  name: tmpFile.name,
                  description: '',
                  parentId: this._chapter.id,
                  shouldSave: true,
                  workbookId: this.activatedRoute.snapshot.params.id, // this.storeService.getData('workbookReducer', 'workbook').id,
                }
              );

            storeFilesSubscription[i].unsubscribe();
          }
        }));
    }

    $event.target.value = '';
  }

  // changeAttachedFile($event: FormGroup, i: number):void {
  //   this.attachedFiles[i].controls['description'].setValue($event.controls['description'].value);
  //
  //   this.attachmentValid = this.attachedFiles.every((attach: FormGroup) => attach.valid);
  // }

  // deleteDoc($event: boolean, i: number):void {
  //   if ($event) {
  //     // delete from server
  //     this.attachmentService.deleteAttachedDoc(this.attachedFiles[i].controls['filename'].value)
  //       .subscribe(() => {
  //         this.attachedFiles = this.attachedFiles.filter((attachment: FormGroup) =>
  //           attachment.controls['filename'] != this.attachedFiles[i].controls['filename']
  //         )
  //       });
  //   }
  // }

  ngOnDestroy():void {
    this.chaptersSubscription.unsubscribe();
    this.subchaptersSubscription.unsubscribe();
    this.attachmentsSubscription.unsubscribe();
    this.isSubchapterSubscription.unsubscribe();
  }
}
