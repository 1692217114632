import {AfterContentInit, Component, OnDestroy} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SurveyService } from '../../../core/service/survey.service';
import { QuestionTypes } from '../../../core/const/questionTypes';
import { StoreService } from '../../../core/service/store.service';
import { Subscription } from 'rxjs';
import { IQuestion, ISurvey } from '../../../core/interfaces/ISurvey';
import { SurveyActions } from '../../../actions/survey.actions';
import { QuestionAffiliation } from '../../../core/const/questionAffiliation';
import { NetworkStates } from '../../../core/const/networkStates';

@Component({
  selector: 'app-survey-editor',
  templateUrl: './surveyEditor.component.html',
  styleUrls: ['./surveyEditor.component.scss'],
})
export class SurveyEditorComponent implements OnDestroy, AfterContentInit {
  types = [
      { title: this.setTypeTitle(QuestionTypes.SINGLE), id: QuestionTypes.SINGLE },
      // { title: Multi, id: QuestionTypes.MULTI },
      { title: this.setTypeTitle(QuestionTypes.SELFDESCRIPTION), id: QuestionTypes.SELFDESCRIPTION },
  ];
  disabled = false;

  currentType = { id: QuestionTypes.SINGLE, title: this.setTypeTitle(QuestionTypes.SINGLE) };
  currentQuestionId: string;

  affiliations = [
    { title: this.setAffiliationTitle(QuestionAffiliation.TRAINER), id: QuestionAffiliation.TRAINER },
    { title: this.setAffiliationTitle(QuestionAffiliation.WORKBOOK), id: QuestionAffiliation.WORKBOOK },
    { title: this.setAffiliationTitle(QuestionAffiliation.TRAINING), id: QuestionAffiliation.TRAINING },
  ];

  currentAffiliation = { title: this.setAffiliationTitle(QuestionAffiliation.TRAINER), id: QuestionAffiliation.TRAINER };

  currentQuestionIdSubscription: Subscription;
  currentQuestionSubscription: Subscription;
  private offlineSubscription: Subscription;
  private idbSubscription: Subscription;

  constructor(
      private router: Router,
      private activatedRouter: ActivatedRoute,
      private surveyService: SurveyService,
      private storeService: StoreService,
      private surveyActions: SurveyActions,
  ) {

    this.currentQuestionIdSubscription = this.storeService.getDataSubscription('surveyReducer', 'currentQuestionId')
        .subscribe((id: string) => {
          this.currentQuestionId = id;

          if (id) {
            this.fillCurrentQuestionData(id);
          }
        });

    this.currentQuestionSubscription = this.storeService.getDataSubscription('surveyReducer', 'questions')
        .subscribe((questions: IQuestion[]) => {
          const id = this.storeService.getData('surveyReducer', 'currentQuestionId');

          this.fillCurrentQuestionData(id);
        });

    this.offlineSubscription = this.storeService.getDataSubscription('utilsReducer', 'networkState')
        .subscribe((status: NetworkStates) => {
          this.disabled = status === NetworkStates.OFFLINE;
        });
  }

  ngAfterContentInit() {
    this.idbSubscription = this.storeService.getDataSubscription('commonReducer', 'idb_connected')
        .subscribe((idb: boolean) => {
          if (idb) {
            this.init(this.activatedRouter.snapshot.params.id);
          }
        })
  }

  init(id: string): void {
    if (id) {
      this.fetchSurveyData(id);
    } else {
      const subscription = this.surveyService.createSurvey()
          .subscribe((data: {id: string}) => {
            subscription.unsubscribe();

            const currentSurvey: ISurvey = this.storeService.getData('surveyReducer', 'currentSurvey');
            currentSurvey.id = data.id;

            this.surveyActions.change_current_survey(currentSurvey);
          });
    }
  }

  fetchSurveyData(id: string): void {
    this.surveyService.getSurveyById(id);
  }

  fillCurrentQuestionData(id: string) {
    const currentQuestion = this.storeService.getData('surveyReducer', 'questions')
        .filter((q: IQuestion) => q.id === id)[0];

    if (currentQuestion) {
      this.currentType = { id: currentQuestion.type, title: this.setTypeTitle(currentQuestion.type) };
      this.currentAffiliation = { id: currentQuestion.affiliation, title: this.setAffiliationTitle(currentQuestion.affiliation) };
    }
  }

  setQuestionType($event) {
    const currentQuestionId: string = this.storeService.getData('surveyReducer', 'currentQuestionId');
    const currentQuestion: IQuestion = {
      ...this.storeService.getData('surveyReducer', 'questions')
          .filter((q: IQuestion) => q.id === currentQuestionId)[0],
    };

    currentQuestion.type = $event.id;

    if (currentQuestion.type === QuestionTypes.SELFDESCRIPTION) {
      const updatedOptions = this.storeService.getData('surveyReducer', 'options')
          .filter((o => o.parentId !== currentQuestionId));
      currentQuestion.options = [];
      this.surveyActions.store_options(updatedOptions);
    }

    this.surveyActions.question_changed(currentQuestion);
  }

  setQuestionAffiliation($event): void {
    const currentQuestionId: string = this.storeService.getData('surveyReducer', 'currentQuestionId');
    const currentQuestion: IQuestion = {
      ...this.storeService.getData('surveyReducer', 'questions')
          .filter((q: IQuestion) => q.id === currentQuestionId)[0],
    };

    currentQuestion.affiliation = $event.id;

    this.surveyActions.question_changed(currentQuestion);
  }

  setTypeTitle(value: string): string {
    switch (value) {
      case QuestionTypes.SINGLE:
        return 'Multiple choice';
      case QuestionTypes.MULTI:
        return 'Multi';
      case QuestionTypes.SELFDESCRIPTION:
        return 'Free text feedback';
      default:
        return 'Single';
    }
  }

  setAffiliationTitle(value: string): string {
    switch (value) {
      case QuestionAffiliation.TRAINER:
        return 'Trainer evaluation';
      case QuestionAffiliation.WORKBOOK:
        return 'Content evaluation';
      case QuestionAffiliation.TRAINING:
        return 'Event evaluation';
      default:
        return 'Trainer evaluation';
    }
  }

  ngOnDestroy() {
    this.surveyActions.clear_all();
    this.currentQuestionIdSubscription.unsubscribe();
    if (this.offlineSubscription) { this.offlineSubscription.unsubscribe(); }
    if (this.idbSubscription) { this.idbSubscription.unsubscribe(); }
  }
}
