import { Injectable } from "@angular/core";
import { IChapter, IChapterCategory, IWorkbook } from "../core/interfaces/IWorkbook";
import { NgRedux } from "@angular-redux/store";
import { trainingsState } from "../reducers/training.reducer";
import {IAttachment} from "../core/interfaces/IAttachment";

@Injectable()
export class WorkbookActions {
  static STORE_WORKBOOK = '[Workbook] Store workbook';
  static STORE_CATEGORIES = '[Workbook] Store categories';
  static STORE_INIT_CATEGORIES = '[Workbook] Store init categories';
  static STORE_CHAPTERS = '[Workbook] Store chapters';
  static STORE_INIT_CHAPTERS = '[Workbook] Store init chapters';
  static ADD_CHAPTER = '[Workbook] Add chapter';
  static ADD_CATEGORY = '[Workbook] Add category';
  static ADD_WORKBOOK = '[Workbook] Add workbook';
  static ADD_SUBCHAPTER = '[Workbook] Add subchapter';
  static CHANGE_CAT_PROP = '[Workbook] Change category property';
  static CHANGE_WB_PROP = '[Workbook] Change WB property';
  static CHANGE_CHAP_PROP = '[Workbook] Change Chapter property';
  static DELETE_CHAPTER = '[Workbook] Delete chapter';
  static DELETE_CATEGORY = '[Workbook] Delete category';
  static STORE_ATTACHMENTS = '[Workbook] Store attachments';
  static ADD_ATTACHMENT = '[Workbook] Add attachment';
  static CHANGE_ATTACHMENT_PROP = '[Workbook] Change attachment prop';
  static DELETE_ATTACHMENT = '[Workbook] Delete Attachment';
  static ADD_FEW_ATTACHMENTS = '[Workbook] Add few Attachments';
  static ADD_TO_CHANGED_ATTACHMENTS = '[Workbook] Add to changed attachments';
  static DEL_FROM_CHANGED_ATTACHMENTS = '[Workbook] Del from changed attachments';

  constructor(private ngRedux: NgRedux<trainingsState>) {}

  store_workbook(workbook: IWorkbook):void {
    this.ngRedux.dispatch({
      type: WorkbookActions.STORE_WORKBOOK,
      payload: workbook
    });
  }

  store_categories(categories: IChapterCategory[]):void {
    this.ngRedux.dispatch({
      type: WorkbookActions.STORE_CATEGORIES,
      payload: categories
    });
  }

  store_init_categories(initCategories: IChapterCategory[]) {
    this.ngRedux.dispatch({
      type: WorkbookActions.STORE_INIT_CATEGORIES,
      payload: initCategories
    })
  }

  store_chapters(chapters: IChapter[]):void {
    this.ngRedux.dispatch({
      type: WorkbookActions.STORE_CHAPTERS,
      payload: chapters
    });
  }

  store_init_chapters(chapters: IChapter[]):void {
    this.ngRedux.dispatch({
      type: WorkbookActions.STORE_INIT_CHAPTERS,
      payload: chapters
    });
  }

  add_workbook(workbook: IWorkbook):void {
    this.ngRedux.dispatch({
      type: WorkbookActions.ADD_WORKBOOK,
      payload: workbook
    });
  }

  add_category(category: IChapterCategory):void {
    this.ngRedux.dispatch({
      type: WorkbookActions.ADD_CATEGORY,
      payload: category
    });
  }

  add_chapter(chapter: IChapter):void {
    this.ngRedux.dispatch({
      type: WorkbookActions.ADD_CHAPTER,
      payload: chapter
    });
  }

  changeWBProp(data: { prop: string, value: any }):void {
    this.ngRedux.dispatch({
      type: WorkbookActions.CHANGE_WB_PROP,
      payload: data
    });
  }

  changeCatProp(data: { id: string, prop: string, value: any }):void {
    this.ngRedux.dispatch({
      type: WorkbookActions.CHANGE_CAT_PROP,
      payload: data
    });
  }

  changeChapProp(data: { id: string, prop: string, value: any }):void {
    this.ngRedux.dispatch({
      type: WorkbookActions.CHANGE_CHAP_PROP,
      payload: data
    });
  }

  deleteChapter(updatedChapters: IChapter[]):void {
    this.ngRedux.dispatch({
      type: WorkbookActions.DELETE_CHAPTER,
      payload: updatedChapters
    });
  }

  deleteCategory(updatedCategories: IChapterCategory[], updatedChapters: IChapter[]):void {
    this.ngRedux.dispatch({
      type: WorkbookActions.DELETE_CATEGORY,
      payload: { updatedChapters, updatedCategories }
    });
  }

  store_attachments(attachments: IAttachment[]):void {
    this.ngRedux.dispatch({
      type: WorkbookActions.STORE_ATTACHMENTS,
      payload: attachments
    });
  }

  add_attachment(attachment: IAttachment):void {
    this.ngRedux.dispatch({
      type: WorkbookActions.ADD_ATTACHMENT,
      payload: attachment
    })
  }

  add_few_attachments(attachments: IAttachment[]):void {
    this.ngRedux.dispatch({
      type: WorkbookActions.ADD_FEW_ATTACHMENTS,
      payload: attachments
    });
  }

  del_attachment(attachment: IAttachment):void {
    this.ngRedux.dispatch({
      type: WorkbookActions.DELETE_ATTACHMENT,
      payload: attachment
    });
  }

  changeAttachmentProp(changes: { attachment: IAttachment, prop: string, value: any }):void {
    this.ngRedux.dispatch({
      type: WorkbookActions.CHANGE_ATTACHMENT_PROP,
      payload: changes
    });
  }

  attachment_changed(attachment: IAttachment):void {
    this.ngRedux.dispatch({
      type: WorkbookActions.ADD_TO_CHANGED_ATTACHMENTS,
      payload: attachment.filename
    })
  }

  attachment_not_changed(attachment: IAttachment):void {
    this.ngRedux.dispatch({
      type: WorkbookActions.DEL_FROM_CHANGED_ATTACHMENTS,
      payload: attachment.filename
    });
  }
}
