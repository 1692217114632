import {ButtonsSets} from "./buttonsSets";
import {faCheck, faEye, faPlus, faSave, faTimes} from "@fortawesome/free-solid-svg-icons";
import {ButtonClasses} from "./buttonClasses";
import {ButtonTypes} from "./buttonTypes";
import {BtnPosition} from "./btnPosition";

export const BUTTONS = {};

BUTTONS[ButtonsSets.SURVEY] = [
    {
        text: 'Add question',
        iconLeft: faPlus,
        class: [ButtonClasses.GRAY],
        type: ButtonTypes.ADD,
        position: BtnPosition.HEADER
    },
    {
        iconLeft: faEye,
        class: [ButtonClasses.GRAY],
        type: ButtonTypes.PREVIEW,
        position: BtnPosition.HEADER
    },
    {
        text: 'Cancel',
        iconLeft: faTimes,
        class: [ButtonClasses.GRAY],
        type: ButtonTypes.CANCEL,
        position: BtnPosition.HEADER
    },
    {
        text: 'Save',
        iconLeft: faSave,
        class: [ButtonClasses.GRAY],
        type: ButtonTypes.SAVE,
        position: BtnPosition.HEADER
    },
    {
        text: 'Create',
        iconLeft: faCheck,
        class: [ButtonClasses.BLUE],
        type: ButtonTypes.CREATE,
        position: BtnPosition.HEADER
    },
    {
        text: 'Add option',
        iconLeft: faPlus,
        class: [ButtonClasses.TRANSPARENT],
        type: ButtonTypes.ADD,
        position: BtnPosition.BODY
    }
];