import {Component, EventEmitter, HostListener, Input, Output} from "@angular/core";

@Component({
  selector: 'app-wide-img',
  templateUrl: './wideImg.component.html',
  styleUrls: ['./wideImg.component.scss'],
  host: {
  '(document:click)': 'closeImg($event)',
},
})
export class WideImgComponent {
  _src: string;
  @Input()
  set src(value: string) {
    this._src = value;
  }

  get src(): string {
    return this._src;
  }

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('document:touched', ['$event'])
  documentClick($event) {
    this.closeImg($event)
  }

  closeImg($event) {
    this.close.emit(true);
  }
}
