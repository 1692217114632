import {TrainingActions} from "../actions/training.actions";
import {IWorkbook} from "../core/interfaces/IWorkbook";
import {ITraining} from "../core/interfaces/ITraining";
import {IAttachment} from "../core/interfaces/IAttachment";

export type trainingsState = {
  // total: {
  //   ended: number,
  //   active: number,
  //   new: number
  // },
  training: null | ITraining,
  testsSaved: { id: string, state: boolean }[]
}

const initialState = {
  // total: {
  //   ended: 0,
  //   active: 0,
  //   new: 0
  // },
  training: null,
  testsSaved: []
};

export const trainingReducer = (state: trainingsState = initialState, action: any) => {
  switch(action.type) {
    // case(TrainingActions.SET_NEW_TRAININGS_TOTAL):
    //   return {
    //     ...state,
    //     total: {
    //       ...state.total,
    //       new: action.payload
    //     }
    //   };

    // case(TrainingActions.SET_ACTIVE_TRAININGS_TOTAL):
    //   return {
    //     ...state,
    //     total: {
    //       ...state.total,
    //       active: action.payload
    //     }
    //   };

    // case(TrainingActions.SET_ENDED_TRAININGS_TOTAL):
    //   return {
    //     ...state,
    //     total: {
    //       ...state.total,
    //       ended: action.payload
    //     }
    //   };

    case(TrainingActions.STORE_TRAINING):
      return {
        ...state,
        training: action.payload
      };

    case(TrainingActions.SET_TEST_STATE):
      let testsStates = state.testsSaved || [];

      if (testsStates.some(testState => testState.id == action.payload.id)) {
        testsStates = state.testsSaved.map((testSaved: {id: string, state: boolean}) => {
          if (testSaved.id == action.payload.id) {
            testSaved.state = action.payload.state;
          }

          return testSaved;
        });
      } else {
        testsStates = testsStates.concat([action.payload]);
      }

      return {
        ...state,
        testsSaved: testsStates
      };

    default:
      return {
        ...state
      }
  }
};
