import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../store';
import { Injectable } from '@angular/core';

@Injectable()
export class FiltersActions {
  static INIT_FILTERS = '[Filters] init filters';
  static CHANGE_CURRENT_VALUES = '[Filters] change current filters values';
  static CHANGE_CURRENT_SUBFILTER = '[Filters] change current subfilter value';
  static CHANGE_START_DATE = '[Filters] change start date';
  static CHANGE_END_DATE = '[Filters] change end date';
  static CHANGE_PERIOD = '[Filters] change period';
  static SKIP_TO_INITIAL = '[Filters] skip to initial';

  constructor(private ngRedux: NgRedux<IAppState>) {}

  initFilters(filters: object): void {
    this.ngRedux.dispatch({
      type: FiltersActions.INIT_FILTERS,
      payload: filters,
    });
  }

  changeCurrentFilters(filterType: string, values: any[]): void {
    this.ngRedux.dispatch({
      type: FiltersActions.CHANGE_CURRENT_VALUES,
      payload: { filterType, values },
    });
  }

  changeCurrentSubfilter(filterType: string, value: any): void {
    const filter = {};
    filter[filterType] = value;

    this.ngRedux.dispatch({
      type: FiltersActions.CHANGE_CURRENT_SUBFILTER,
      payload: filter,
    });
  }

  changeStartDate(date: string): void {
    this.ngRedux.dispatch({
      type: FiltersActions.CHANGE_START_DATE,
      payload: date,
    });
  }

  changeEndDate(date: string): void {
    this.ngRedux.dispatch({
      type: FiltersActions.CHANGE_END_DATE,
      payload: date,
    });
  }

  changePeriod(period): void {
    this.ngRedux.dispatch({
      type: FiltersActions.CHANGE_PERIOD,
      payload: period,
    });
  }

  skipToInitial(): void {
    this.ngRedux.dispatch({
      type: FiltersActions.SKIP_TO_INITIAL,
    });
  }
}
