import { Component, Input } from '@angular/core';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Types } from '../../../core/interfaces/types';
import { ITrainer } from 'src/app/core/interfaces/ITraining';

@Component({
  selector: 'app-entity-expander',
  templateUrl: './entityExpander.component.html',
  styleUrls: ['./entityExpander.component.scss'],
})

export class EntityExpanderComponent {
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;

  isOpened = false;

  @Input() entity: ITrainer;

  toggleCardBody() {
    this.isOpened = !this.isOpened;
  }

  dataFormat(data): string {
    return this.entity.avg.type === Types.PERCENT ? `${(parseFloat(data) * 100).toFixed(0)}%` : data;
  }
}
