import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Observer, of, throwError } from 'rxjs/index';
import { CONFIG } from '../config';
import { URLS } from '../const/urls';
import { LoaderService } from './loader.service';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { GlobalErrorhandlerService } from './globalErrorhandler.service';
import { StoreService } from './store.service';
import { NetworkStates } from '../const/networkStates';
import {
    PersistenceService,
    REQUESTS_STORE_DELETE,
    REQUESTS_STORE_GET,
    REQUESTS_STORE_POST,
    REQUESTS_STORE_PUT,
} from './persistence.service';

export interface IRequestOptions {
    body?: any;
    headers?: HttpHeaders | { [header: string]: string | string[] };
    observe?: any;
    params?: HttpParams | { [param: string]: string | string[] };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
}

@Injectable()
export class RequestService {
    constructor(
        private readonly http: HttpClient,
        private readonly loaderService: LoaderService,
        private readonly globalErrorhandler: GlobalErrorhandlerService,
        private readonly storeService: StoreService,
        private readonly persistenceService: PersistenceService,
    ) {}

    get(urlKey: string, params?: object, headers?: any): Observable<any> {
        let url = `${CONFIG.host}/${URLS[urlKey]}`;
        const user = localStorage.getItem('profile') && JSON.parse(localStorage.getItem('profile')).email;

        if (params) {
            const preparedParams = Object.keys(params)
                .map((key: string) => `${key}=${params[key]}`)
                .join('&');

            url = `${url}?${preparedParams}`;
        }

        this.loaderService.showLoader();

        return this.http.get(url, {
            headers: Object.assign({}, headers,
                //   {
                //     'Cache-Control': 'no-cache',
                //     Pragma: 'no-cache',
                //     Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
                // }
            ),
        })
            .pipe(
                map((resp: any) => {
                    this.loaderService.hideLoader();

                    this.persistenceService.save(REQUESTS_STORE_GET, {
                        url: `${url.replace(CONFIG.host, '')}/${user ? user : ''}/GET`,
                        data: JSON.stringify(resp),
                    });

                    // this._storeApiData(url.replace(CONFIG.host, ''), JSON.stringify(resp));
                    return resp;
                }),
                catchError((error: any) => {
                    this.loaderService.hideLoader();

                    console.log(url);

                    if (+error.status === 0) {
                        return new Observable((obs: Observer<any>) => {
                            this.persistenceService.get(REQUESTS_STORE_GET, `${url.replace(CONFIG.host, '')}/${user ? user : ''}/GET`)
                                .then((req: {url: string, data: string}) => {
                                    obs.next(JSON.parse(req.data));
                                    // obs.complete();
                                });
                        });
                        // return of(JSON.parse(localStorage.getItem(url.replace(CONFIG.host, ''))));
                    }
                    return this.globalErrorhandler.processGlobalErrors(error);

                }),
            );
    }

    post(urlKey: string, params: object, headers?: any): Observable<any> {
        const url = `${CONFIG.host}/${URLS[urlKey]}`;
        const user = localStorage.getItem('profile') && JSON.parse(localStorage.getItem('profile')).email;

        this.loaderService.showLoader();

        return this.http.post(url, params, {
            headers,
        })
            .pipe(
                map((resp: any) => {
                    this.loaderService.hideLoader();

                    this.persistenceService.save(REQUESTS_STORE_POST, {
                        url: `${url.replace(CONFIG.host, '')}/${user ? user : ''}/POST`,
                        data: JSON.stringify(resp),
                    });

                    // this._storeApiData(url.replace(CONFIG.host, ''), JSON.stringify(resp));

                    return resp;
                }),
                catchError((error: any) => {
                    this.loaderService.hideLoader();

                    if (+error.state === 0) {
                        return new Observable((obs: Observer<any>) => {
                            this.persistenceService.get(REQUESTS_STORE_POST, `${url.replace(CONFIG.host, '')}/${user ? user : ''}/POST`)
                                .then((req: {url: string, data: string}) => {
                                    obs.next(JSON.parse(req.data));
                                    obs.complete();
                                });
                        });
                        // return of(JSON.parse(localStorage.getItem(url.replace(CONFIG.host, ''))));
                    }
                    return this.globalErrorhandler.processGlobalErrors(error);

                }),
            );
    }

    put(urlKey: string, params: object, headers?: any): Observable<any> {
        const url = `${CONFIG.host}/${URLS[urlKey]}`;
        const user = localStorage.getItem('profile') && JSON.parse(localStorage.getItem('profile')).email;

        this.loaderService.showLoader();

        return this.http.put(url, params, {
            headers,
        })
            .pipe(
                map((resp: any) => {
                    this.loaderService.hideLoader();

                    this.persistenceService.save(REQUESTS_STORE_PUT, {
                        url: `${url.replace(CONFIG.host, '')}/${user ? user : ''}/PUT`,
                        data: JSON.stringify(resp),
                    });

                    // this._storeApiData(url.replace(CONFIG.host, ''), JSON.stringify(resp));

                    return resp;
                }),
                catchError((error: any) => {
                    this.loaderService.hideLoader();

                    if (+error.state === 0) {
                        return new Observable((obs: Observer<any>) => {
                            this.persistenceService.get(REQUESTS_STORE_PUT, `${url.replace(CONFIG.host, '')}/${user ? user : ''}/PUT`)
                                .then((req: {url: string, data: string}) => {
                                    obs.next(JSON.parse(req.data));
                                    obs.complete();
                                });
                        });
                        // return of(JSON.parse(localStorage.getItem(url.replace(CONFIG.host, ''))));
                    }
                    return this.globalErrorhandler.processGlobalErrors(error);

                }),
            );
    }

    postWithStatus(urlKey: string, params: object, headers?: any): Observable<any> {
        const url = `${CONFIG.host}/${URLS[urlKey]}`;
        const user = localStorage.getItem('profile') && JSON.parse(localStorage.getItem('profile')).email;

        this.loaderService.showLoader();

        const options: IRequestOptions = {
            headers: new HttpHeaders(headers),
            reportProgress: true,
            observe: 'events',
        };

        return this.http.post(url, params, options)
            .pipe(
                map((resp: any) => {
                    this.loaderService.hideLoader();

                    if (resp.type === HttpEventType.Response) {
                        this.persistenceService.save(REQUESTS_STORE_POST, {
                            url: `${url.replace(CONFIG.host, '')}/${user ? user : ''}/POSTS`,
                            data: JSON.stringify(resp),
                        });
                        // this._storeApiData(url.replace(CONFIG.host, ''), JSON.stringify(resp));
                    }

                    return resp;
                }),
                catchError((error: any) => {
                    this.loaderService.hideLoader();

                    if (error.state === 0) {
                        return new Observable((obs: Observer<any>) => {
                            this.persistenceService.get(REQUESTS_STORE_POST, `${url.replace(CONFIG.host, '')}/${user ? user : ''}/POSTS`)
                                .then((req: {url: string, data: string}) => {
                                    obs.next(JSON.parse(req.data));
                                    obs.complete();
                                });
                        });
                        // return of(JSON.parse(localStorage.getItem(url.replace(CONFIG.host, ''))));
                    }
                    return this.globalErrorhandler.processGlobalErrors(error);

                }),
            );
    }

    delete(urlKey: string, params: object, headers?: any): Observable<boolean> {
        let url = `${CONFIG.host}/${URLS[urlKey]}`;
        const user = localStorage.getItem('profile') && JSON.parse(localStorage.getItem('profile')).email;

        if (params) {
            const preparedParams = Object.keys(params)
                .map((key: string) => `${key}=${params[key]}`)
                .join('&');

            url = `${url}?${preparedParams}`;
        }

        this.loaderService.showLoader();

        return this.http.delete(url, {
            headers,
        }).pipe(
            map((resp: any) => {
                this.loaderService.hideLoader();

                this.persistenceService.save(REQUESTS_STORE_DELETE, {
                    url: `${url.replace(CONFIG.host, '')}/${user ? user : ''}/DELETE`,
                    data: JSON.stringify(resp),
                });
                // this._storeApiData(url.replace(CONFIG.host, ''), JSON.stringify(resp));

                return resp;
            }),
            catchError((error: any) => {
                this.loaderService.hideLoader();

                if (+error.state === 0) {
                    return new Observable((obs: Observer<any>) => {
                        this.persistenceService.get(REQUESTS_STORE_DELETE, `${url.replace(CONFIG.host, '')}/${user ? user : ''}/DELETE`)
                            .then((req: {url: string, data: string}) => {
                                obs.next(JSON.parse(req.data));
                                obs.complete();
                            });
                    });
                    // return of(JSON.parse(localStorage.getItem(url.replace(CONFIG.host, ''))));
                }
                return this.globalErrorhandler.processGlobalErrors(error);

            }),
        );
    }

    // _storeApiData(req: string, data: string):void {
    //   localStorage.setItem(req, data);
    // }

    _isOnline(): boolean {
        const state = this.storeService.getData('utilsReducer', 'networkState');
        return state === NetworkStates.ONLINE;
    }
}
