import { IChapter, IChapterCategory, IWorkbook } from "../core/interfaces/IWorkbook";
import { WorkbookActions } from "../actions/workbook.actions";
import { IAttachment } from "../core/interfaces/IAttachment";

export type workbookState = {
  workbook: IWorkbook | null,
  initWorkbook: IWorkbook | null,
  categories: IChapterCategory[],
  initCategories: IChapterCategory[],
  chapters: IChapter[],
  initChapters: IChapter[],
  attachments: IAttachment[],
  changedAttachments: string[]
}

const initialState = {
  workbook: null,
  initWorkbook: null,
  categories: [],
  initCategories: [],
  chapters: [],
  initChapters: [],
  attachments: [],
  changedAttachments: []
};

export const workbookReducer = (state: workbookState = initialState, action: any) => {
  switch(action.type) {
    case(WorkbookActions.STORE_WORKBOOK):
      return {
        ...state,
        workbook: action.payload,
        initWorkbook: action.payload && { title: action.payload.title, description: action.payload.description }
      };

    case(WorkbookActions.STORE_CATEGORIES):
      return {
        ...state,
        categories: [...action.payload]
      };

    case(WorkbookActions.STORE_INIT_CATEGORIES):
      return {
        ...state,
        initCategories: action.payload
      };

    case(WorkbookActions.STORE_INIT_CHAPTERS):
      return {
        ...state,
        initChapters: [...action.payload]
      };

    case(WorkbookActions.STORE_CHAPTERS):
      return {
        ...state,
        chapters: [...action.payload]
      };

    case(WorkbookActions.DELETE_CHAPTER):
      return {
        ...state,
        chapters: [...action.payload]
      };

    case(WorkbookActions.CHANGE_WB_PROP):
      let newWB = Object.assign({}, state.workbook);
      newWB[action.payload.prop] = action.payload.value;

      return {
        ...state,
        workbook: newWB
      };

    case(WorkbookActions.CHANGE_CAT_PROP):
      let categories = state.categories.map((cat: IChapterCategory) => {
        if (cat && cat.id == action.payload.id) {
          cat[action.payload.prop] = action.payload.value;
        }

        return cat;
      });

      return {
        ...state,
        categories: [...categories]
      };

    case(WorkbookActions.CHANGE_CHAP_PROP):
      let chapters = state.chapters.map((chapter: IChapter) => {
        if (chapter.id == action.payload.id) {
          chapter[action.payload.prop] = action.payload.value;
        }

        return chapter;
      });

      return {
        ...state,
        chapters: [...chapters]
      };

    case(WorkbookActions.ADD_CATEGORY):
      return {
        ...state,
        categories: state.categories.concat(action.payload)
      };

    case(WorkbookActions.ADD_CHAPTER):
      return {
        ...state,
        chapters: state.chapters.concat(action.payload)
      };

    case(WorkbookActions.ADD_WORKBOOK):
      return {
        ...state,
        workbook: action.payload
      };

    case(WorkbookActions.DELETE_CATEGORY):
      return {
        ...state,
        categories: action.payload.updatedCategories,
        chapters: action.payload.updatedChapters
      };

    case(WorkbookActions.STORE_ATTACHMENTS):
      return {
        ...state,
        attachments: action.payload
      };

    case(WorkbookActions.ADD_ATTACHMENT):
      return {
        ...state,
        attachments: state.attachments.concat([action.payload])
      };

    case(WorkbookActions.ADD_FEW_ATTACHMENTS):
      return {
        ...state,
        attachments: state.attachments.concat(action.payload)
      };

    case(WorkbookActions.DELETE_ATTACHMENT):
      return {
        ...state,
        attachments: [...state.attachments.filter((attachment: IAttachment) =>
          attachment.filename != action.payload.filename)
        ]
      };

    case(WorkbookActions.CHANGE_ATTACHMENT_PROP):
      let updatedAttachments = state.attachments.map((att: IAttachment) => {
        if (att.filename == action.payload.attachment.filename) {
          att[action.payload.prop] = action.payload.value;
        }

        return att;
      });

      return {
        ...state,
        attachments: [...updatedAttachments]
      };

    case(WorkbookActions.ADD_TO_CHANGED_ATTACHMENTS):

      return {
        ...state,
        changedAttachments: state.changedAttachments.indexOf(action.payload) >= 0
          ? [...state.changedAttachments]
          : [...state.changedAttachments.concat([action.payload])]
      };

    case(WorkbookActions.DEL_FROM_CHANGED_ATTACHMENTS):

      return {
        ...state,
        changedAttachments: state.changedAttachments.filter(att => att != action.payload)
      };

    default:

      return {
        ...state
      };
  }
};
