import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import {current} from "codelyzer/util/syntaxKind";

@Component({
  selector: 'app-custom-select',
  templateUrl: './customSelect.component.html',
  styleUrls: ['./customSelect.component.scss'],
  host: {
    '(document:click)': 'close($event)',
  },
})
export class CustomSelectComponent {
  isOpen = false;
  arrowDown = faSortDown;
  arrowUp = faSortUp;
  _currentOption: any;
  _data: any;

  @Input() height: number;
  @Input() maxItems: number;
  @Input() set data(value: any) {
    this._data = value;
    this._currentOption = {id: null, name: 'N/A', code: '', location: null};
  };
  @Input() set current(value: any) {
    if (value) {
      if (value.id) {
        this._currentOption = value; //this._data.filter((option: any) => option.id == value.id || option.code == value.id)[0] || value;
      } else {
        this._currentOption = value;
      }
    }
  }

  get current(): any {
    return this._currentOption;
  }

  get data(): any {
    return this._data;
  }

  @Output() change: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _eref: ElementRef) {}

  toggleDropdown($event) {
    this.isOpen = !this.isOpen;
  }

  openDropdown($event) {
    $event.stopImmediatePropagation();
    this.isOpen = true;
  }

  closeDropdown($event) {
    // if ($event )$event.stopImmediatePropagation();
    this.isOpen = false;
  }

  close() {
    if (!this._eref.nativeElement.contains(event.target)) // or some similar check
     this.closeDropdown(null);
  }

  selectOption($event) {
    this._currentOption = $event;
    this.change.emit($event);
    this.isOpen = false;
  }
}
