import {Component, Input, OnDestroy} from '@angular/core';
import { IButton } from '../../../../core/interfaces/IButton';
import { ButtonsService } from '../../../../core/service/buttons.service';
import { ButtonsSets } from '../../../../core/const/buttonsSets';
import { ButtonTypes } from '../../../../core/const/buttonTypes';
import { Router } from '@angular/router';
import { SurveyActions } from '../../../../actions/survey.actions';
import { StoreService } from '../../../../core/service/store.service';
import { ISurvey } from '../../../../core/interfaces/ISurvey';
import { QuestionTypes } from '../../../../core/const/questionTypes';
import { SurveyService } from '../../../../core/service/survey.service';
import { BtnPosition } from '../../../../core/const/btnPosition';
import { VisibilityState } from '../../../../core/const/visibilityState';
import { QuestionAffiliation } from '../../../../core/const/questionAffiliation';
import { initialState } from '../../../../reducers/survey.reducer';
import { NotificationService } from '../../../../core/service/notification.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'survey-edit-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class SurveyEditHeader implements OnDestroy {
  btns: IButton[];
  currentSurvey: ISurvey;
  surveyModel: FormGroup;

  @Input() disabled: boolean;

  currentSurveySubscription: Subscription;

  constructor(
        private btnsService: ButtonsService,
        private router: Router,
        private surveyActions: SurveyActions,
        private storeService: StoreService,
        private surveyService: SurveyService,
        private notifications: NotificationService,
        private _fb: FormBuilder,
    ) {
    this.btns = this.btnsService.getButtons(ButtonsSets.SURVEY).filter(btn => btn.position == BtnPosition.HEADER);

    this.surveyModel = this._fb.group({
      title: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(255),
        ]),
      ],
    });

    this.currentSurveySubscription = this.storeService.getDataSubscription('surveyReducer', 'currentSurvey')
            .subscribe((curSurvey: ISurvey) => {
              this.currentSurvey = curSurvey;
              this.surveyModel.controls['title'].setValue(curSurvey.title);
            });
  }

  onClick(btnType: string): void {
    switch (btnType) {
      case ButtonTypes.ADD:
        const questionSub = this.surveyService.createQuestion()
            .subscribe((data: {id: string}) => {
              questionSub.unsubscribe();

              this.surveyActions.add_question(data.id);
            });

        return;

      case ButtonTypes.CANCEL:
        this.router.navigate(['/shared/overview']);

        return;

      case ButtonTypes.PREVIEW:
        if (this.surveyModel.valid) {
          const survey = this.surveyService.collectCurrentSurvey();
          const subscription = this.surveyService.saveSurvey(survey)
              .subscribe(() => {
                subscription.unsubscribe();
                this.router.navigate(['edit/survey/preview/', this.currentSurvey.id]);
              });
        } else {
          this.surveyModel.controls['title'].markAsTouched();
        }

        return;

      case ButtonTypes.SAVE:
        if (this.surveyService.surveyIsValid()) {
          const forSave: ISurvey = this.surveyService.collectCurrentSurvey();
          const saveSubscription = this.surveyService.saveSurvey(forSave)
                        .subscribe((saved: ISurvey) => {
                          saveSubscription.unsubscribe();
                          this.router.navigate([`/edit/survey/${saved.id}`]);
                        });
        } else {
          this.notifications.getNotification('error', 'Survey isn`t valid');
        }

        return;

      case ButtonTypes.CREATE:
        if (this.surveyService.surveyIsValid()) {
          const forPublish: ISurvey = this.surveyService.collectCurrentSurvey();
          const publishSubscription = this.surveyService.publishSurvey(forPublish)
                        .subscribe((saved: ISurvey) => {
                          publishSubscription.unsubscribe();
                          this.router.navigate(['/shared/overview']);
                        });
        } else {
          this.notifications.getNotification('error', 'Survey isn`t valid');
        }

        return;

      default:
        return;
    }
  }

  changeTitle($event): void {
    const currentSurvey: ISurvey = this.storeService.getData('surveyReducer', 'currentSurvey');

    currentSurvey.title = $event.target.value;

    this.surveyActions.change_current_survey(currentSurvey);
  }

  ngOnDestroy() {
    this.currentSurveySubscription.unsubscribe();
  }
}
