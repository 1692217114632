import {Roles} from '../../const/roles';

export const MAP = [
  {
    route: '/shared',
    allowed: [Roles.TEAM_LEAD, Roles.USER, Roles.TRAINER, Roles.CONTENT_MANAGER],
  },
  {
    route: '/shared/overview',
    allowed: [Roles.TEAM_LEAD, Roles.USER, Roles.TRAINER, Roles.CONTENT_MANAGER],
  },
  {
    route: '/shared/overview/workbooks',
    allowed: [Roles.TRAINER, Roles.TEAM_LEAD],
  },
  {
    route: '/shared/workbook/preview',
    allowed: [Roles.TRAINER, Roles.TEAM_LEAD],
  },
  {
    route: '/training',
    allowed: [Roles.TRAINER, Roles.USER, Roles.TEAM_LEAD],
  },
  {
    route: '/training/new',
    allowed: [Roles.TRAINER, Roles.TEAM_LEAD],
  },
  {
    route: '/training/edit',
    allowed: [Roles.TRAINER, Roles.TEAM_LEAD],
  },
  {
    route: '/training/survey',
    allowed: [Roles.TRAINER, Roles.USER, Roles.TEAM_LEAD],
  },
  {
    route: '/edit',
    allowed: [Roles.CONTENT_MANAGER],
  },
  {
    route: '/edit/workbook/preview',
    allowed: [Roles.TEAM_LEAD, Roles.TRAINER, Roles.CONTENT_MANAGER],
  },
  {
    route: '/statistic/overall',
    allowed: [Roles.TRAINER, Roles.TEAM_LEAD, Roles.GLOBAL_ADMIN, Roles.REGIONAL_ADMIN, Roles.ADMIN],
  },
  {
    route: '/statistic/dealer',
    allowed: [Roles.TRAINER, Roles.TEAM_LEAD, Roles.GLOBAL_ADMIN, Roles.REGIONAL_ADMIN, Roles.ADMIN],
  },
  {
    route: '/statistic/training',
    allowed: [Roles.TRAINER, Roles.TEAM_LEAD, Roles.GLOBAL_ADMIN, Roles.REGIONAL_ADMIN, Roles.ADMIN],
  },
  {
    route: '/statistic/trainer',
    allowed: [Roles.TRAINER, Roles.TEAM_LEAD, Roles.GLOBAL_ADMIN, Roles.REGIONAL_ADMIN, Roles.ADMIN],
  },
  {
    route: '/statistic/consultant',
    allowed: [Roles.TRAINER, Roles.TEAM_LEAD, Roles.GLOBAL_ADMIN, Roles.REGIONAL_ADMIN, Roles.ADMIN],
  },
];
