import { Component, Input } from '@angular/core';
import { StoreService } from '../../../../../core/service/store.service';
import { SurveyActions } from '../../../../../actions/survey.actions';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'survey-editor-answer-single',
  templateUrl: './single.component.html',
  styleUrls: ['./single.component.scss'],
})
export class SingleAnswerComponent {
  answerModel: FormGroup;
  _pos: number = null;
  timer = 0;
  interval: any;

  @Input() disabled: boolean;
  @Input() set pos(value: number) {
    const questionId = this.storeService.getData('surveyReducer', 'currentQuestionId');
    const opt = this.storeService.getData('surveyReducer', 'options')
        .filter(o => o.parentId === questionId && o.pos === value)[0];

    if (opt) {
      this._pos = value;
      this.answerModel.controls['title'].setValue(opt.title);
      this.answerModel.controls['score'].setValue(opt.score);
      this.answerModel.controls['pos'].setValue(opt.pos);
      this.answerModel.controls['id'].setValue(opt.id);
      this.answerModel.controls['parentId'].setValue(opt.parentId);
    }
  }

  constructor(
        private storeService: StoreService,
        private surveyActions: SurveyActions,
        private _fb: FormBuilder,
    ) {

    this.answerModel = this._fb.group({
      title: [''],
      score: [0],
      pos: [0],
      id: [''],
      parentId: [''],
    });
  }

  changeAnswer() {
    this.surveyActions.answerChanged(this.answerModel.value);
  }

  delAnswer($event) {
    this.surveyActions.del_answer(this.answerModel.value);
  }
}
