import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';
import { IMultiAnswer, IQuestion, ISingleAnswer, ISurvey } from '../interfaces/ISurvey';
import { AuthService } from './auth.service';
import { StoreService } from './store.service';
import { SurveyActions } from '../../actions/survey.actions';
import { VisibilityState } from '../const/visibilityState';

@Injectable()
export class SurveyService {

  constructor(
      private request: RequestService,
      private authService: AuthService,
      private storeService: StoreService,
      private surveyActions: SurveyActions,
  ) {}

  createSurvey(): Observable<{id: string}> {
    return this.request.get('SURVEY_CREATE', null, {
      Authorization: `Bearer ${this.authService.getToken()}`,
    });
  }

  createQuestion(): Observable<{id: string}> {
    return this.request.get('SURVEY_CREATE_QUESTION', null, {
      Authorization: `Bearer ${this.authService.getToken()}`,
    });
  }

  createOption(): Observable<{id: string}> {
    return this.request.get('SURVEY_CREATE_ANSWER', null, {
      Authorization: `Bearer ${this.authService.getToken()}`,
    });
  }

  getSurveyByTrainingId(language: string, skip: number, take: number, parentId: string): Observable<{
    totalCount: number,
    items: ISurvey[]}
  > {
    return this.request.get('SURVEY_LIST', {
      language,
      skip,
      take,
      parentId,
    },                      {
      Authorization: `Bearer ${this.authService.getToken()}`,
    });
  }

  getSurveyById(id: string): void {
    const subscribtion = this.request.get('SURVEY', { id }, {
        Authorization: `Bearer ${this.authService.getToken()}`,
      })
      .subscribe((survey: ISurvey) => {

        survey.questions = survey.questions.map((q: IQuestion, index: number) => {
          q.pos = index;
          q.options = (q.options as ISingleAnswer[]).map((o: ISingleAnswer, i: number) => {
            o.pos = i;

            return o;
          });

          return q;
        });

        this.surveyActions.set_init_survey(survey);
        this.surveyActions.set_current_survey(survey);
        this.surveyActions.set_current_question(survey.questions ? survey.questions[0].id : '');
        this.surveyActions.store_questions([...survey.questions]);
        this.surveyActions.store_options([...survey.questions.reduce(
            (sum: any[], q: IQuestion) => {
              sum = sum.concat(q.options);

              return sum;
            },
            [],
        )]);

        subscribtion.unsubscribe();
      });
  }

  getAllSurveys(skip?: number, take?: number): Observable<{totalCount: number, items: ISurvey[]}> {
    const params = skip >= 0 && take
      ? { skip, take, language: 'en' }
      : { language: 'en' };

    return this.request.get('SURVEY_LIST',
                            params,
                            {
        Authorization: `Bearer ${this.authService.getToken()}`,
      });
  }

  sendSurveyAnswer(survey: ISurvey): Observable<{totalQuestions: string, correctAnswers: string}> {
    return this.request.post('SURVEY_ANSWER', survey, {
      Authorization: `Bearer ${this.authService.getToken()}`,
    });
  }

  getSurveyResults(parentId: string): Observable<{
    totalQuestions: string, correctAnswers: string, surveyId: string, surveyTitle: string }[]
  > {
    return this.request.get('SURVEY_RESULTS', { parentId }, {
      Authorization: `Bearer ${this.authService.getToken()}`,
    });
  }

  changeQuestionPosition(curPos: number, newPos: number): void {
    // const survey = JSON.parse(JSON.stringify(this.storeService.getData('surveyReducer', 'currentSurvey')));
    // const currentQuestion = this.storeService.getData('surveyReducer', 'currentQuestion');
    // const questions = survey.questions;
    // const question = questions.splice(curPos, 1);
    //
    // questions.splice(newPos, 0, question[0]);
    //
    // survey.questions = questions
    //     .map((q: IQuestion, index: number) => {
    //       q.pos = index;
    //
    //       return q;
    //     })
    //     .sort((q1: IQuestion, q2: IQuestion) => q1.pos - q2.pos);
    //
    // this.surveyActions.set_current_question(
    //     survey.questions.filter((q: IQuestion) => q.title == currentQuestion.title)[0],
    // );
    //
    // this.surveyActions.change_current_survey(survey);
    let questions: IQuestion[] = [
      ...this.storeService.getData('surveyReducer', 'questions'),
    ];
    const currentQuestionId: string = this.storeService.getData('surveyReducer', 'currentQuestionId');
    const question = questions.splice(curPos, 1);
    questions.splice(newPos, 0, question[0]);

    questions = questions
        .map((q: IQuestion, index: number) => {
          q.pos = index;

          return q;
        })
        .sort((q1: IQuestion, q2: IQuestion) => q1.pos - q2.pos);

    this.surveyActions.store_questions(questions);
  }

  saveSurvey(model: ISurvey): Observable<ISurvey> {
    model.language = 'en';

    return this.request.post('SURVEY_SAVE', model, {
      Authorization: `Bearer ${this.authService.getToken()}`,
    });
  }

  publishSurvey(model: ISurvey): Observable<ISurvey> {
    model.state = VisibilityState.PUBLISHED;
    model.language = 'en';

    return this.request.post('SURVEY_SAVE',  model, {
      Authorization: `Bearer ${this.authService.getToken()}`,
    });
  }

  surveyIsValid() {
    const survey: ISurvey = this.storeService.getData('surveyReducer', 'currentSurvey');
    const questions: IQuestion[] = this.storeService.getData('surveyReducer', 'questions');
    const options: any[] = this.storeService.getData('surveyReducer', 'options');

    return survey.title &&
           questions.every((q: IQuestion) => !!q.title) &&
           options.every(o => !!o.title) &&
           questions
               .filter(q => q.type !== 'selfdescription')
               .every((q: IQuestion) => options.some(o => o.parentId === q.id));
  }

  collectCurrentSurvey() {
    const survey: ISurvey = this.storeService.getData('surveyReducer', 'currentSurvey');
    const questions: IQuestion[] = this.storeService.getData('surveyReducer', 'questions');
    const options: any[] = this.storeService.getData('surveyReducer', 'options');

    survey.questions = questions;
    survey.questions = survey.questions.map((q: IQuestion) => {
      q.options = options.filter(o => o.parentId === q.id);

      return q;
    });

    return survey;
  }
}
