import { chapterTypes } from '../../../../core/const/chapterTypes';

export const actionsOptions = [
  { title: 'Content', img: '../../../../assets/images/subject-24px.svg', type: chapterTypes.CONTENT },
  { title: 'Self-description', img: '../../../../../assets/images/help_square.svg', type: chapterTypes.SELF_DESCRIPTION },
  // { title: 'Multiple Choice Questions', img: '../../../../assets/images/poll-24px.svg', type: chapterTypes.MULTI_CHOISE},
  // { title: 'Checklist', img: '../../../../assets/images/subject-24px.svg', type: chapterTypes.CHECKLIST },
  // { title: 'Poll', img: '../../../../assets/images/live_help-24px.svg', type: chapterTypes.POLL },
  { title: 'Attachments', img: '../../../../assets/images/outline-attach_file-24px.svg', type: chapterTypes.ATTACHMENTS }
];
