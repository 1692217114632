import {Injectable} from "@angular/core";
import {NgRedux} from "@angular-redux/store";
import {trainingsState} from "../reducers/training.reducer";
import {ITraining} from "../core/interfaces/ITraining";
import {IAttachment} from "../core/interfaces/IAttachment";

@Injectable()
export class TrainingActions {
  // static SET_NEW_TRAININGS_TOTAL = '[Training] Set new trainings total';
  // static SET_ACTIVE_TRAININGS_TOTAL = '[Training] Set active trainings total';
  // static SET_ENDED_TRAININGS_TOTAL = '[Training] Set end trainings total';
  static STORE_TRAINING = '[Training] Store training';
  // static STORE_ATTACHMENTS = '[Training] Store attachments';
  static SET_TEST_STATE = '[Training] Set test state';

  constructor(private ngRedux: NgRedux<trainingsState>) {}

  // store_new_trainings_total(total: number) {
  //   this.ngRedux.dispatch({
  //     type: TrainingActions.SET_NEW_TRAININGS_TOTAL,
  //     payload: total
  //   })
  // }
  //
  // store_active_trainings_total(total: number) {
  //   this.ngRedux.dispatch({
  //     type: TrainingActions.SET_ACTIVE_TRAININGS_TOTAL,
  //     payload: total
  //   })
  // }
  //
  // store_ended_training_total(total: number) {
  //   this.ngRedux.dispatch({
  //     type: TrainingActions.SET_ENDED_TRAININGS_TOTAL,
  //     payload: total
  //   });
  // }

  store_training(training: ITraining) {
    this.ngRedux.dispatch({
      type: TrainingActions.STORE_TRAINING,
      payload: training
    });
  }

  // store_attachments(attachments: IAttachment[]) {
  //   this.ngRedux.dispatch({
  //     type: TrainingActions.STORE_ATTACHMENTS,
  //     payload: attachments
  //   });
  // }

  set_test_state(state: { id: string, state: boolean }) {
    this.ngRedux.dispatch({
      type: TrainingActions.SET_TEST_STATE,
      payload: state
    });
  }
}
