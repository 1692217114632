import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FilterTypes } from '../const/filterTypes';
import { FiltersActions } from '../../actions/filters.actions';
import { RequestService } from './request.service';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';
import { StoreService } from './store.service';
import {
  ICountryFilter,
  IDealerFilter,
  IFilter,
  IKPIFilter,
  ILocalRegionFilter, ITrainingFilter, IWorkbookFilter,
} from '../interfaces/IFilter';
import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable()
export class FilterService {

  constructor(
      private request: RequestService,
      private filterActions: FiltersActions,
      private authService: AuthService,
      private storeService: StoreService,
  ) {}

  static initAllValue(filterName: string): any {
    let allOption = null;

    switch (filterName) {
      case FilterTypes.GR:
        allOption = { title: 'All' };
        break;

      case FilterTypes.COUNTRY:
        allOption = { title: 'All', code: 'All', globalRegion: '' };
        break;

      case FilterTypes.CR:
        allOption = { title: 'All', code: 'All', countryCode: '' };
        break;

      case FilterTypes.DEALER:
        allOption = { title: 'All', code: 'All', regionCode: '' };
        break;

      case FilterTypes.WORKBOOK:
        allOption = { title: 'All', id: 'All', dealersCode: [] };
        break;

      case FilterTypes.TRAINING:
        allOption = { id: 'All', title: 'All', workbookId: '' };
        break;

      default:
        allOption = { id: 'All', title: 'All' };
    }

    return allOption;
  }

  changeFilter(type: FilterTypes, values: any): void {
    this.filterActions.changeCurrentFilters(type, values);

    const currentFilters = this.storeService.getData('filtersReducer', 'currentFilters');

    this.initFilters(Object.assign(
        {},
        Object.keys(currentFilters)
            .reduce((sum: object, key: string) => {
              if (currentFilters[key].values.length && currentFilters[key].values[0].title !== 'All') {
                sum[key] = currentFilters[key].values;
              }

              return sum;
            },      {}),
        { period: this.storeService.getData('filtersReducer', 'period') },
    ));
  }

  // Init filters
  initFilters(params?: any) {
    // Get filters, store them and fill blocked filters
    const subscription: Subscription = this.request.post('FILTERS_INIT', params, {
      Authorization: `Bearer ${this.authService.getToken()}`,
    })
        .pipe(
            map((filters: IKPIFilter): IKPIFilter => {
              // Add { title: 'All', values: [] } to each filter which isnt default
              Object.keys(filters).forEach((key: string) => {
                if (filters[key].length > 1) {
                  filters[key].unshift(FilterService.initAllValue(key));
                }
              });

              return filters;
            }),
        )
        .subscribe((filters: IKPIFilter) => {
          subscription.unsubscribe();
          const currentFilters = this.storeService.getData('filtersReducer', 'currentFilters');

          // Map filters to type which using in app
          // Object.keys(initialFilters).forEach((key: string) => {
          //   initialFilters[key].values = filters[key].map(f => f[initialFilters[key]]);
          // });
          this.filterActions.initFilters(filters);

          // Set filters which are default by role for this user
          Object.keys(filters).forEach((key: string) => {
            if (filters[key].length === 1) {
              this.filterActions.changeCurrentFilters(key, filters[key]);
            } else if (key !== 'period' && currentFilters[key].values.length > 0) {
              this.filterActions.changeCurrentFilters(key, currentFilters[key].values);
            } else {
              this.filterActions.changeCurrentFilters(key, [FilterService.initAllValue(key)]);
            }
          });
        });
  }

  updateFilters(params: any) {
    // return this.request.post('FILTERS_INIT', params, {
    //   Authorization: `Bearer ${this.authService.getToken()}`,
    // })
    //     .pipe(
    //         map((filters: IKPIFilter): IKPIFilter => {
    //
    //           Object.keys(filters).forEach((key: string) => {
    //             if (filters[key].length > 1) {
    //               filters[key].unshift(FilterService.initAllValue(key));
    //             }
    //           });
    //
    //           this.filterActions.changeCurrentFilters(filters);
    //
    //           return filters;
    //         }),
    //     );
  }

  // Update data for filters selectors
  updateFiltersSelectorsData(): object {
    const filters: IFilter[] = JSON.parse(JSON.stringify(this.storeService.getData('filtersReducer', 'filters')));
    // const filtersData = JSON.parse(JSON.stringify(this.storeService.getData('filtersReducer', 'filters')));
    //
    // if (!filtersData) {
    //   return [];
    // }
    //
    // // Find name of last filter which is not "All"
    // const filterName = _.findLast(Object.keys(currentFilters), (key: string) => {
    //
    //   return currentFilters[key].values.length > 0 &&
    //          currentFilters[key].values.some( v => v[currentFilters[key].property] !== 'All');
    // });
    //
    // const updatedFilters = {};
    // const index = Object.keys(currentFilters).indexOf(filterName);
    //
    // Object.keys(filtersData).forEach((key, i) => {
    //   i === 0
    //     ? updatedFilters[key] = filtersData[key]
    //     : index >= i - 1
    //       ? updatedFilters[key] = this.updateFilterByParent(Object.keys(filtersData)[i - 1], currentFilters[Object.keys(filtersData)[i - 1]])[key]
    //       : updatedFilters[key] = [FilterService.initAllValue(key)];
    // });

    return Object.keys(filters || {})
        .reduce((sum: object, key: string) => {
          sum[key] = filters[key];

          return sum;
        },      {});
  }

  // Get next filter with filtered values by previous filter values. Example: { globalRegions: { property: string, values: any[] }}
  updateFilterByParent(filterName: string, parentFilter: IFilter): any {
    const updatedFilter = {};
    const filters = JSON.parse(JSON.stringify(this.storeService.getData('filtersReducer', 'filters')));

    switch (filterName) {
      case FilterTypes.GR:
        updatedFilter[FilterTypes.COUNTRY] = [
          FilterService.initAllValue(FilterTypes.COUNTRY),
          ...filters[FilterTypes.COUNTRY]
              .filter((value: ICountryFilter) =>
                  parentFilter.values.some(v => v[parentFilter.property] === value.globalRegion),
              ),
        ];
        break;

      case FilterTypes.COUNTRY:
        const countryCodes = parentFilter.values.map(c => c.code);
        updatedFilter[FilterTypes.CR] = [
          FilterService.initAllValue(FilterTypes.CR),
          ...filters[FilterTypes.CR].filter((value: ILocalRegionFilter) => countryCodes.indexOf(value.countryCode) >= 0)];
        break;

      case FilterTypes.CR:
        const regionCodes = parentFilter.values.map(r => r.code);
        updatedFilter[FilterTypes.DEALER] = [
          FilterService.initAllValue(FilterTypes.DEALER),
          ...filters[FilterTypes.DEALER].filter((value: IDealerFilter) => regionCodes.indexOf(value.regionCode) >= 0)];
        break;

      case FilterTypes.DEALER:
        const dealerCodes = parentFilter.values.map(d => d.code);
        updatedFilter[FilterTypes.WORKBOOK] = [
          FilterService.initAllValue(FilterTypes.WORKBOOK),
          ...filters[FilterTypes.WORKBOOK].filter((value: IWorkbookFilter) => dealerCodes.some(code => value.dealersCode.indexOf(code) >= 0))];
        break;

      case FilterTypes.WORKBOOK:
        const workbooksIds = parentFilter.values.map(wb => wb.id);
        updatedFilter[FilterTypes.TRAINING] = [
          FilterService.initAllValue(FilterTypes.TRAINING),
          ...filters[FilterTypes.TRAINING].filter((value: ITrainingFilter) => workbooksIds.indexOf(value.workbookId) >= 0),
        ];
        break;

      default:
        break;
    }

    return updatedFilter;
  }

  changeDate(date: string, position: string): void {
    position === 'from'
        ? this.filterActions.changeStartDate(date)
        : this.filterActions.changeEndDate(date);
  }

  changePeriod(period: object): void {
    this.filterActions.changePeriod(period);
  }

  // Skip all filters to initial state
  cleanFilters(): void {
    this.filterActions.skipToInitial();
  }
}
