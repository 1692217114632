import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OfflineGuard } from './core/guards/offline.guard';

const routes: Routes = [
    { path: 'training', loadChildren: './screens/training/training.module#TrainingModule', canActivate: [OfflineGuard] },
    { path: 'edit', loadChildren: './screens/editors/editors.module#EditorsModule', canActivate: [OfflineGuard] },
    { path: 'shared', loadChildren: './screens/shared/shared.module#SharedModule', canActivate: [OfflineGuard] },
    { path: '', loadChildren: './screens/public/public.module#PublicModule', canActivate: [OfflineGuard] },
    { path: '', loadChildren: './screens/statistic/statistic.module#StatisticModule', canActivate: [OfflineGuard] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
