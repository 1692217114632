export const colors = [
  '#0098CD',
  '#9D9BC0',
  '#009976',
  '#EBA32A',
  '#931813',
  '#CEC8C1',
  '#333333',
  '#46BDC9',
  '#B8B8B8',
];
