import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DatepickerOptions } from 'ng2-datepicker';

@Component({
  selector: 'app-datePicker',
  templateUrl: './datePicker.component.html',
  styleUrls: ['./datePicker.component.scss'],
})

export class DatePiсkerComponent {
  @Input() title: string;
  date;

  @Output() customDate = new EventEmitter<any>();

  onChange() {
    this.customDate.emit(this.date);
  }

  options: DatepickerOptions = {
    minYear: 2010,
    maxYear: 2030,
    displayFormat: 'MMM[/]DD[/]YYYY',
    //barTitleFormat: 'MMMM YYYY',
    //dayNamesFormat: 'dd',
    firstCalendarDay: 1, // 0 - Sunday, 1 - Monday
    //locale: frLocale,
    //minDate: new Date(Date.now()), // Minimal selectable date
    maxDate: new Date(Date.now()),  // Maximal selectable date
    //barTitleIfEmpty: 'Click to select a date',
    placeholder: 'Select a date', // HTML input placeholder attribute (default: '')
    addClass: '', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };
}