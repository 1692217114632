import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { WorkbookEditorComponent } from "./workbookEditor/workbookEditor.component";
import { WorkbookService } from "../../core/service/workbook.service";
import { AttachmentService } from "../../core/service/attachment.service";
import {AuthService} from "../../core/service/auth.service";

@Injectable()
export class WbEditorCanDeactivate implements CanDeactivate<boolean> {

  constructor(
    private workbookService: WorkbookService,
    private attachmentsService: AttachmentService,
    private authService: AuthService
  ) {}

  canDeactivate(): boolean {

    if (this.workbookService.wbWasChanged()) {
      if (confirm("You have unsaved changes! If you leave, your changes will be lost.")) {
        this.workbookService.saveWB();
        this.attachmentsService.saveAllAttachments();
        this.authService.clearUserCreds();
        return true;
      } else {
        return false;
      }

    }

    return true;
  }
}
