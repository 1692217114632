import {Component, ElementRef, Input} from '@angular/core';
import { IMenuOption } from '../../interfaces/IMenuOption';
import { headerMenuModel } from './model';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { Roles } from '../../const/roles';
import { StoreService } from '../../service/store.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './headerMenu.component.html',
  styleUrls: ['./headerMenu.component.scss'],
  host: {
    '(document:click)': 'close($event)',
  },
})
export class HeaderMenuComponent {
  model: IMenuOption[] = headerMenuModel;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  openedOption: IMenuOption;

  @Input() disabled: boolean;

  constructor(
      private router: Router,
      private storeService: StoreService,
      private _eref: ElementRef,
  ) {}

  get currentOption(): IMenuOption {

    return this.model
        .filter((opt: IMenuOption) => opt.href && !this.model.some(o => o.children.some(ch => ch.href === this.router.url))
            ? opt.href === this.router.url
            : opt.children.some((ch: IMenuOption) => ch.href === this.router.url),
        )[0];
  }

  get currentSuboption(): IMenuOption {

    return this.model.map((o: IMenuOption) => o.children.length > 0
      ? o.children.filter((so: IMenuOption) => so.href === this.router.url)[0]
      : null,
    ).filter(so => so)[0];
  }

  toggleMenu(event: any, option: IMenuOption) {
    event.stopPropagation();

    this.openedOption = this.openedOption && this.openedOption.title === option.title
      ? null
      : option;
  }

  optionsFilteredByRole(options) {
    return options.filter(o => this.allowedToShow(o));
  }

  allowedToShow(option: IMenuOption): boolean {
    const role = this.storeService.getData('authReducer', 'profile').role;
    const countryCode = this.storeService.getData('authReducer', 'profile').country
        ? this.storeService.getData('authReducer', 'profile').country.isoCode
        : '';

    return option.allowed.indexOf(role) >= 0; // && option.disallowCountries.indexOf(countryCode) === -1;
  }

  close(event) {
    if (!this._eref.nativeElement.contains(event.target)) // or some similar check
        this.openedOption = null;
  }

  hideBurger() {
    document.querySelector('.mobile-wrapper').classList.remove('mobile-show');
  }
}
