import { Component, Input } from "@angular/core";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { trainingStatus } from "../../const/trainingStatus";

@Component({
    selector: 'app-workbook-card',
    templateUrl: './workbookCard.component.html',
    styleUrls: ['./workbookCard.component.scss']
})
export class WorkbookCardComponent {
    faArrowDown = faArrowDown;
    showImage = false;
    isIE: boolean;

    @Input() item: any; // Short model of Training/Survey/Workbook
    @Input() status: trainingStatus;

    get code(): string {
      return this.item && this.item.code
        ? this.item.code.substring(0, 3) + ' ' + this.item.code.substring(3, this.item.code.length)
        : '';
    }

    get codeBackground(){
      return this.status === trainingStatus.NEW
        ? '#9B6747'
        : this.status === trainingStatus.ACTIVE
          ? '#313131'
          : '#931813';
    }

    constructor() {
      this.isIE =  /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    }

    isDraft() {
        return this.item.state == 'draft';
      }
}
