import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { trainingsState } from '../reducers/training.reducer';
import { IQuestion, ISurvey } from '../core/interfaces/ISurvey';

@Injectable()
export class SurveyActions {
  static SET_INIT_SURVEY = '[Survey] Set init survey';
  static SET_CUR_SURVEY = '[Survey] Set current survey';
  static CHANGE_CUR_SURVEY = '[Survey] Change current survey prop';
  static STORE_QUESTIONS = '[Survey] Store questions';
  static ADD_QUESTION = '[Survey] Add question';
  static DEL_QUESTION = '[Survey] Delete question';
  static SET_CURRENT_QUESTION = '[Survey] Set current question';
  static CHANGE_CUR_QUESTION = '[Survey] Change current question property';
  static CHANGE_QUESTION_ORDER = '[Survey] Change question order';
  static STORE_ANSWERS = '[Survey] Store answers';
  static ADD_ANSWER = '[Survey] Add answer';
  static DEL_ANSWER = '[Survey] Delete answer';
  static CHANGE_ANSWER = '[Survey] Change answer';
  static CHANGE_ANSWER_ORDER = '[Survey] Change answer order';
  static CLEAR_ALL = '[Survey] clear all';

  constructor(private ngRedux: NgRedux<trainingsState>) {}

  set_init_survey(survey: ISurvey): void {
    this.ngRedux.dispatch({
      type: SurveyActions.SET_INIT_SURVEY,
      payload: survey,
    });
  }

  set_current_survey(survey: ISurvey): void {
    this.ngRedux.dispatch({
      type: SurveyActions.SET_CUR_SURVEY,
      payload: survey,
    });
  }

  change_current_survey(survey: ISurvey): void {
    this.ngRedux.dispatch({
      type: SurveyActions.CHANGE_CUR_SURVEY,
      payload: survey,
    });
  }

  store_questions(questions: IQuestion[]): void {
    this.ngRedux.dispatch({
      type: SurveyActions.STORE_QUESTIONS,
      payload: questions,
    });
  }

  store_options(options): void {
    this.ngRedux.dispatch({
      type: SurveyActions.STORE_ANSWERS,
      payload: options,
    });
  }

  add_question(questionId: string): void {
    this.ngRedux.dispatch({
      type: SurveyActions.ADD_QUESTION,
      payload: questionId,
    });
  }

  set_current_question(questionId: string): void {
    this.ngRedux.dispatch({
      type: SurveyActions.SET_CURRENT_QUESTION,
      payload: questionId,
    });
  }

  del_question(question): void {
    this.ngRedux.dispatch({
      type: SurveyActions.DEL_QUESTION,
      payload: question,
    });
  }

  question_changed(question): void {
    this.ngRedux.dispatch({
      type: SurveyActions.CHANGE_CUR_QUESTION,
      payload: question,
    });
  }

  add_answer(answer) {
    this.ngRedux.dispatch({
      type: SurveyActions.ADD_ANSWER,
      payload: answer,
    });
  }

  del_answer(answer) {
    this.ngRedux.dispatch({
      type: SurveyActions.DEL_ANSWER,
      payload: answer,
    });
  }

  answerChanged(answer): void {
    this.ngRedux.dispatch({
      type: SurveyActions.CHANGE_ANSWER,
      payload: answer,
    });
  }

  clear_all() {
    this.ngRedux.dispatch({
      type: SurveyActions.CLEAR_ALL,
    });
  }
}
