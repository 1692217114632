import { Component, Input } from '@angular/core';
import { QuestionTypes } from '../../../../core/const/questionTypes';
import { BUTTONS } from '../../../../core/const/buttons';
import { ButtonsSets } from '../../../../core/const/buttonsSets';
import { BtnPosition } from '../../../../core/const/btnPosition';
import { ButtonTypes } from '../../../../core/const/buttonTypes';
import { StoreService } from '../../../../core/service/store.service';
import { IMultiAnswer, IQuestion, ISingleAnswer } from '../../../../core/interfaces/ISurvey';
import { SurveyActions } from '../../../../actions/survey.actions';
import { Subscription } from 'rxjs';
import { SurveyService } from '../../../../core/service/survey.service';

@Component({
  selector: 'survey-editor-answers',
  templateUrl: './answers.component.html',
  styleUrls: ['./answers.component.scss'],
})
export class AnswersComponent {

  options = [];
  sub = '';
  _type: QuestionTypes;
  add = BUTTONS[ButtonsSets.SURVEY].filter(btn => btn.position == BtnPosition.BODY && btn.type == ButtonTypes.ADD)[0];
  currentQuestion: IQuestion;

  currentQuestionSubscription: Subscription;
  optionsSubscription: Subscription;

  @Input() disabled: boolean;
  @Input() type(value: QuestionTypes): void {
    this._type = value;
  }

  constructor(
      private storeService: StoreService,
      private surveyActions: SurveyActions,
      private surveyService: SurveyService,
  ) {
    this.currentQuestionSubscription = this.storeService.getDataSubscription('surveyReducer', 'questions')
        .subscribe((questions: IQuestion[]) => {
          const currentQuestionId: string = this.storeService.getData('surveyReducer', 'currentQuestionId');

          this.currentQuestion = questions
              .filter((q: IQuestion) => q.id === currentQuestionId)[0];

          if (currentQuestionId) {
            this.sub = `survey.editor.${this.currentQuestion.type}`;
          }
        });

    this.optionsSubscription = this.storeService.getDataSubscription('surveyReducer', 'questions')
        .subscribe((questions: IQuestion[]) => {
          this.options = this.storeService.getData('surveyReducer', 'options')
              .filter(o => this.currentQuestion && o.parentId === this.currentQuestion.id);

          console.log(this.options);
        });
  }

  addOption(): void {
    const subscription = this.surveyService.createOption()
        .subscribe((data: {id: string}) => {
          this.surveyActions.add_answer(this.createOption(this._type, this.options.length, data.id));
        });
  }

  createOption(type: QuestionTypes, pos: number, id: string): IMultiAnswer | ISingleAnswer {
    const parentId = this.storeService.getData('surveyReducer', 'currentQuestionId');

    switch (type) {
      case QuestionTypes.SINGLE:
        return { title: '', pos, score: 0, id, parentId };

      case QuestionTypes.MULTI:
        return { title: '', pos, isCorrect: false, id, parentId };

      default:
        return { title: '', pos, score: 0, id, parentId };
    }
  }

  isSingle() {
    return this.currentQuestion && this.currentQuestion.type == QuestionTypes.SINGLE;
  }

  isSelfDescribed() {
    return this.currentQuestion && this.currentQuestion.type == QuestionTypes.SELFDESCRIPTION;
  }

  ngOnDestroy() {
    this.currentQuestionSubscription.unsubscribe();
    this.optionsSubscription.unsubscribe();
  }
}
