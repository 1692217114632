import { Component } from "@angular/core";
import { options } from "./options";
import {NavigationEnd, Router, RouterEvent} from "@angular/router";
import {filter, map, tap} from "rxjs/internal/operators";

@Component({
    'selector': 'app-menu',
    templateUrl: './WBMenu.component.html',
    styleUrls: ['./WBMenu.component.scss']
})
export class WBMenuComponent {
    menuOpts = options;
    page: string;

    constructor(private router: Router) {
        router.events.subscribe((event: RouterEvent) => {
           if (event instanceof NavigationEnd) {
               this.page = event.url;
           }
        });
    }
}