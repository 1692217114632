import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { IChapter, IChapterCategory, IWorkbook } from '../interfaces/IWorkbook';
import { RequestService } from './request.service';
import { AuthService } from './auth.service';
import { StoreService } from './store.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { WorkbookActions } from '../../actions/workbook.actions';
import { WorkbookHelpers } from './workbookHelpers';

@Injectable()
export class WorkbookService {
  allSavedSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  allSaved$ = this.allSavedSource.asObservable();

  shouldSaveTestsSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  shouldSaveTests$ = this.shouldSaveTestsSource.asObservable();

  constructor(
    private request: RequestService,
    private authService: AuthService,
    private workbookActions: WorkbookActions,
    private storeService: StoreService,
  ) {}

  /**
   * Fetch workbook by training id
   *
   * @param {string} id (trainingId/workbookId)
   *
   * @return {Observable<IWorkbook>}
   */
  fetchWorkbookBySomeId(id: string): Observable<any> {
    return this.request.get('WORKBOOK', { id }, {
      Authorization: `Bearer ${this.authService.getToken()}`,
    }).pipe(
      switchMap((workbook: IWorkbook) => of(
        this.workbookActions.store_workbook(workbook),
        // Using JSON for breaking deep dependencies
        this.workbookActions.store_categories(JSON.parse(JSON.stringify(workbook.categories))),
        this.workbookActions.store_init_categories(JSON.parse(JSON.stringify(workbook.categories))),
        this.workbookActions.store_chapters(WorkbookHelpers.separateChapters(workbook)),
        this.workbookActions.store_init_chapters(JSON.parse(JSON.stringify(WorkbookHelpers.separateChapters(workbook)))),
      )),
    );
  }

  /**
   * Fetch workbooks list
   *
   */
  fetchWorkbookList(skip?: number, take?: number): Observable<{ total: number, items: any[] }> {
    const params = skip >= 0 && take ? { skip, take } : null;

    return this.request.get('WORKBOOK_LIST', params, {
      Authorization: `Bearer ${this.authService.getToken()}`,
    });
  }

  /**
   * Check that all notes and tests are saved
   *
   */
  checkFillableEntities(): boolean {
    const states: { id: string, state: boolean }[] = this.storeService.getData('notesReducer', 'notesSaved')
        .concat(this.storeService.getData('trainingReducer', 'testsSaved'));

    return states.length ? states.every(state => !!state.state) : true;
  }

  /**
   * Trigger tests saving operation
   *
   */
  saveTestsTrigger(shouldSave: boolean): void {
    this.shouldSaveTestsSource.next(shouldSave);
  }

  /**
   * Save chapter in DB
   *
   * @param {IChapter} chapter
   *
   * @return {Observable}
   */
  saveChapter(chapter: IChapter, workbookId: string): Observable<IChapter> {
    return this.request.post('WORKBOOK_SAVE_CHAPTER', { chapter, workbookId }, {
      Authorization: `Bearer ${this.authService.getToken()}`,
    });
  }

  /**
   * Update chapter in workbook
   *
   * @param {IChapter} chapter
   */
  updateChapter(chapter: IChapter): void {
    const workbook = this.storeService.getData('workbookReducer', 'workbook');

    const replaceChapter = (ch: any) => {
      if (chapter.id == ch.id) {
        ch = chapter;
      } else {
        if (ch.chapters.length) {
          ch.chapters.forEach(sub => replaceChapter(sub));
        }
      }
    };

    workbook.categories.forEach(cat => replaceChapter(cat));

    this.workbookActions.store_workbook(workbook);
  }

  // Firing on "create chapter" btn click, return object with empty chapter properties except id
  createChapter(categoryId: string): Observable<IChapter> {
    return this.request.get('WORKBOOK_CREATE_CHAPTER', null, {
      Authorization: `Bearer ${this.authService.getToken()}`,
    })
      .pipe(
        map((chapter: IChapter) => {
          chapter.parentId =  categoryId;
          chapter.orderPosition = this.storeService.getData('workbookReducer', 'chapters')
            .filter((chapter: IChapter) => chapter.category && chapter.parentId == categoryId).length;

          this.workbookActions.add_chapter(chapter);

          return chapter;
        }),
      );
  }

  // Firing on "create category" btn click, return object with empty category properties except id
  createCategory(): Observable<IChapterCategory> {
    return this.request.get('WORKBOOK_CREATE_CATEGORY', null, {
      Authorization: `Bearer ${this.authService.getToken()}`,
    })
      .pipe(
        map((category: IChapterCategory) => {
          category.orderPosition = this.storeService.getData('workbookReducer', 'categories').length;
          this.workbookActions.add_category(category);

          return category;
        }),
      );
  }

  // Firing in create wb constructor, return object with empty wb properties except id
  createWB(): Observable<IWorkbook> {
    return this.request.get('WORKBOOK_CREATE_WB', null, {
      Authorization: `Bearer ${this.authService.getToken()}`,
    })
      .pipe(
        map((wb: IWorkbook) => {
          this.workbookActions.add_workbook(wb);

          return wb;
        }),
      );
  }

  //
  createSubChapter(parentId: string): Observable<IChapter> {
    return this.request.get('WORKBOOK_CREATE_CHAPTER', null, {
      Authorization: `Bearer ${this.authService.getToken()}`,
    })
      .pipe(
        map((chapter: IChapter) => {
          chapter.parentId = parentId;

          this.workbookActions.add_chapter(chapter);

          return chapter;
        }),
      );
  }

  deleteCategory(catId: string): void {
    const updatedCategories = [...this.storeService.getData('workbookReducer', 'categories')
        .filter((cat: IChapterCategory) => cat.id !== catId)]
        .map((cat: IChapterCategory, i: number) => {
          cat.orderPosition = i;

          return { ... cat };
        });

    const catChaptersIds = this.storeService.getData('workbookReducer', 'chapters')
      .filter((chapter: IChapter) => chapter.parentId === catId)
      .map((chapter: IChapter) => chapter.id);

    const updatedChapters = [...this.storeService.getData('workbookReducer', 'chapters')
        .filter((chapter: IChapter) =>
            catChaptersIds.indexOf(chapter.id) < 0 && catChaptersIds.indexOf(chapter.parentId) < 0,
        )];

    this.workbookActions.deleteCategory(updatedCategories, updatedChapters);
  }

  changeCatOrderPosition(curPos: number, newPos: number): void {
    let categories = this.storeService.getData('workbookReducer', 'categories');
    const item = categories.splice(curPos, 1);
    categories.splice(newPos, 0, item[0]);

    categories = categories
      .map((cat: IChapterCategory, index: number) => {
        cat.orderPosition = index;

        return cat;
      })
      .sort((cat1: IChapterCategory, cat2: IChapterCategory) => cat1.orderPosition < cat2.orderPosition ? -1 : 1);

    this.workbookActions.store_categories(categories);
  }

  changeChapterOrderPosition(catId: string, curPos: number, newPos: number): void {
    let chapters = this.storeService.getData('workbookReducer', 'chapters')
      .filter((chapter: IChapter) => chapter.parentId == catId);

    const item = chapters.splice(curPos, 1);

    chapters.splice(newPos, 0, item[0]);

    chapters = chapters
      .map((chapter: IChapter, index: number) => {
        chapter.orderPosition = index;

        return chapter;
      })
      .sort((ch1: IChapter, ch2: IChapter) => ch1.orderPosition < ch2.orderPosition ? -1 : 1);

    this.workbookActions.store_chapters(
      this.storeService.getData('workbookReducer', 'chapters')
        .filter((chapter: IChapter) => chapter.parentId != catId)
        .concat(chapters),
    );
  }

  // Upload cover image and content images for WB
  uploadWBFiles(file: File, workbookId: string): Observable<any> {
    const sendedFile = new FormData();
    sendedFile.append('file', file);
    sendedFile.append('workbookId', workbookId);

    return this.request.post('WORKBOOK_UPLOAD_CONTENT', sendedFile, {
      Authorization: `Bearer ${this.authService.getToken()}`,
    });
  }

  saveWB(): Observable<any> {
    let wb = this.storeService.getData('workbookReducer', 'workbook');
    const categories = this.storeService.getData('workbookReducer', 'categories');
    const chapters = this.storeService.getData('workbookReducer', 'chapters');
    wb = WorkbookHelpers.collectWB(wb, categories, chapters);

    return this.request.post('WORKBOOK_SAVE', wb, {
      Authorization: `Bearer ${this.authService.getToken()}`,
    }).pipe(
      switchMap((workbook: IWorkbook) => of(
        this.workbookActions.store_workbook(workbook),
        // Using JSON for breaking deep dependencies
        this.workbookActions.store_categories(JSON.parse(JSON.stringify(workbook.categories))),
        this.workbookActions.store_init_categories(JSON.parse(JSON.stringify(workbook.categories))),
        this.workbookActions.store_chapters(WorkbookHelpers.separateChapters(workbook)),
        this.workbookActions.store_init_chapters(JSON.parse(JSON.stringify(WorkbookHelpers.separateChapters(workbook)))),
      )),
    );
  }

  // Check was wb changed or no
  wbWasChanged(): boolean {
    const wb = this.storeService.getData('workbookReducer', 'workbook');
    const initChapters = this.storeService.getData('workbookReducer', 'initChapters');
    const initCategories = this.storeService.getData('workbookReducer', 'initCategories');

    const initWorkbook = this.storeService.getData('workbookReducer', 'initWorkbook');
    const categories = this.storeService.getData('workbookReducer', 'categories');
    const chapters = this.storeService.getData('workbookReducer', 'chapters');
    const collectedWb = WorkbookHelpers.collectWB(Object.assign({}, wb), categories, chapters);

    const changedAttachments = this.storeService.getData('workbookReducer', 'changedAttachments');

    const catIsSame = categories.every(cat => {
      const comparable = initCategories.filter(initCat => initCat.id == cat.id)[0];

      return comparable && Object.keys(cat).filter(key => key != 'chapters' && key != 'valid')
          .every(key => {

        return cat[key] == comparable[key];
      });
    });

    const chapIsSame = chapters.every((chap: any, i: number) => {
      const comparable = initChapters.filter(initCh => initCh.id == chap.id)[0];

      return Object.keys(chap).filter(key => key != 'chapters' && key != 'valid' && key != 'tags' && key != 'notes' && key != 'comments')
        .every((key: any, index: number) => {

          if (!comparable) {
            return false;
          }

          return chap && (key != 'content' && key != 'description')
                    ? chap[key] == comparable[key]
                    : this.prepareContentForComparation(chap[key]) == this.prepareContentForComparation(comparable[key]);
        });
    });

    return !(chapIsSame && catIsSame && initWorkbook && wb && initWorkbook.title == wb.title && initWorkbook.description == wb.description);
  }

  prepareContentForComparation(content: string): string {
    return content
              // clear symbols which presented with amp
              .replace(/\&(\w|\W){1,}?\;/g, '')
              // Style tags
              .replace('<strong>', 'strong')
              .replace('<em>', 'em')
              .replace('<ol>', 'ol')
              .replace('<ul>', 'ul')
              .replace('<i>', 'i')
              .replace('<u>', 'u')
              .replace('<sup>', 'sup')
              .replace('<sub>', 'sub')
              .replace('<s>', 's')
              .replace('<q>', 'q')
              .replace('<ins>', 'ins')
              .replace('<code>', 'code')
              .replace('<kbd>', 'kbd')
              .replace('<samp>', 'samp')
              .replace('<var>', 'var')
              .replace('<del>', 'del')
              .replace('<ins>', 'ins')
              .replace('<cite>', 'cite')
              .replace('<small>', 'small')
              .replace('<tt>', 'tt')
              .replace('<big>', 'big')
              // clear start part of tag
              .replace(/<[^>|^\ ]*/g, '')
              // clear end part
              .replace(/<\/\S*>/g, '')
              .replace(/>/g, '')
              // clear white spaces
              .replace(/\s/g, '');
  }

  // Check that object is category
  isCat(data: IChapter): boolean {
    return !!data.parentId;
  }
}
