import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    CanActivate,
    NavigationEnd,
    NavigationStart,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { StoreService } from '../store.service';
import { MAP } from './roleMap';
import { Location } from '@angular/common';
import { AuthService } from '../auth.service';
import { IProfile } from '../../interfaces/IProfile';
import { map } from 'rxjs/operators';
import { Roles } from '../../const/roles';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(
      private storeService: StoreService,
      private router: Router,
      private _location: Location,
      private authService: AuthService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

    if (this.storeService.getData('authReducer', 'profile').role) {
      if (MAP.filter(r => r.route === this.clearUrl(state.url))[0]
              .allowed.indexOf(this.storeService.getData('authReducer', 'profile').role) >= 0
          ) {
        return true;
      }

      this._location.back();

      return false;
    }

    return new Observable((observer) => {
      this.authService.fetchProfile()
            .subscribe((profile: IProfile) => {
              if (MAP.filter(r => r.route === this.clearUrl(state.url))[0]
                    .allowed.indexOf((profile.role as Roles)) >= 0
              ) {
                observer.next(true);
                observer.complete();
                return;
              }

              this._location.back();

              observer.next(false);
              observer.complete();
              return;
            });
    });
  }

  // Delete part with id
  clearUrl(url): string {
    return url.split('/')
          .filter(part => part.split('-').length < 3)
          .join('/');
  }
}
