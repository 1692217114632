import { TrainerNotesActions } from '../actions/trainerNotes.actions';
import { IComment } from '../core/interfaces/IComment';

export interface TrainerNotesState {
  initNotes: IComment[];
  notes: IComment[];
}

export const initialState = {
  initNotes: [],
  notes: [],
};

export const trainerNotesReducer = (state: TrainerNotesState = initialState, action) => {
  switch (action.type) {
    case TrainerNotesActions.initialTrainerNote:
      return {
        ...state,
        initNotes: state.initNotes.filter(n => n.chapterId !== action.payload.chapterId).concat([action.payload]),
      };

    case TrainerNotesActions.updatedTrainerNote:
      return {
        ...state,
        notes: state.notes.filter(n => n.chapterId !== action.payload.chapterId)
            .concat((action.payload ? [action.payload] : [])),
      };

    case TrainerNotesActions.setInitialState:
      return {
        ...initialState,
      };

    default:
      return {
        ...state,
      };
  }
};
