import {Action} from "redux";
import {IToken} from "../core/interfaces/IToken";
import {IProfile} from "../core/interfaces/IProfile";
import {AuthAction} from "../actions/auth.action";

export type AuthStore = {
    token: IToken,
    profile: IProfile
}

export const initialState: AuthStore = {
    token: {
        access_token: '',
        refresh_token: '',
        expires_in: 0,
        token_type: 'Bearer'
    },
    profile: {
        email: '',
        firstName: '',
        lastName: '',
        country: null,
        dealer: null,
        preferedLanguage: null,
        photo: '',
        userId: '',
        role: '',
        isGDPRAccepted: false,
        id: -1,
        created: null,
        modified: null
    }
};

export const authReducer = (state: AuthStore = initialState, action: any) => {
    switch(action.type) {
        case(AuthAction.STORE_USER_TOKEN):
            return {
                ...state,
                token: action.payload
            };

        case(AuthAction.STORE_USER_PROFILE):
            return {
                ...state,
                profile: action.payload
            };

        case(AuthAction.LOGOUT):
            return {
              ...state,
              profile: initialState.profile,
              token: initialState.token
            };

        default:
            return {
                ...state
            }
    }
};
