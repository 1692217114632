import {Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';
import {faArrowRight, faCheck} from "@fortawesome/free-solid-svg-icons";
import {Subscription} from "rxjs";
import {IChapter, IWorkbook} from "../../interfaces/IWorkbook";
import {WorkbookHelpers} from "../../service/workbookHelpers";
import {NavigationService} from "../../service/navigation.service";
import {StoreService} from "../../service/store.service";
import {AttachmentService} from "../../service/attachment.service";
import {IAttachment} from "../../interfaces/IAttachment";
import {TrainingActions} from "../../../actions/training.actions";
import {WorkbookService} from "../../service/workbook.service";
import {trainingStatus} from "../../const/trainingStatus";
import {Roles} from "../../const/roles";
import {WorkbookActions} from "../../../actions/workbook.actions";
import {TrackingService} from "../../service/tracking.service";
import {ContentEntry} from "../../const/contentEntry";
import * as _ from 'lodash';
import {NetworkStates} from "../../const/networkStates";
import {SearchService} from "../../service/search.service";

@Component({
  selector: 'app-workbook-wrapper',
  templateUrl: './workbookWrapper.component.html',
  styleUrls: ['./workbookWrapper.component.scss']
})
export class WorkbookWrapperComponent {
  windowParams = {width: 0, height: 0};

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.windowParams.height = window.innerHeight;
    this.windowParams.width = window.innerWidth;
  }

  faRightArrow = faArrowRight;
  faCheck = faCheck;

  @Input() meta: any;
  @Input() status: string;
  @Input() searchOpened: boolean;

  itemsPerScreen = 25;
  scrolledChapters = this.itemsPerScreen;
  workbookModel: IWorkbook;
  chapters = [];
  oldScroll = 0;
  wideImgShowed = false;
  wideImgUrl = '';
  trainingStatus = trainingStatus;
  chaptersHeight = [];
  sendedChaptersIds = [];
  role: Roles;
  disabled = false;

  @Output() completeTraining: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('wb_content', { static: false }) content: ElementRef;
  @ViewChild('wb', { static: false }) wb: ElementRef;

  navSubscription: Subscription;
  workbookSubscription: Subscription;
  storeSubscription: Subscription;
  attachmentSubscription: Subscription;
  offlineSubscription: Subscription;

  get visibleContent(): any[] {
    return this.createVisibleBlocksList();
  }

  constructor(
    private navService: NavigationService,
    private storeService: StoreService,
    private attachmentService: AttachmentService,
    private trainingActions: TrainingActions,
    private workbookActions: WorkbookActions,
    private workbookService: WorkbookService,
    private trackingService: TrackingService,
    private searchService: SearchService,
  ) {
    // Get window
    this.getScreenSize();

    this.role = this.storeService.getData('authReducer', 'profile').role;

    if (this.role == Roles.USER) {
      // TODO: rework, now when user save notes/tests then exit from training and then deeping again in it last value are true and
      // app try to send requsts for save notes/trainings. Maybe we should do unsubscription somewhere
      this.workbookService.saveTestsTrigger(false);
    }

    this.navSubscription = this.navService.navToChapter$.subscribe((chapterId: string) => {
      if (chapterId) {
        this.chapters.forEach((chapter: IChapter, index: number) => {
          if (
            chapter.id === chapterId ||
            (chapter.chapters && chapter.chapters.filter((subchapter: IChapter) => subchapter.id == chapterId).length > 0)
          ) {

            this.scrolledChapters = index > this.scrolledChapters ? index : this.scrolledChapters;

            setTimeout(() => {
              let offset = 0;
              for (let i = 0; i < index; i++) {

                offset += this.content.nativeElement.children[i].children[0].offsetHeight;
              }

              let el = document.getElementById(chapterId);
              el.scrollIntoView();
            }, 0);
          }
        })
      }
    });

    this.workbookSubscription = this.storeService.getDataSubscription('workbookReducer', 'workbook')
      .subscribe((workbook: IWorkbook) => {
        if (workbook) {
          this.workbookModel = workbook;
          this.chapters = WorkbookHelpers.createChaptersArray(this.workbookModel);

          // After relogin or active account changing attachment request firing 2 times
          if (workbook.trainingId == localStorage.getItem('trainingId')) {
            this.attachmentSubscription = this.attachmentService.fetchAttachmentsByParentId(workbook.templateId)
              .subscribe((attachments: IAttachment[]) => workbookActions.store_attachments(attachments));
          }

          // Send tracking
          if (this.role == Roles.USER) {
            this.trackingService.sendTrack({
              contentType: 'workbook',
              contentId: [workbook.id],
              contentEntry: ContentEntry.IN
            }).subscribe();
          }
        }
      });

    this.offlineSubscription = this.storeService.getDataSubscription('utilsReducer', 'networkState')
        .subscribe((status: NetworkStates) => {
          this.disabled = status === NetworkStates.OFFLINE;
        });
  }

  static getChapterHTMLEelm(container) {
    return container.target.children[1].children[container.target.children[1].children.length - 1].children[0];
  }

  onScroll($event) {
    // Tracking data
    if (this.role == Roles.USER) {
      this.sendChaptersOnScreenToServer(this.chaptersOnScreen(this.wb.nativeElement.scrollTop));
    }

    if (this.shouldShowBottomElement(($event.target as HTMLElement).scrollTop) && (this.oldScroll < $event.target.scrollTop)) {
      this.incrementHeight();
    }
    else if (this.shouldHideBottomElement(($event.target as HTMLElement).scrollTop) && (this.oldScroll > $event.target.scrollTop)) {
        this.scrolledChapters--;
    }
    if (this.oldScroll < ($event.target as HTMLElement).scrollTop) {
      document.querySelector('.top_part').classList.add('hidePanel');
      document.querySelector('.mobile-wrapper').classList.remove('mobile-show');
    } else {
      document.querySelector('.top_part').classList.remove('hidePanel');
      document.querySelector('.mobile-wrapper').classList.remove('mobile-show');
    }
    if ((this.oldScroll - 10) < ($event.target as HTMLElement).scrollTop) {
      document.querySelector('.top_part').classList.remove('hidePanelUp');
    }
    this.searchService.toggleSearchBlock(false);
    this.oldScroll = ($event.target as HTMLElement).scrollTop;
  }

  shouldShowBottomElement(scrollPos: number): boolean {

    return scrollPos > this.content.nativeElement.offsetHeight - this.windowParams.height;
  }

  createVisibleBlocksList() {
    // If not last elements
    return this.chapters.filter( (item, index) =>
      index >= 0 && index <= this.scrolledChapters
    );
  }

  incrementHeight() {
    this.updateChaptersHeight();
    this.scrolledChapters++;
  }

   shouldHideBottomElement(scrollPos): boolean {
      // TODO: store notes and answers which was changed by user and then send them to server when user clicking on 'Save' btn
      // now they will be remove and never stored if user leave them in last chapters and then scroll to top
      // let chapterHeight = 0;
      //
      // // Add last 3 chapters childs to calculation
      // for (let i = 0; i < this.content.nativeElement.children[this.content.nativeElement.children.length - 1].children.length; i++){
      //   chapterHeight += this.content.nativeElement.children[this.content.nativeElement.children.length - 1].children[i].offsetHeight;
      // }
      //
      // for (let i = 0; i < this.content.nativeElement.children[this.content.nativeElement.children.length - 2].children.length; i++){
      //   chapterHeight += this.content.nativeElement.children[this.content.nativeElement.children.length - 2].children[i].offsetHeight;
      // }
      //
      // for (let i = 0; i < this.content.nativeElement.children[this.content.nativeElement.children.length - 3].children.length; i++){
      //   chapterHeight += this.content.nativeElement.children[this.content.nativeElement.children.length - 3].children[i].offsetHeight;
      // }
      //
      // return this.scrolledChapters > this.itemsPerScreen &&
      //   scrollPos <
      //   this.content.nativeElement.offsetHeight - 2220 - chapterHeight;
     return false;
   }

   showImg($event) {
    $event.stopImmediatePropagation();

    if (this.wideImgUrl != $event.target.getAttribute('src')) {
      this.wideImgShowed = true;
      let src = $event.target.getAttribute('src');
      if (this.wideImgUrl != src) {
        this.wideImgUrl = src;
      }
    } else {
      this.wideImgShowed = false;
      this.wideImgUrl = '';
    }
   }

   closeImg($event) {
    if ($event) {
      this.wideImgUrl = '';
      this.wideImgShowed = false;
    }
   }

   complete() {
     this.completeTraining.emit(true);
   }

  isActive() {

    return this.storeService.getData('trainingReducer', 'training')
        ? this.storeService.getData('trainingReducer', 'training').status == this.trainingStatus.ACTIVE &&
          this.storeService.getData('authReducer', 'profile').role == Roles.USER
        : false;
  }

   ngAfterContentChecked() {

    if (this.content) {
      let images = this.content.nativeElement.getElementsByTagName('img');

      for (let i=0; i < images.length; i++) {
        images[i].addEventListener('click', ($event) => this.showImg($event));
      }

      this.updateChaptersHeight();

      // console.log(this.chaptersOnScreen(0));
    }
   }

   updateChaptersHeight() {
     for (let i = 0, len = this.content.nativeElement.children.length; i < len; i++) {
       let id = this.content.nativeElement.children[i].children[0].id;

       if (this.chaptersHeight.filter(chHeight => chHeight.id == id).length == 0) {
         this.chaptersHeight.push({
           id,
           height: this.content.nativeElement.children[i].children[0].offsetHeight
         });
       }
     }
   }

   // TODO: Fix accuracy
   chaptersOnScreen(scrollPos: number) {
     let visibleChapters = [];

     this.chaptersHeight.reduce((sum, ch) => {

       if (scrollPos >= sum &&  (scrollPos - this.windowParams.height + 400 < sum + ch.height)) {
         visibleChapters.push(ch.id);
       }

       sum += ch.height;

       return sum;
     }, 0);

     return visibleChapters;
   }

   sendChaptersOnScreenToServer(chaptersIds: string[]):void {
      if (!(_.xor(chaptersIds, this.sendedChaptersIds).length === 0)) {
        this.sendedChaptersIds = chaptersIds;
        this.trackingService.sendTrack({
          contentEntry: ContentEntry.IN,
          contentId: chaptersIds,
          contentType: 'chapter'
        }).subscribe();
      }
   }

   ngOnDestroy() {
     if (this.navSubscription) this.navSubscription.unsubscribe();
     if (this.storeSubscription) this.storeSubscription.unsubscribe();
     if (this.workbookSubscription) this.workbookSubscription.unsubscribe();
     if (this.attachmentSubscription) this.attachmentSubscription.unsubscribe();
     if (this.offlineSubscription) this.offlineSubscription.unsubscribe();

     //Send tracking data
     if (this.role == Roles.USER) {
       this.trackingService.sendTrack({
         contentType: 'workbook',
         contentId: [this.workbookModel.id],
         contentEntry: ContentEntry.OUT
       }).subscribe();
     }
   }
}
