import { Component, HostListener, OnDestroy } from '@angular/core';
import { WorkbookService } from '../../../core/service/workbook.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { IWorkbook } from '../../../core/interfaces/IWorkbook';
import { StoreService } from '../../../core/service/store.service';
import { NavigationService } from '../../../core/service/navigation.service';
import { Location } from '@angular/common';
import { TrainerNotesService } from '../../service/trainerNotes.service';
import { Roles } from '../../const/roles';
import { IProfile } from '../../interfaces/IProfile';
import { IComment } from '../../interfaces/IComment';

@Component({
  selector: 'app-workbook-preview',
  templateUrl: './workbookPreview.component.html',
  styleUrls: ['./workbookPreview.component.scss'],
})
export class WorkbookPreviewComponent implements OnDestroy {
  showMenu: boolean;
  faLeftArrow = faArrowLeft;
  faSave = faSave;
  wb: IWorkbook;
  role: string;
  notesChanged = false;
  notesAlertOpened = false;

  workbookSubscription: Subscription;
  navStateSubscription: Subscription;
  roleSubscription: Subscription;
  notesSubscription: Subscription;

  @HostListener('document:click', ['$event'])
  checkScrollBody() {
    if (this.showMenu === true) {
      document.querySelector('body').classList.add('no-scroll');
    } else {
      document.querySelector('body').classList.remove('no-scroll');
    }
  }

  get shouldShowSave(): boolean {
    return this.role === Roles.TEAM_LEAD || this.role === Roles.TRAINER;
  }

  constructor(
    private workbookService: WorkbookService,
    private storeService: StoreService,
    private navService: NavigationService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private trainerNotesService: TrainerNotesService,
  ) {
    this.workbookSubscription = this.workbookService.fetchWorkbookBySomeId(this.route.snapshot.params['id'])
      .subscribe(() => {
        this.wb = this.storeService.getData('workbookReducer', 'workbook');
      });

    this .navStateSubscription = this.navService.navIsOpen$.subscribe((state: boolean) => this.showMenu = state);

    this.roleSubscription = this.storeService.getDataSubscription('authReducer', 'profile')
        .subscribe((profile: IProfile) => this.role = profile.role);

    this.notesSubscription = this.storeService.getDataSubscription('trainerNotesReducer', 'notes')
        .subscribe((notes: IComment[]) => {
          this.notesChanged = this.trainerNotesService.notesWasChanged(notes);
        });
  }

  toggleMenu(): void {
    this.showMenu = !this.showMenu;
  }

  processUnsavedData($event): void {
    if ($event) {
      this.saveComments();
    }

    this.notesAlertOpened = false;
    this.location.back();
  }

  goBack() {
    if (this.notesChanged) {
      this.notesAlertOpened = true;
    } else {
      if (this.role === Roles.CONTENT_MANAGER) {
        this.router.navigate(['/edit/workbook', this.router.url.split('/').reverse()[0]]);
      } else {
        this.location.back();
      }
    }
  }

  saveComments(): void {
    if (this.role === Roles.TRAINER || this.role === Roles.TEAM_LEAD) {
      this.trainerNotesService.saveUpdatedNotes(this.wb.id);
    }
  }

  ngOnDestroy() {
    if (this.notesChanged) {
      this.notesAlertOpened = true;
    }

    this.trainerNotesService.skipToInitialState();
    this.navStateSubscription.unsubscribe();
    this.workbookSubscription.unsubscribe();
    this.roleSubscription.unsubscribe();
    this.notesSubscription.unsubscribe();
  }
}
