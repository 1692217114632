import {Component, Input} from "@angular/core";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import {IAttachment} from "../../interfaces/IAttachment";

@Component({
  selector: 'app-applied-file',
  templateUrl: './appliedFile.component.html',
  styleUrls: ['./appliedFile.component.scss']
})
export class AppliedFileComponent {
  arrowDown = faArrowDown;

  @Input() attachment: IAttachment;
}
