import { ICountry } from "../core/interfaces/ICountry";
import { ILang } from "../core/interfaces/ILang";
import {CommonActions} from "../actions/common.actions";
import {IDealer} from "../core/interfaces/IDealer";

export interface commonState {
  countries: ICountry[];
  langs: ILang[];
  dealers: IDealer[];
  inner_regions: string[];
  idb_connected: boolean;
}

const initialState = {
  countries: [],
  langs: [],
  dealers: [],
  inner_regions: [],
  idb_connected: false,
};

export const commonReducer = (state: commonState = initialState, action: any ) => {

  switch(action.type) {
    case(CommonActions.STORE_COUNTRIES):
      return {
        ...state,
        countries: action.payload
      };

    case(CommonActions.STORE_LANGUAGES):
      return {
        ...state,
        langs: action.payload
      };

    case(CommonActions.STORE_DEALERS):
      return {
        ...state,
        dealers: action.payload
      };

    case(CommonActions.STORE_INNER_REGIONS):
      return {
        ...state,
        inner_regions: action.payload
      };

    case(CommonActions.STORE_CONNECTED):
      return {
        ...state,
        idb_connected: true,
      };

    default:
      return {
        ...state
      }
  }
};
