import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WorkbookEditorComponent } from './workbookEditor/workbookEditor.component';
import { SurveyEditorComponent } from './surveyEditor/surveyEditor.component';
import { WorkbookPreviewComponent } from '../../core/components/workbookPreview/workbookPreview.component';
import { OfflineGuard } from '../../core/guards/offline.guard';
import { EditorsComponent } from './editors.component';
import { SurveyComponent } from '../../core/components/survey/survey.component';
import { DeactivateGuard } from '../../core/service/guards/deactivate.guard';

const routes: Routes = [
  { path: '', component: EditorsComponent, children: [
      { path: 'workbook/preview/:id', component: WorkbookPreviewComponent, canActivate: [OfflineGuard] },
      { path: 'workbook/:id', component: WorkbookEditorComponent, canActivate: [OfflineGuard], canDeactivate: [DeactivateGuard] },
      { path: 'workbook', component: WorkbookEditorComponent, canActivate: [OfflineGuard] },
      { path: 'survey', component: SurveyEditorComponent, canActivate: [OfflineGuard] },
      { path: 'survey/preview/:id', component: SurveyComponent, canActivate: [OfflineGuard] },
      { path: 'survey/:id', component: SurveyEditorComponent, canActivate: [OfflineGuard] },
  ]},
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class EditorsRoutingModule {}
