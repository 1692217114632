import {AfterContentChecked, Component, ElementRef, EventEmitter, OnDestroy, Output} from "@angular/core";
import {SearchService} from "../../service/search.service";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {faBlog} from "@fortawesome/free-solid-svg-icons";
import {StoreService} from "../../service/store.service";
import {Roles} from "../../const/roles";
import {PersistenceService, REQUESTS_STORE_GET} from "../../service/persistence.service";
import {NetworkStates} from "../../const/networkStates";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    '(window:scroll)': 'close($event)',
    '(document:click)': 'close($event)',
  },
})
export class HeaderComponent implements OnDestroy {
  searchState: boolean;
  showSearch: boolean;
  faBlog = faBlog;
  mobileShow: boolean;
  cookiesAccepted = true;
  disabled = false;

  searchStateSubscription: Subscription;
  cookiesSubscription: Subscription;
  routerEventsSubscription: Subscription;
  offlineModeSubscribtion: Subscription;

  @Output() hideWb: EventEmitter<any> = new EventEmitter<any>();


  constructor(
    private searchService: SearchService,
    private router: Router,
    private readonly persistenceService: PersistenceService,
    private storeService: StoreService,
    private _eref: ElementRef,
  ) {
    this.searchStateSubscription = this.searchService.searchState$.subscribe((searchState: boolean) => {
      this.searchState = searchState;
    });

    // this.routerEventsSubscription = this.router.events.subscribe((event: any) => {
    //   if (event instanceof NavigationEnd) {
    this.showSearch = window.location.href.indexOf('/training') >= 0 &&
      !(window.location.href.indexOf('/training/new') >= 0) &&
      !(window.location.href.indexOf('/training/edit') >= 0);
    //   }
    // });

    this.cookiesSubscription = this.storeService.getDataSubscription('commonReducer', 'idb_connected')
      .subscribe((isConnected: boolean) => {
        if (isConnected) {
          this.persistenceService.get(REQUESTS_STORE_GET, 'profile/GET')
            .then((profile) => {
              if (profile) {
                const user = typeof profile.data === 'string' ? JSON.parse(profile.data) : profile.data;
                this.persistenceService.get(REQUESTS_STORE_GET, `${user.email}/cookies/GET`)
                  .then((cookies) => {
                    this.cookiesAccepted = !!cookies && JSON.parse(cookies.data);
                  });
              }
            });
        }
      });

    this.offlineModeSubscribtion = this.storeService.getDataSubscription('utilsReducer', 'networkState')
      .subscribe((status: NetworkStates) => {
        this.disabled = status === NetworkStates.OFFLINE;
      });
  }

  toggleSearch($event) {
    $event.preventDefault();

    this.searchService.toggleSearchBlock(!this.searchState);
    document.querySelector('.mobile-wrapper').classList.remove('mobile-show')
  }

  toHome() {
    const role = this.storeService.getData('authReducer', 'profile').role;
    if (role === Roles.TRAINER || role === Roles.CONTENT_MANAGER || role === Roles.USER || role === Roles.TEAM_LEAD) {
      this.router.navigate(['/shared/overview']);
    } else {
      this.router.navigate(['/statistic/overall']);
    }
  }

  mobileMenu() {
    if (document.querySelector('.mobile-wrapper').classList.contains('mobile-show')) {
      document.querySelector('.mobile-wrapper').classList.remove('mobile-show')
    } else {
      document.querySelector('.mobile-wrapper').classList.add('mobile-show')
    }
    this.searchService.toggleSearchBlock(false);
    this.mobileShow = !this.mobileShow;
  }
  hideWbNav() {
    this.hideWb.emit();
  }

  close(event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      document.querySelector('.mobile-wrapper').classList.remove('mobile-show')
    }
  }

  ngOnDestroy() {
    if (this.searchStateSubscription) this.searchStateSubscription.unsubscribe();
    if (this.routerEventsSubscription) this.routerEventsSubscription.unsubscribe();
    if (this.cookiesSubscription) {
      this.cookiesSubscription.unsubscribe();
    }
  }
}
