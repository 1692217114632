import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import { IChapter } from '../../interfaces/IWorkbook';
import { WBBlockTypes } from '../../const/WBBlockTypes';
import { AttachmentService } from '../../service/attachment.service';
import { IAttachment } from '../../interfaces/IAttachment';
import { Subscription } from 'rxjs';
import { StoreService } from '../../service/store.service';
import { TrainingActions } from '../../../actions/training.actions';
import { WorkbookService } from '../../service/workbook.service';
import { trainingStatus } from '../../const/trainingStatus';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Roles } from '../../const/roles';
import { chapterTypes } from '../../const/chapterTypes';
import { NotificationService } from '../../service/notification.service';
import { TrainerNotesService } from '../../service/trainerNotes.service';
import { IComment } from '../../interfaces/IComment';
import {subscribeOn} from "rxjs/operators";
import {Router} from "@angular/router";

@Component({
  selector: 'app-chapter',
  templateUrl: './chapter.component.html',
  styleUrls: ['./chapter.component.scss'],
})
export class ChapterComponent implements OnInit, OnDestroy {
  chapter: IChapter;
  showChapter = false;
  @Input('chapter') set _chapter(value: IChapter) {
    this.chapter = value;

    if (this.role === Roles.CONTENT_MANAGER) {
      const subscription = this.attachmentService.fetchAttachmentsByParentId(value.id)
        .subscribe((attachments: IAttachment[]) => {
          this.attachments = attachments;
          subscription.unsubscribe();
        });
    }

    // Init notes
    this.initNotes();
  }

  @Input() isActive: boolean;
  attachments: IAttachment[];

  chapterTypes = chapterTypes;
  answer = '';
  status: string;
  trainingStatus = trainingStatus;
  roles = Roles;
  role = '';
  initCommentContent: string = '';
  commentsShowed = false;
  isIE: boolean;

  storeSubscription: Subscription;
  shouldSaveSubscription: Subscription;
  chapterContentSubscription: Subscription;

  get comments(): IComment[] {
    return this.role === Roles.TRAINER || this.role === Roles.TEAM_LEAD
      ? this.chapter.notes
      : this.role === Roles.REVIEWER
        ? this.chapter.comments
        : [];
  }

  constructor(
    private storeService: StoreService,
    private trainingActions: TrainingActions,
    private workbookService: WorkbookService,
    private attachmentService: AttachmentService,
    private notifications: NotificationService,
    private trainerNotesService: TrainerNotesService,
    private router: Router,
  ) {
    this.shouldSaveSubscription = this.workbookService.shouldSaveTests$
      .subscribe((shouldSave) => {
        if (shouldSave) {
          this.saveTest();
        }
      });

    this.role = this.storeService.getData('authReducer', 'profile').role;
    this.isIE =  /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
  }

  ngOnInit() {
    this.storeSubscription = this.storeService.getDataSubscription('workbookReducer', 'attachments')
      .subscribe((attachments: IAttachment[]) => {
        this.attachments = attachments.filter((attachment: IAttachment) => attachment.parentId === this.chapter.templateId);
      });

    this.answer = this.chapter.content;
  }

  setState() {
    if (this.chapter.content !== this.answer) {
      this.trainingActions.set_test_state({ id: this.chapter.id, state: false });
    } else {
      this.trainingActions.set_test_state({ id: this.chapter.id, state: true });
    }
  }

  saveTest() {
    const state = this.storeService.getData('trainingReducer', 'testsSaved')
      ? this.storeService.getData('trainingReducer', 'testsSaved').filter(test => test.id === this.chapter.id)[0]
      : true;

    if (state && !state.state) {
      this.chapter.content = this.answer;
      const wb = this.storeService.getData('workbookReducer', 'workbook');
      this.workbookService.saveChapter(this.chapter, wb.id)
        .subscribe((savedChapter: IChapter) => {
          this.workbookService.updateChapter(savedChapter);
          this.trainingActions.set_test_state({ id: this.chapter.id, state: true });
          this.chapter = savedChapter;
          this.workbookService.saveTestsTrigger(false);
          this.notifications.getNotification('success', 'Success! Your changes have been saved');
        },
                   (error) => {
                     this.notifications.getNotification('success', 'Oops, something went wrong. Please, try again');
                   });
    }
  }

  videoChanges(content: string) {
    return content.replace(new RegExp('oembed url', 'gi'), 'embed width="100%" src');
  }

  initNotes() {
    if (this.role === Roles.TEAM_LEAD || this.role === Roles.TRAINER) {
      this.trainerNotesService.addInitialNote(
          this.chapter.notes && this.chapter.notes.length
            ? Object.assign({}, { chapterId: this.chapter.id }, this.chapter.notes[0])
            : this.trainerNotesService.emptyNote(this.chapter.id),
      );

      this.chapterContentSubscription = this.storeService.getDataSubscription('trainerNotesReducer', 'initNotes')
          .subscribe((initNotes) => {
            this.initCommentContent = initNotes &&
                initNotes.filter(n => n.chapterId === this.chapter.id)[0] &&
                initNotes.filter(n => n.chapterId === this.chapter.id)[0].content;
          });

      this.commentsShowed = this.router.url.indexOf('training') === -1 && this.workbookService.isCat(this.chapter);
    }
  }

  commentUpdated($event: string): void {
    this.trainerNotesService.addUpdatedNote(Object.assign(
        {}, { chapterId: this.chapter.id }, { content: $event },
        ));
  }

  // sanitizeIframes(content) {
  //   let iframes = (content.match(new RegExp(/<iframe.*?s*src="http(.*?)".*?<\/iframe>/, 'g')) || [])
  //     .filter((iframe: string) => iframe.indexOf('<iframe') >= 0);
  //
  //   let sanitizedIframes = iframes.map((iframe: string) => {
  //     let src = iframe.match(/src\s*=\s*"(.+?)"/)[0];
  //
  //     src = src ? src.substring(5, src.length - 1) : '';
  //
  //     if (src) {
  //       iframe = iframe.replace(src, '{{' + src + ' | safe}}')
  //     }
  //
  //     iframe = iframe.replace('src', '[src]');
  //
  //     return iframe;
  //   });
  //
  //   if (iframes.length > 0) {
  //     iframes.forEach((iframe: string, index: number) => {
  //       content = content.replace(iframe, sanitizedIframes[index]);
  //     });
  //   }
  //
  //   return content;
  // }

  ngOnDestroy() {
    if (this.storeSubscription) { this.storeSubscription.unsubscribe(); }
    if (this.shouldSaveSubscription) { this.shouldSaveSubscription.unsubscribe(); }
    if (this.chapterContentSubscription) { this.chapterContentSubscription.unsubscribe(); }
  }
}
