import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class NavigationService {
  navToChapterSource: BehaviorSubject<string> = new BehaviorSubject(null);
  navToChapter$ = this.navToChapterSource.asObservable();
  navIsOpenSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  navIsOpen$ = this.navIsOpenSource.asObservable();

  navigateToChapter(chapterId: string) {
    this.navToChapterSource.next(chapterId);
  }

  toggleMenu(value: boolean) {
    this.navIsOpenSource.next(value)
  }
}
