import {INote} from "../core/interfaces/INote";
import {NotesActions} from "../actions/notes.actions";

export type NotesStore = {
  notes: INote[],
  notesSaved: { id: string, state: boolean }[]
};

export const initialState: NotesStore = {
  notes: [],
  notesSaved: []
};

export const notesReducer = (state: NotesStore = initialState, action: any) => {
  switch(action.type) {
    case NotesActions.STORE_WB_NOTES:
      return {
        ...state,
        notes: action.payload
      };

    case NotesActions.SET_NOTE_STATE:
      let notesState = state.notesSaved || [];

      if (state.notesSaved.filter((noteState) => noteState.id == action.payload.id).length > 0) {
        notesState = state.notesSaved.map((noteSaved: {id: string, state: boolean}) => {
          if (noteSaved.id == action.payload.id) {
            noteSaved.state = action.payload.state;
          }

          return noteSaved;
        });
      } else {
        notesState.push(action.payload);
      }

      return {
        ...state,
        notesSaved: notesState
      };

    case NotesActions.UPDATE_NOTE:
      let newNotes = state.notes || [];
      if (state.notes.filter(note => note.chapterId == action.payload.id).length > 0) {
        newNotes = state.notes.map((note) => {
          if (note.chapterId == action.payload.id) note.notes = action.payload.text;

          return note;
        });
      } else {
        newNotes.push(action.payload);
      }

      return {
        ...state,
        notes: newNotes,
      };

    case NotesActions.SKIP_SAVED_NOTES:
      return {
        ...state,
        notesSaved: [],
      };

    default:
      return {
        ...state
      }
  }
};
