import { RequestService } from './request.service';
import {Inject, Injectable, OnDestroy} from '@angular/core';
import { AuthAction } from '../../actions/auth.action';
import { catchError, map } from 'rxjs/internal/operators';
import { Observable, of, Subscription, throwError } from 'rxjs/index';
import { IProfile } from '../interfaces/IProfile';
import { IToken } from '../interfaces/IToken';
import { StoreService } from './store.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { GlobalErrorhandlerService } from './globalErrorhandler.service';
import { initialState } from '../../reducers/auth.reducer';
// import {DOCUMENT} from "@angular/platform-browser";
import { NotificationService } from './notification.service';
import { UserState } from '../const/userState';
import { Roles } from '../const/roles';
import {PersistenceService, REQUESTS_STORE_GET} from "./persistence.service";

@Injectable()
export class AuthService implements OnDestroy {
  globalErrorhandlerSubscription: Subscription;
  initialState = initialState;

  constructor(
      // @Inject(DOCUMENT) private document: any,
      private request: RequestService,
      private authAction: AuthAction,
      private storeService: StoreService,
      private router: Router,
      private globalErrorhandler: GlobalErrorhandlerService,
      private notificationService: NotificationService,
      private persistenceService: PersistenceService,
    ) {
    this.globalErrorhandlerSubscription = this.globalErrorhandler.authError$
        .subscribe((isAutherror: boolean) => {

          if (isAutherror && localStorage.getItem('refresh_expire')) {
            this.refresh();
          } else if (isAutherror) {
            this.logout();
          }
        });
  }

    /**
     * Login user
     *
     * @param {email: string, password: string} params
     * @return {Observable<any>}
     */
  login(params: any) {

    return this.request.post('LOGIN', params)
            .pipe(
              map((user: {token: IToken, profile: IProfile, trainingId?: string}) => {
                this.authAction.store_user_token_action(user ? user.token : this.initialState.token);
                this.authAction.store_user_profile_action(user ? user.profile : this.initialState.profile);
                localStorage.setItem('token', JSON.stringify(user ? user.token : ''));
                localStorage.setItem('refresh_expire', JSON.stringify({
                  expire: (new Date().getTime() + (user ? user.token.expires_in : 0) * 1000 + 3600 * 1000).toString(10),
                  refresh: user ? user.token.refresh_token : '',
                }));
                  localStorage.setItem('profile', JSON.stringify(user ? user.profile : this.initialState.profile));
                  this.persistenceService.save(REQUESTS_STORE_GET, { url: 'profile/GET', data: user.profile });

                return user;
              }),
              catchError((error: any) => throwError(error)),
            );
  }

    /**
     * Check user state by login (active/disactive/absent)
     *
     * @param {string} login
     *
     * @return {Observable<{ state: UserState, role: Roles }>}
     */
  userState(login: { login: string }): Observable<{ state: UserState, role: Roles }> {
    return this.request.post('LOGIN_VERIFY', login)
        .pipe(
          catchError((error) => {
            console.log('USER STATE ERROR: ', error);
            return throwError(error);
          }),
        );
  }

    /**
     * Register user
     */
  register(model: any) {
    model.dealerId = model.dealer.id == -1 ? null : model.dealer.id;
    model.dealer = model.dealer.id == -1 ? null : model.dealer;
    model.countryId = model.country.id;

    return this.request.post('ACCOUNT_REGISTER', model);
  }

    /**
     * Clear user credentials
     *
     */
  clearUserCreds() {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_expire');
  }

    /**
     * Logout from system
     */
  logout() {
    this.clearUserCreds();

    this.router.navigate(['/']);
  }

    /**
     * Fetch profile by token
     */
  fetchProfile(): Observable<IProfile> {

    return this.request.get('PROFILE', {}, {
      Authorization: `Bearer ${this.getToken()}`,
    }).pipe(
        map((profile: IProfile) => {
          this.authAction.store_user_profile_action(profile);

          return profile;
        }),
        catchError((error: any) => {
          console.log('Fetch profile error: ', error);

          return throwError(error);
        }),
      );
  }

  getToken() {
    const token = JSON.parse(localStorage.getItem('token'));

    if (token) {
      return token.access_token;
    }
    this.router.navigate(['/']);
    return;

  }

    /**
     * Verify user email
     *
     */
  verifyUser(code: string): Observable<{ result: boolean }> {
    return this.request.post('AUTH_VERIFY', { code })
        .pipe(
          map((verified: { result: boolean }) => verified),
          catchError((error: any) => {
            console.log('Verify code error: ', error);

            this.notificationService.getNotification('error', error.error.message);

            return throwError(error);
          }),
        );
  }

    /**
     * Refresh token
     *
     */
  refresh() {
    const refresh_expiration = JSON.parse(localStorage.getItem('refresh_expire'));

    if (refresh_expiration && moment(new Date(+refresh_expiration.expire)).isAfter(new Date())) {
      const refreshToken = refresh_expiration.refresh;

      this.request.post('AUTH_REFRESH', { refreshToken })
          .subscribe((token: IToken) => {
            this.authAction.store_user_token_action(token);
            localStorage.setItem('token', JSON.stringify(token));
            localStorage.setItem('refresh_expire', JSON.stringify({
              expire: (new Date().getTime() + token.expires_in * 1000 + 3600 * 1000).toString(10),
              refresh: token.refresh_token,
            }));
            document.location.reload();
          });
    } else {
      localStorage.removeItem('token');
      this.authAction.logout();
      this.logout();
    }
  }

    /**
     * Set new pasword to user
     */
  forgotPassword(email: { email: string }): Observable<any> {
    return this.request.post(
        'ACCOUNT_FORGOT',
        { email: email.email, forgotUrl: `https://${document.location.hostname}/reset` },
        )
        .pipe(
          map((resp: any) => resp),
          catchError((error: any) => {
            console.log('FORGOT ERROR: ', error);

            return throwError(error);
          }),
        );
  }

    /**
     * Reset user`s password
     *
     */
  resetPassword(model: { email: string, code: string, password: string }): Observable<IToken> {
    const updatedModel = Object.assign({}, model, { confirmUrl: `https://${document.location.hostname}/verified` });

    return this.request.post('ACCOUNT_RESET', updatedModel)
        .pipe(
          map((token: IToken) => {
            this.authAction.store_user_token_action(token);
            localStorage.setItem('token', JSON.stringify(token));
            localStorage.setItem('refresh_expire', JSON.stringify({
              expire: (new Date().getTime() + token.expires_in * 1000 + 3600 * 1000).toString(10),
              refresh: token.refresh_token,
            }));

            return token;
          }),
          catchError((error: any) => {
            console.log('RESET ERROR: ', error);

            return throwError(error);
          }),
        );
  }

  isUser(): boolean {
    return this.storeService.getData('authReducer', 'profile').role === Roles.USER;
  }

    getUserName(): string {
        return this.storeService.getData('authRreducer', 'profile').email;
    }

  ngOnDestroy() {
    this.globalErrorhandlerSubscription.unsubscribe();
  }
}
