import { Component } from "@angular/core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { StoreService } from "../../service/store.service";
import { IWorkbook } from "../../interfaces/IWorkbook";
import { WorkbookHelpers } from "../../service/workbookHelpers";
import { NavigationService } from "../../service/navigation.service";

@Component({
  selector: 'app-workbook-nav',
  templateUrl: './workbookNav.component.html',
  styleUrls: ['./workbookNav.component.scss']
})
export class WorkbookNavComponent {
  faTimes = faTimes;

  menu: any[];

  constructor(private storeService: StoreService, private navService: NavigationService) {
    this.storeService.getDataSubscription('workbookReducer', 'workbook')
      .subscribe((workbook: IWorkbook) => {
        if (workbook) this.menu = WorkbookHelpers.createWorkbookMenu(workbook.categories)
      })
  }

  stopPropagation($event) {
    $event.stopImmediatePropagation();
  }

  hideMenu() {
    this.navService.toggleMenu(false);
  }
}
