import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import { DevToolsExtension, NgRedux, NgReduxModule } from '@angular-redux/store';

import { AppComponent } from './app.component';
import { IAppState, INITIAL_STATE, rootReducer } from './store';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CoreModule } from './core/core.module';
import { AuthAction } from './actions/auth.action';
import { CommonActions } from './actions/common.actions';
import { LoaderService } from './core/service/loader.service';
import { TrainingActions } from './actions/training.actions';
import { NotificationService } from './core/service/notification.service';
import { NotesActions } from './actions/notes.actions';
import { WorkbookActions } from './actions/workbook.actions';
import { UtilsActions } from './actions/utils.actions';
import { ConnectionService } from 'ng-connection-service';
import { SurveyActions } from './actions/survey.actions';
import { EditorsModule } from './screens/editors/editors.module';
import { FiltersActions } from './actions/filters.actions';
import {TrainerNotesActions} from "./actions/trainerNotes.actions";
import {PersistenceService} from "./core/service/persistence.service";

// Required for AOT compilation
export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    NgReduxModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    EditorsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    AuthAction,
    CommonActions,
    TrainingActions,
    NotesActions,
    WorkbookActions,
    UtilsActions,
    SurveyActions,
    FiltersActions,
    TrainerNotesActions,
    LoaderService,
    NotificationService,
    ConnectionService,
    {
      provide: APP_INITIALIZER,
      useFactory: (persistenceService: PersistenceService) => () => persistenceService.connect(),
      deps: [PersistenceService],
      multi: true,
    },  ],
  exports: [CoreModule],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor (
        ngRedux: NgRedux<IAppState>,
        private devTools: DevToolsExtension,
    ) {
    const enhancers = [];
        // if (process.env.NODE_ENV === 'dev' && devTools.isEnabled()) {
        // enhancers = [ ...enhancers, devTools.enhancer() ];
        // }

    ngRedux.configureStore(rootReducer, INITIAL_STATE, [], enhancers);
  }
}
