import { IChapter, IChapterCategory, IWorkbook } from '../interfaces/IWorkbook';
import { ITraining } from '../interfaces/ITraining';
import { WBBlockTypes } from '../const/WBBlockTypes';

export class WorkbookHelpers {

  static createWorkbookMenu(categories: any[]) {

    const createBranch = (category: any): any => {
      const chapter = { id: category.id, title: category.title, chapters: [], color: category.backgroundColor || 'black' };

      if (category.chapters && category.chapters.length > 0) {
        chapter.chapters = category.chapters.map((item: IChapter | IChapterCategory) => createBranch(item));
      }

      return chapter;
    };

    return categories.map((item) => createBranch(item));
  }

  static createMeta(training: ITraining) {

    return {
      trainingId: training.id,
      start: training.startDate,
      end: training.endDate,
      dealer: training.dealer ? training.dealer.name : '',
      location: training.location.address,
      description: training.description,
    };
  }

  static createChaptersArray(workbook: IWorkbook) {
    const components = [];
    workbook.categories.forEach((category) => {
      // Add category title
      components.push({
        type: WBBlockTypes.HTML,
        title: category.title,
        id: category.id,
        description: category.description || '',
        content: category.content || '',
      });

      // Add current category chapters
      category.chapters.forEach((chapter: IChapter) => components.push(chapter));
    });

    return components;
  }

  // Collect all chapters and subchapters to one array
  static separateChapters(workbook: IWorkbook) {
    const chapters = [];
    const categories = JSON.parse(JSON.stringify(workbook.categories));

    const addChapter = (chapter: IChapter) => {
      chapters.push(chapter);

      if (chapter.chapters.length) {
        chapter.chapters.forEach((subChapter: IChapter) => {
          addChapter(subChapter);
        });
      }
    };

    categories.forEach((cat: IChapterCategory) => {
      cat.chapters.forEach((chapter: IChapter) => {
        addChapter(chapter);
      });
    });

    return chapters;
  }

  // Forming WB structure
  static collectWB(wb: IWorkbook, categories: IChapterCategory[], chapters: IChapter[]): IWorkbook {
    wb.categories = categories;

    const catIds = categories.map((cat: IChapterCategory) => cat.id);

    const chaptersTree = WorkbookHelpers.createChaptersTree(chapters, catIds);

    wb.categories.forEach((cat: IChapterCategory) => {
      cat.chapters = [];
      chaptersTree.forEach((ch: IChapter) => {
        if (ch.parentId == cat.id) {
          cat.chapters.push(ch);
        }
      });
    });

    return wb;
  }

  static createChaptersTree(chapters: IChapter[], catsIds: string[]): IChapter[] {
    let chaptersTree: IChapter[] = [];
    const tmpChapters = [].concat(chapters);

    // Separate chapters
    chaptersTree = tmpChapters
      .filter((ch: IChapter) => catsIds.indexOf(ch.parentId) >= 0)
      .map((ch: IChapter) => {
        ch.chapters = [];
        return ch;
      });

    let subChapters = tmpChapters
      .filter((ch: IChapter) => catsIds.indexOf(ch.parentId) < 0)
      .map((ch: IChapter) => {
        ch.chapters = [];
        return ch;
      });

    const addChapterToTree = () => {
      const addedSubchaptersIds = [];

      chaptersTree.forEach((ch: IChapter) => {
        subChapters.forEach((sub: IChapter) => {
          if (ch.id == sub.parentId) {
            addedSubchaptersIds.push(sub.id);
            ch.chapters.push(sub);
          }
        });
      });

      subChapters = subChapters.filter((sub: IChapter) => addedSubchaptersIds.indexOf(sub.id) < 0);

      if (subChapters.length > 0) {
        addChapterToTree();
      }
    };

    addChapterToTree();

    return chaptersTree;
  }
}
