import { Component, OnChanges, OnDestroy } from '@angular/core';
import { StoreService } from '../../../../core/service/store.service';
import { IQuestion, ISurvey } from '../../../../core/interfaces/ISurvey';
import { Subscription } from 'rxjs';
import { SurveyActions } from '../../../../actions/survey.actions';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { IChapterCategory } from '../../../../core/interfaces/IWorkbook';
import { SurveyService } from '../../../../core/service/survey.service';

@Component({
  selector: 'survey-editor-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class SurveyEditorMenu implements OnDestroy {
  questions: IQuestion[];
  currentQuestion: IQuestion;

  questionsSubscription: Subscription;
  currentQuestionSubscription: Subscription;

  constructor(
        private storeService: StoreService,
        private surveyActions: SurveyActions,
        private surveyService: SurveyService,
    ) {
    this.questionsSubscription = this.storeService.getDataSubscription('surveyReducer', 'questions')
            .subscribe((questions: IQuestion[]) => {
              this.questions = questions.length > 0 ? questions : [];
              const currentId = this.storeService.getData('surveyReducer', 'currentQuestionId');

              if (currentId) {
                this.currentQuestion = this.questions.filter(q => q.id === currentId)[0];
              }
            });

    this.currentQuestionSubscription = this.storeService.getDataSubscription('surveyReducer', 'currentQuestionId')
            .subscribe((currentId: string) => {
              this.currentQuestion = this.questions.filter(q => q.id === currentId)[0];
            });
  }

  changeCurrentQuestion(question: IQuestion) {
    this.surveyActions.set_current_question(question.id);
  }

  dropQuestion($event: CdkDragDrop<IChapterCategory[]>) {
    this.surveyService.changeQuestionPosition($event.previousIndex, $event.currentIndex);
  }

  ngOnDestroy() {
    this.currentQuestionSubscription.unsubscribe();
    this.questionsSubscription.unsubscribe();
  }
}
