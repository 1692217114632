import { faChartBar, faAlignLeft, faComments, faFile, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { Roles } from "../../const/roles";

export interface IMenuOption {
    name: string;
    icon: any;
    path: string;
    role: Roles[];
}

export const options: IMenuOption[] = [
    { name: 'workbook.main_menu.overview', icon: faChartBar, path: '/overview', role: [Roles.USER] },
    { name: 'workbook.main_menu.docs', icon: faAlignLeft, path: '/docs', role: [Roles.USER] },
    { name: 'workbook.main_menu.video', icon: faPlayCircle, path: '/video', role: [Roles.USER] },
    { name: 'workbook.main_menu.surveys', icon: faComments, path: '/surveys', role: [Roles.USER] },
    { name: 'workbook.main_menu.notes', icon: faFile, path: '/notes', role: [Roles.USER] }
]
