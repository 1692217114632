import { Injectable } from "@angular/core";

import {Observable} from "rxjs";
import {RequestService} from "./request.service";
import {AuthService} from "./auth.service";
import {ITrackingData} from "../interfaces/ITrackingData";

@Injectable()
export class TrackingService {

  constructor(private request: RequestService, private authService: AuthService) {}

  sendTrack(trackData: ITrackingData): Observable<any> {
    return this.request.post('STATISTIC_TRACKING', trackData, {
      "Authorization": `Bearer ${this.authService.getToken()}`
    })
  }
}
