import { Injectable } from "@angular/core";
import {BehaviorSubject, Observable, of, throwError} from "rxjs";
import {NotificationService} from "./notification.service";
import {Router} from "@angular/router";
import {LoaderService} from "./loader.service";

@Injectable()
export class GlobalErrorhandlerService {
  authErrorSource: BehaviorSubject<boolean> = new BehaviorSubject(null);
  authError$ = this.authErrorSource.asObservable();

  constructor(
    private notificationService: NotificationService,
    private loaderService: LoaderService,
    private router: Router
  ) {}

  processGlobalErrors(error: any): Observable<any> {
    if (error.status == 401) {
      this.authErrorSource.next(true);
      // this.notificationService.getNotification('error', 'Unauthorized');
      // this.loaderService.skipLoader();

      return throwError(error);
    } else {
      // this.notificationService.getNotification('error', error.message || error.text);
      return throwError(error);
    }
  }
}
