import { Injectable } from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class LoaderService {
  loaderStateSource: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loaderState$ = this.loaderStateSource.asObservable();
  activeRequests = 0;

  showLoader():void {

    if (this.activeRequests === 0) {
      this.loaderStateSource.next(true);
    }

    this.activeRequests++;
  }
  
  hideLoader():void {

    if (this.activeRequests === 1) {
      this.loaderStateSource.next(false);
    }

    this.activeRequests--;
  }

  skipLoader():void {
    if (this.activeRequests > 0) {
      this.activeRequests = 1;
      this.hideLoader();
    }
  }
}
