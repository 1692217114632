import {Component, ElementRef, ViewChild} from "@angular/core";
import {StoreService} from "../../service/store.service";
import {IProfile} from "../../interfaces/IProfile";
import { CONFIG } from '../../config';
import {Subscription} from "rxjs";

/**
 * Declares the WebChat property on the window object.
 */
declare global {
  interface Window {
    WebChat: any;
  }
}

window.WebChat = window.WebChat || {};

@Component({
  selector: 'app-bot',
  templateUrl: './bot.component.html',
  styleUrls: ['./bot.component.scss']
})
export class BotComponent {
  @ViewChild("botWindow", {static: true}) botWindowElement: ElementRef;

  constructor(private storeService: StoreService) {}

  ngOnInit(): void {
    let profileSubscription = this.storeService.getDataSubscription('authReducer', 'profile')
      .subscribe((profile: IProfile) => {

        if (profile.userId) {
          const directLine = window.WebChat.createDirectLine({
            secret: CONFIG.bot_key,
            websocket: false
          });

          window.WebChat.renderWebChat({
            directLine: directLine,
            userID: profile.userId,
            username: profile.email,
            botId: 'bridgelt',
          }, this.botWindowElement.nativeElement);

          if (profileSubscription) profileSubscription.unsubscribe();
        }
      });
  }
}
