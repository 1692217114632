export enum Roles {
    ADMIN = 'Admin',
    USER = 'User',
    TRAINER = 'Trainer',
    TEAM_LEAD = 'TeamLead',
    REGIONAL_ADMIN = 'RegionalAdministrator',
    CONTENT_MANAGER = 'ContentManager',
    GLOBAL_ADMIN = 'GlobalAdministrator',
    REVIEWER = 'Reviewer',
}
