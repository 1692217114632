import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input, NgZone, OnDestroy, OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { faChevronRight, faSave, faFile } from '@fortawesome/free-solid-svg-icons';
import { NotesService } from '../../../screens/training/notes.service';
import { StoreService } from '../../service/store.service';
import { INote } from '../../interfaces/INote';
import { NotesActions } from '../../../actions/notes.actions';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../service/notification.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnDestroy, OnInit {
  notes: any = '';
  // wasChanged = false;

  @Input() chapterId: string;
  // @Input() set isChanged(value: boolean) {
  //   this.wasChanged = value;
  // }

  // @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
  // @Output() save: EventEmitter<boolean> = new EventEmitter<boolean>();
  // @Output() changed: EventEmitter<string> = new EventEmitter();

  faChevronRight = faChevronRight;
  faSave = faSave;
  faFile = faFile;

  options = {
    toolbar: [
      ['bold', 'italic', 'strike'],
      ['blockquote'],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  };

  get disabled(): boolean {
    const noteData = this.storeService.getData('notesReducer', 'notes')
        .filter((note: INote) => note.chapterId === this.chapterId)[0];

    if (noteData) {
      return noteData.notes === this.notes;
    }

    return true;
  }

  shouldSaveSubscription: Subscription;
  notesSubscription: Subscription;

  constructor(
      private notesService: NotesService,
      private storeService: StoreService,
      private notesActions: NotesActions,
      private notifications: NotificationService,
      private zone: NgZone,
  ) {

  }

  ngOnInit() {
    this.notesSubscription = this.storeService.getDataSubscription('notesReducer', 'notes')
        .subscribe((notes) => {
          const noteData = notes.filter(note => note.chapterId === this.chapterId)[0];
          this.notes = noteData ? noteData.notes || noteData.content : '';
        });

    this.shouldSaveSubscription = this.notesService.shouldSaveAll$
        .subscribe((shouldSave: boolean) => {
          if (shouldSave && this.chapterId) {
            const note = this.storeService.getData('notesReducer', 'notesSaved')
                .filter(noteSaved => noteSaved.id === this.chapterId)[0];

            if (note && !note.state) {
              this.saveNotes();
            }
          }
        });
  }

  onChange($event) {
    const noteData = this.storeService.getData('notesReducer', 'notes').length > 0
        ? JSON.parse(JSON.stringify(this.storeService.getData('notesReducer', 'notes')))
            .filter((note: INote) => note.chapterId === this.chapterId)[0]
        : null;

    if (
        !noteData && $event.html ||
        noteData && this.prepareContentForComparation(noteData.notes || '') !==
        this.prepareContentForComparation(($event.html || '').trim() || '')
    ) {
      this.notesActions.set_note_saved({ id: this.chapterId, state: false });
    } else {
      this.notesActions.set_note_saved({ id: this.chapterId, state: true });
    }
  }

  saveNotes() {

    this.notesService.saveNotes(this.chapterId, this.notes)
        .subscribe((res: any) => {
          this.zone.run(() => {
            this.notesActions.set_note_saved({ id: res[0].chapter, state: true });
            this.notesActions.updateNote({ id: res[0].chapter, text: res[0].notes });
            this.notifications.getNotification('success', 'Success! Your changes have been saved');
            this.notes = res[0].notes;
          });
        },
                   (error) => {
                     this.notifications.getNotification('success', 'Oops, something went wrong. Please, try again');
                   });
  }

  // closeNotes() {
  //   this.close.emit(true);
  // }
  prepareContentForComparation(content: string): string {
    return content
        .replace(/\&(\w|\W){1,}?\;/g, '')
        .replace(/<[^>]*>/g, '')
        .replace(/\s/g, '')
        .replace(/\W/g, '');
  }

  ngOnDestroy() {
    this.notesActions.set_note_saved({ id: this.chapterId, state: true });
    if (this.shouldSaveSubscription) { this.shouldSaveSubscription.unsubscribe(); }
    if (this.notesSubscription) { this.notesSubscription.unsubscribe(); }
  }
}
