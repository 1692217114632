import {Injectable} from "@angular/core";
import {RequestService} from "./request.service";
import {catchError, map} from "rxjs/operators";
import {BehaviorSubject, Observable, of} from "rxjs";
import {AuthService} from "./auth.service";
import {ITraining} from "../interfaces/ITraining";
import {trainingStatus} from "../const/trainingStatus";
import {TrainingActions} from "../../actions/training.actions";
import {IWorkbook} from "../interfaces/IWorkbook";

@Injectable()
export class TrainingService {
  // newTrainingSource: BehaviorSubject<{total: number, items: ITraining[]}> = new BehaviorSubject(null);
  // activeTrainingSource: BehaviorSubject<{total: number, items: ITraining[]}> = new BehaviorSubject(null);
  // endedTrainingsSource: BehaviorSubject<{total: number, items: ITraining[]}> = new BehaviorSubject(null);
  // newTrainings$ = this.newTrainingSource.asObservable();
  // activeTrainings$ = this.activeTrainingSource.asObservable();
  // endedTrainings$ = this.endedTrainingsSource.asObservable();

  constructor(
    private request: RequestService,
    private authService: AuthService,
    private trainingActions: TrainingActions
  ) {}

  /**
   * Return unique code for new training
   *
   * @return {Observable<string>}
   */
  getTrainingCode(): Observable<{ code: string }> {

    return this.request.get('TRAINING_CODE', {}, {
      "Authorization": `Bearer ${this.authService.getToken()}`
    })
      .pipe(
        map((code: { code: string }) => code),
        catchError((error: any) => {
          // TODO: create error handler for errors in training service
          console.log("GET CODE ERROR: ", error);
          return of({ code: '' });
        })
      );
  }

  /**
   * Send all data to BE and creating new training
   *
   * @param {ITraining} trainingData. Collection with all required params of training
   *
   * @return {Observable<any>}
   */
  createTraining(trainingData: ITraining): Observable<any> {
    let preparedData = {};

    Object.keys(trainingData).forEach((key) => {
      if (trainingData[key]) {
        preparedData[key] = trainingData[key];
      }
    });

    return this.request.post('TRAINING_ADD', preparedData,{
      "Authorization": `Bearer ${this.authService.getToken()}`
    }).pipe(
      map((data: any) => data),
      catchError((error: any) => {
        // TODO: create error handler for errors in training service
        console.log("CREATE TRAINING ERROR: ", error);
        return of('');
      })
    );
  }

  /**
   * Send all data to BE and update existed training
   *
   * @param {ITraining} trainingData. Collection with all required params of training
   *
   * @return {Observable<any>}
   */
  updateTraining(trainingData: ITraining): Observable<any> {

    return this.request.put('TRAINING_UPDATE', trainingData,{
      "Authorization": `Bearer ${this.authService.getToken()}`
    }).pipe(
      map((data: any) => data),
      catchError((error: any) => {
        // TODO: create error handler for errors in training service
        console.log("CREATE TRAINING ERROR: ", error);
        return of('');
      })
    );
  }

  /**
   * Get new/added/closed trainings
   *
   * @param {trainingStatus} status
   * @param {number} skip
   * @param {number} take
   */
  fetchTrainings(status: trainingStatus, skip: number, take: number): Observable<any> {
    return this.request.get('TRAINING_LIST', { status, skip, take }, {
      "Authorization": `Bearer ${this.authService.getToken()}`
    })
    //   .subscribe((trainings: {total: number, items: ITraining[]}) => {
    //   status === trainingStatus.NEW
    //     ? this.newTrainingSource.next(trainings)
    //     : status === trainingStatus.ACTIVE
    //       ? this.activeTrainingSource.next(trainings)
    //       : this.endedTrainingsSource.next(trainings);
    // })
  }

  /**
   * Fetch training by id
   *
   * @param {string} id
   *
   * @return {Observable<ITraining>}
   */
  fetchTrainigById(id: string) {
    return this.request.get('TRAINING', { id }, {
      "Authorization": `Bearer ${this.authService.getToken()}`
    }).pipe(
      map((training: ITraining) => this.trainingActions.store_training(training))
    );
  }

  /**
   * Send complete training signal
   *
   * @param {string} id
   */
  completeTraining(id: string): Observable<boolean> {
    return this.request.post('TRAINING_END', { id }, {
      "Authorization": `Bearer ${this.authService.getToken()}`
    });
  }

  /**
   * Add training to user trainings list by code
   *
   * @param {string} code
   *
   * @return {Observable<ITraining>}
   */
  addTrainingToList(code: string): Observable<ITraining> {
    return this.request.post('TRAINING_ENTER', { code }, {
      "Authorization": `Bearer ${this.authService.getToken()}`
    });
  }

  /**
   * Fetch trainings count
   *
   * @return {Observable<number>}
   */
  fetchTrainingsCount(): Observable<{total: number}> {
    return this.request.get('TRAINING_TOTAL', null, {
      "Authorization": `Bearer ${this.authService.getToken()}`
    });
  }
}
