import { Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';
// import * as ClassicBuilder from '@ckeditor/ckeditor5-build-classic';
// import * as CustomBuilder from './ckeditor5-custom-build/build/ckeditor.js';

@Component({
  selector: 'app-richtext-editor',
  templateUrl: './richtextEditor.component.html',
  styleUrls: ['./richtextEditor.component.scss'],
})
export class RichtextEditorComponent {
  // public Editor = CustomBuilder; // ClassicBuilder;
  public html = '';
  editorUrl = 'build/ckeditor.js';
  config = {
    toolbar: [
          ['Bold', 'Italic', 'Underline', 'Subscript', 'Superscript', 'Strike'],
          ['Font', 'FontSize', 'Styles', 'Format', 'TextColor', 'BGColor'],
          ['Undo', 'Redo'],
          ['NumberedList', 'BulletedList'],
          ['Find', 'Replace'],
          ['CopyFormatting', 'RemoveFormat'],
          ['Outdent', 'Indent'],
          ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
          ['Link', 'Unlink'],
          ['Image', 'Table', 'Youtube', 'SpecialChar'],
          ['Maximize', 'Scayt'],
          ['SelectAll'],
          ['BidiLtr', 'BidiRtl', 'HorizontalRule'],
      ],
    format_tags: 'p;h1;h2;h3;pre',
    youtube_responsive: true,
    youtube_related: false,
    youtube_controls: true,
    allowedContent: true,
    extraPlugins: 'youtube,magicline',
  };

  @ViewChild('editor', { static: false }) editor: ElementRef;

  @Input() set incommingHTML(value: string) {
    this.html = value ? value : '';
  }
  @Output() changed: EventEmitter<string> = new EventEmitter<string>();

  contentChanged() {
    this.changed.emit(this.html);
  }
}
