import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-expand-btn',
  templateUrl: './expandBtn.component.html',
  styleUrls: ['./expandBtn.component.scss'],
})
export class ExpandBtnComponent {
  isOpened = false;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  @Input() set opened(value: boolean) {
    this.isOpened = value;
  }
  @Output() click: EventEmitter<boolean> = new EventEmitter();

  toggle($event) {
    $event.stopPropagation();
    this.isOpened = !this.isOpened;
    this.click.emit(this.isOpened);
  }
}
