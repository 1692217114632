import { Component, OnDestroy } from '@angular/core';
import { IQuestion, ISingleAnswer, ISurvey } from '../../interfaces/ISurvey';
import { Subscription } from 'rxjs';
import { trainingStatus } from '../../const/trainingStatus';
import { SurveyService } from '../../service/survey.service';
import { StoreService } from '../../service/store.service';
import { ITraining } from '../../interfaces/ITraining';
import { TrainingService } from '../../service/training.service';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../service/auth.service';
import { Location } from '@angular/common';
import { QuestionTypes } from '../../const/questionTypes';

@Component({
  selector: 'app-survey',
  templateUrl: 'survey.component.html',
  styleUrls: ['survey.component.scss'],
})
export class SurveyComponent implements OnDestroy {
  // _status: trainingStatus;
  training: ITraining;
  trainingId: string;
  faLeftArrow = faArrowLeft;
  // @Input() set status(value: trainingStatus) {
  //   this.init(value);
  //   this._status = value;
  // }

  // get status(): trainingStatus {
  //   return this._status;
  // }
  // @Output() changed: EventEmitter<any> = new EventEmitter();
  // @Output() valid: EventEmitter<boolean> = new EventEmitter();

  trainingStatus = trainingStatus;
  surveys: ISurvey[] = [];
  surveysAnswers: any[] = [];
  surveyResults: { totalQuestions: string, correctAnswers: string, surveyId: string, surveyTitle: string }[] = [];
  submit = false;
  singleAnswerQuestions: ISingleAnswer[] = [];
  selfDescribedQuestions: any;

  surveysSubscription: Subscription;
  trainingSubscription: Subscription;
  currentSurveySubscription: Subscription;

  constructor(
    private surveyService: SurveyService,
    private storeService: StoreService,
    private trainingService: TrainingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private _location: Location,
  ) {
    this.training = this.storeService.getData('trainingReducer', 'training');
    this.trainingId = this.training ? this.training.id : localStorage.getItem('trainingId');

    const surveyId = this.activatedRoute.snapshot.params['id'];

    if (surveyId) {
      this.initForPreview(surveyId);
    } else {
      this.initForUser();
    }
  }

  initForPreview(surveyId: string): void {
    this.surveyService.getSurveyById(surveyId);

    this.currentSurveySubscription = this.storeService.getDataSubscription('surveyReducer', 'currentSurvey')
          .subscribe((curSurvey: ISurvey) => {
            this.surveys = [{ ...curSurvey }];
            this.singleAnswerQuestions = this.surveys.reduce(
                  (sum: any[], s: ISurvey) => {
                    sum = sum.concat(s.questions.filter((q: IQuestion) => q.type == QuestionTypes.SINGLE));

                    return sum;
                  },
                  [],
              );

            this.selfDescribedQuestions = this.surveys.reduce(
                  (sum: any[], s: ISurvey) => {
                    sum = sum.concat(s.questions.filter((q: IQuestion) => q.type == QuestionTypes.SELFDESCRIPTION));

                    return sum;
                  },
                  [],
              );
          });
  }

  initForUser() {

    // if (status == trainingStatus.ACTIVE) {
    this.surveysSubscription = this.surveyService.getSurveyByTrainingId(
        'en',
        0,
        5,
        this.trainingId,
      ).subscribe((surveys: {totalCount: number, items: ISurvey[]}) => {
        if (surveys.totalCount != 0) {
          this.surveys = surveys.items;
          surveys.items.forEach((survey: ISurvey) => {
            this.surveysAnswers.push({
              parentId: this.trainingId,
              surveyId: survey.id,
              answers: [],
            });
          });
        }
      });

    if (!this.training) {
      this.trainingSubscription = this.trainingService.fetchTrainigById(this.trainingId)
          .subscribe(() => {
            this.training = this.storeService.getData('trainingReducer', 'training');
          });
    }
    // }
    // else {
    //   this.surveysSubscription = this.surveyService.getSurveyResults(this.trainingId)
    //     .subscribe((surveyResults: any[]) => this.surveyResults = surveyResults);
    // }
  }

  answerChanged($event, questionId: string, answerId: string, surveyId: string) {
    this.surveysAnswers = this.surveysAnswers.map((answer: any) => {
      if (answer.surveyId == surveyId) {

        // If we already have answers on this question
        if (answer.answers.some((question: {question: string, answers: { id: string, value: string | boolean }[]}) => question.question == questionId)) {

          answer.answers.forEach((question: {question: string, answers: { id: string, value: string | boolean }[]}) => {
            if (question.question == questionId) {
              question.answers = [{ id: answerId, value: $event.target.value }];
            }
          });
        } else {
          answer.answers.push({
            question: questionId,
            answers: [{ id: answerId, value: $event.target.value }],
          });
        }
      }

      return answer;
    });

    // this.valid.emit(this.checkSurveys());
    // this.changed.emit(this.surveysAnswers);
  }

  // Check that user answer on all questions in survey
  checkSurveys() {

    return this.surveys.every((survey: ISurvey) => {
      const current = this.surveysAnswers.filter((answer: {parentId: string, surveyId: string, answers: any[]}) =>
          answer.surveyId == survey.id,
        )[0];

      return survey.questions.length == current.answers.length && current.answers.every((answer: any) =>
          answer.answers.length > 0,
        );
    });
  }

  // Send survey answers to server, set that training is completed, redirect to overview page
  saveSurvey() {
    this.submit = true;

    if (this.checkSurveys()) {

      let count = 0;
      this.surveysAnswers.forEach((survey: ISurvey) => {
        const tmp = this.surveyService.sendSurveyAnswer(survey)
            .subscribe((resp) => {
              count++;
              tmp.unsubscribe();

              if (!(this.surveysAnswers.length - count)) {
                const completeSubscription: Subscription = this.trainingService.completeTraining(this.trainingId)
                  .subscribe((resp: boolean) => {
                    completeSubscription.unsubscribe();
                    this.router.navigate(['/shared/overview']);
                  });
              }
            });
      });
    }
  }

  goBack() {
    this.isUser()
        ? this.router.navigate([`/training/${this.trainingId}`])
        : this.router.navigate([`edit/survey/${this.activatedRoute.snapshot.params['id']}`]);
  }

  isUser(): boolean {
    return this.authService.isUser();
  }

  getSingleAnswers(model: IQuestion[]) {

    return this.isUser()
        ? model.filter((q: IQuestion) => q.type == QuestionTypes.SINGLE)
        : this.singleAnswerQuestions;
  }

  getSelfDescriptionQuestions(model: IQuestion[]) {

    return this.isUser()
        ? model.filter((q: IQuestion) => q.type == QuestionTypes.SELFDESCRIPTION)
        : this.selfDescribedQuestions;
  }

  ngOnDestroy() {
    if (this.surveysSubscription) { this.surveysSubscription.unsubscribe(); }
    if (this.trainingSubscription) { this.trainingSubscription.unsubscribe(); }
    if (this.currentSurveySubscription) { this.currentSurveySubscription.unsubscribe(); }

    localStorage.removeItem('trainingId');
  }
}
