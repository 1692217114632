import {AfterContentInit, Component} from '@angular/core';
import { TranslateService } from '../../node_modules/@ngx-translate/core';
import { StoreService } from './core/service/store.service';
import { AuthAction } from './actions/auth.action';
import { CommonService } from './core/service/common.service';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './core/service/auth.service';
import { Subscription } from 'rxjs';
import { ConnectionService } from 'ng-connection-service';
import { UtilsActions } from './actions/utils.actions';
import { NetworkStates } from './core/const/networkStates';
import { RequestService } from './core/service/request.service';
import {NotificationService} from "./core/service/notification.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterContentInit {
  title = 'UI';
  allDataLoaded = false;

  countriesSubscription: Subscription;
  langsSubscription: Subscription;
  dealersSubscription: Subscription;
  profileSubscription: Subscription;
  networkStateSubscription: Subscription;
  routesSubscription: Subscription;
  roleSubscription: Subscription;
  idbConnected: Subscription;

  constructor(
    private translate: TranslateService,
    private storeService: StoreService,
    private commonService: CommonService,
    private router: Router,
    private authActions: AuthAction,
    private authService: AuthService,
    private connectionService: ConnectionService,
    private utilsActions: UtilsActions,
    private request: RequestService,
    private notificationService: NotificationService,
  ) {

    if (navigator.onLine) {
      this.utilsActions.setNetworkState(NetworkStates.ONLINE);
    } else {
      this.utilsActions.setNetworkState(NetworkStates.OFFLINE);
    }

    this.networkStateSubscription = this.connectionService.monitor().subscribe(isConnected => {
      if (isConnected) {
        this.utilsActions.setNetworkState(NetworkStates.ONLINE);
      } else {
        this.notificationService
            .getNotification(
                'error',
                'You are in offline mode',
            );
        this.utilsActions.setNetworkState(NetworkStates.OFFLINE);
      }
    });

    // Store routes if user is online
    this.routesSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setPassedRoutes(event.url);
      }
    });
  }

  ngAfterContentInit() {
    this.initApp();
  }

  initApp() {
    this.idbConnected = this.storeService.getDataSubscription('commonReducer', 'idb_connected')
        .subscribe((idb: boolean) => {

          if (idb && !this.countriesSubscription) {
            if (this.storeService.getData('commonReducer', 'countries').length === 0) {
              this.countriesSubscription = this.commonService.fetchCounties()
                  .subscribe();
            }

            if (this.storeService.getData('commonReducer', 'langs').length === 0) {
              this.langsSubscription = this.commonService.fetchLangs()
                  .subscribe();
            }

            if (this.storeService.getData('commonReducer', 'dealers').length === 0) {
              this.dealersSubscription = this.commonService.fetchDealers()
                  .subscribe();
            }

            if (localStorage.getItem('token') && localStorage.getItem('token') !== 'undefined') {
              const token_data = JSON.parse(localStorage.getItem('token'));
              if (token_data && token_data.access_token) {
                this.authService.fetchProfile()
                    .subscribe();
              }
            }
          }
        });

    this.translate.setDefaultLang('en');
  }

  setPassedRoutes(url: string) {
    if (this.request._isOnline()) {
      const routes = localStorage.getItem('routes');

      if (routes) {
        const parsedRoutes = JSON.parse(routes);

        if (parsedRoutes.indexOf(url) < 0) {
          parsedRoutes.push(url);
          localStorage.setItem('routes', JSON.stringify(parsedRoutes));
        }
      } else {
        localStorage.setItem('routes', JSON.stringify([url]));
      }
    }
  }

  ngOnDestroy() {
    if (this.countriesSubscription) { this.countriesSubscription.unsubscribe(); }
    if (this.dealersSubscription) { this.dealersSubscription.unsubscribe(); }
    if (this.langsSubscription) { this.langsSubscription.unsubscribe(); }
    if (this.profileSubscription) { this.profileSubscription.unsubscribe(); }
    if (this.networkStateSubscription) { this.networkStateSubscription.unsubscribe(); }
    if (this.routesSubscription) { this.routesSubscription.unsubscribe(); }
    if (this.roleSubscription) { this.roleSubscription.unsubscribe(); }
    if (this.idbConnected) { this.idbConnected.unsubscribe(); }
  }
}
