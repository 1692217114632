import { Injectable } from "@angular/core";
import { NgRedux } from "@angular-redux/store";
import { IAppState } from "../../store";
import { Observable } from "rxjs";
import {Action} from "redux";

@Injectable()
export class StoreService {
    constructor(private ngRedux: NgRedux<IAppState>) {}

    getData(reducer: string, field: string): any {
      return this.ngRedux.getState()[reducer][field];
    }

    getDataSubscription(reducer: string, field: string):Observable<any> {
      return this.ngRedux.select([reducer, field]);
    }
}
