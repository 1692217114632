import {Component, Input} from "@angular/core";

interface IFileProgress {
  name: string;
  progress: number;
}

@Component({
  selector: 'app-uploading-doc',
  templateUrl: './uploadingDoc.component.html',
  styleUrls: ['./uploadingDoc.component.scss']
})
export class UploadingDocComponent {
  _file: IFileProgress;

  @Input() set file(value: IFileProgress) {
    this._file = value;
  }

  get file(): IFileProgress {
    return this._file;
  }
}
