import { IMultiAnswer, IQuestion, ISingleAnswer, ISurvey } from '../core/interfaces/ISurvey';
import { SurveyActions } from '../actions/survey.actions';
import { VisibilityState } from '../core/const/visibilityState';
import { applySourceSpanToStatementIfNeeded } from '@angular/compiler/src/output/output_ast';
import { QuestionTypes } from '../core/const/questionTypes';
import { QuestionAffiliation } from '../core/const/questionAffiliation';

export interface surveyState {
  initialSurvey: ISurvey | null;
  currentSurvey: ISurvey | null;
  initialQuestions: IQuestion[];
  questions: IQuestion[];
  initialOptions: any[];
  options: any[];
  currentQuestionId: string;
}

export const initialState = () => {
  return {
    initialSurvey: {
      id: '',
      title: '',
      state: VisibilityState.DRAFT,
      questions: [],
    },
    currentSurvey: {
      id: '',
      title: '',
      state: VisibilityState.DRAFT,
      questions: [],
    },
    initialQuestions: [],
    questions: [],
    initialOptions: [],
    options: [],
    currentQuestionId: '',
  };
};

export const surveyReducer = (state: surveyState = initialState(), action) => {
  switch (action.type) {
    case SurveyActions.SET_INIT_SURVEY:

      return {
        ...state,
        initialSurvey: { ...action.payload },
      };

    case SurveyActions.SET_CUR_SURVEY:

      return {
        ...state,
        currentSurvey: { ...action.payload },
      };

    case SurveyActions.CHANGE_CUR_SURVEY:

      return {
        ...state,
        currentSurvey: { ...action.payload },
      };

    case SurveyActions.STORE_QUESTIONS:

      return {
        ...state,
        questions: action.payload,
      };

    case SurveyActions.STORE_ANSWERS:

      return {
        ...state,
        options: action.payload,
      };

    case SurveyActions.ADD_QUESTION:
      const pos = state.questions
            .length;

      const question = {
        title: '',
        id: action.payload,
        options: [],
        pos,
        type: QuestionTypes.SINGLE,
        affiliation: QuestionAffiliation.TRAINER,
      };

      return {
        ...state,
        questions: state.questions.concat([question]),
        currentQuestionId: action.payload,
      };

    case SurveyActions.DEL_QUESTION:
      const updatedQuestions = state.questions
          .filter((q: IQuestion) => q.id !== action.payload)
          .map((q: IQuestion, i: number) => {
            q.pos = i;

            return q;
          });

      const currentQ = state.questions.filter((q: IQuestion) => q.id === action.payload)[0];

      const udatedCurrentQuestionId = currentQ.pos - 1 >= 0
          ? state.questions[currentQ.pos - 1].id
          : state.questions.length - 1 > 0
              ? state.questions[1].id
              : null;

      return {
        ...state,
        currentSurvey: {
          ...state.currentSurvey,
          questions: updatedQuestions,
        },
        questions: updatedQuestions,
        currentQuestionId: udatedCurrentQuestionId,
        options: state.options
                    .filter(o => o.parentId !== currentQ.id),
      };

    case SurveyActions.SET_CURRENT_QUESTION:

      return {
        ...state,
        currentQuestionId: action.payload,
        questions: [...state.questions],
      };

    case SurveyActions.CHANGE_CUR_QUESTION:

      return {
        ...state,
        currentSurvey: {
          ...state.currentSurvey,
          questions: state.currentSurvey.questions.map((q: IQuestion) => {

            return q.id === action.payload.id ? action.payload : q;
          }),
        },
        questions: state.questions.map((q: IQuestion) => {
          return q.id === action.payload.id ? action.payload : q;
        }),
      };

    case SurveyActions.ADD_ANSWER:

      return {
        ...state,
        questions: state.questions.map((q: IQuestion) => {
          if (q.id === action.payload.parentId) {
            q.options = state.options
                .filter(o => o.parentId === action.payload.id)
                .concat([action.payload]);
          }

          return q;
        }),
        options: state.options.concat([action.payload]),
      };

    case SurveyActions.DEL_ANSWER:

      return {
        ...state,
        options: [...((st, ch) => {
          return st.concat(
              ch
                  .filter(o => o.id !== action.payload.id)
                  .map((o, i) => {
                    o.pos  = i;

                    return o;
                  }),
          );
        })(
            state.options.filter(o => o.parentId !== action.payload.parentId),
            state.options.filter(o => o.parentId === action.payload.parentId),
        ),
        ],
        questions: state.questions.map((q: IQuestion) => {
          if (q.id === action.payload.parentId) {
            q.options = (q.options as any[])
                .filter(o => o.id !== action.payload.id)
                .map((o, i) => {
                  o.pos = i;

                  return { ...o };
                });
          }

          return { ...q };
        }),
      };

    case SurveyActions.CHANGE_ANSWER:
      return {
        ...state,
        options: state.options.map(o => o.id === action.payload.id ? action.payload : o),
      };

    case SurveyActions.CLEAR_ALL:

      return {
        ...initialState(),
      };

    default:

      return {
        ...state,
      };
  }
};
