import {Component, EventEmitter, Input, Output} from "@angular/core";
import {IButton} from "../../interfaces/IButton";
import {ButtonTypes} from "../../const/buttonTypes";

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
    @Input() data: IButton;
    @Input() disabled: boolean;
    @Output() click: EventEmitter<string> = new EventEmitter();

    onClick($event) {
        $event.stopPropagation();

        this.click.emit(this.data.type);
    }
}
