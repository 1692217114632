import { Component, Input } from "@angular/core";
import { IComment } from '../../interfaces/IComment';

@Component({
    selector: 'app-comments-list',
    templateUrl: './commentsList.component.html',
    styleUrls: ['./commentsList.component.scss']
})
export class CommentsListComponent {
    @Input() comments: IComment[] = [];
}