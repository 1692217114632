import {Component, EventEmitter, Input, Output} from '@angular/core';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { StoreService } from '../../service/store.service';
import { Roles } from '../../const/roles';
import { IComment } from '../../interfaces/IComment';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
})

export class CommentsComponent {
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  isOpened = false;

  @Input() comments: IComment[] = [];
  @Input() incomingHtml: string;
  @Output() commentUpdated: EventEmitter<string> = new EventEmitter();

  get title(): string {
    return `${this.comments.length} ${(this.comments.length > 1) ? ' comments' : ' comment'}`;
  }

  get hideComments(): boolean {
    return this.storeService.getData('authReducer', 'profile').role !== Roles.TRAINER &&
            this.storeService.getData('authReducer', 'profile').role !== Roles.TEAM_LEAD;
  }

  constructor(private storeService: StoreService) {}

  toggle() {
    this.isOpened = !this.isOpened;
  }

  changeComment($event) {
    this.commentUpdated.emit($event);
  }
}
