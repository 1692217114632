import { NgModule } from "@angular/core";
import { EditorsRoutingModule } from "./editors-routing.module";
import { WorkbookEditorComponent } from "./workbookEditor/workbookEditor.component";
import { CoreModule } from "../../core/core.module";
import { SurveyEditorComponent } from "./surveyEditor/surveyEditor.component";
import { ChapterEditorComponent } from "./workbookEditor/chapterEditor/chapterEditor.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CategoryEditorComponent } from "./workbookEditor/categoryEditor/categoryEditor.component";
import { ColorPickerModule } from "ngx-color-picker";
import { ColorPickerComponent } from "./colorPicker/colorPicker.component";
import { WbLeftMenuComponent } from "./workbookEditor/leftMenu/wbLeftMenu.component";
import { WbMenuOptionComponent } from "./workbookEditor/leftMenu/wbMenuOption/wbMenuOption.component";
import { TranslateModule } from "@ngx-translate/core";
import { OpenQuestionComponent } from "./workbookEditor/openQuestion/openQuestion.component";

import { DragDropModule } from '@angular/cdk/drag-drop'
import { WbEditorCanDeactivate } from "./wbEditor.canDeactivate";
import {SurveyEditHeader} from "./surveyEditor/header/header.component";
import {SurveyEditorMenu} from "./surveyEditor/menu/menu.component";
import {QuestionComponent} from "./surveyEditor/question/question.component";
import {AnswersComponent} from "./surveyEditor/answers/answers.component";
import {MultiOptionComponent} from "./surveyEditor/answers/multi/multi.component";
import {SingleAnswerComponent} from "./surveyEditor/answers/single/single.component";
import {EditorsComponent} from "./editors.component";

@NgModule({
  imports: [
    EditorsRoutingModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    ColorPickerModule,
    TranslateModule,
    DragDropModule
  ],
  declarations: [
    WorkbookEditorComponent,
    SurveyEditorComponent,
    ChapterEditorComponent,
    CategoryEditorComponent,
    ColorPickerComponent,
    WbLeftMenuComponent,
    WbMenuOptionComponent,
    OpenQuestionComponent,
    SurveyEditHeader,
    SurveyEditorMenu,
    QuestionComponent,
    AnswersComponent,
    MultiOptionComponent,
    SingleAnswerComponent,
    EditorsComponent
  ],
  providers: [
    WbEditorCanDeactivate
  ],
  exports: [
      EditorsComponent
  ]
})
export class EditorsModule {}
