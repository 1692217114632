import { Component } from "@angular/core";
import { IChapterCategory } from "../../../../core/interfaces/IWorkbook";
import { StoreService } from "../../../../core/service/store.service";
import { Subscription } from "rxjs";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {WorkbookService} from "../../../../core/service/workbook.service";

@Component({
  selector: 'app-wb-create-menu',
  templateUrl: './wbLeftMenu.component.html',
  styleUrls: ['./wbLeftMenu.component.scss']
})
export class WbLeftMenuComponent {
  categories: IChapterCategory[] = [];

  categoriesSubscription: Subscription;

  constructor(
    private storeService: StoreService,
    private workbookService: WorkbookService
  ) {
    this.categoriesSubscription = this.storeService.getDataSubscription('workbookReducer', 'categories')
      .subscribe((categories: IChapterCategory[]) => {
        this.categories = categories;
      })
  }

  dropCat($event: CdkDragDrop<IChapterCategory[]>) {
    this.workbookService.changeCatOrderPosition($event.previousIndex, $event.currentIndex);
  }

  ngOnDestroy() {
    this.categoriesSubscription.unsubscribe();
  }
}
