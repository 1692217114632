import {RequestService} from "./request.service";
import {BehaviorSubject, Observable, of} from "rxjs";
import {catchError, map, tap} from "rxjs/operators";
import {AuthService} from "./auth.service";
import {IAttachment} from "../interfaces/IAttachment";
import {Injectable} from "@angular/core";

@Injectable()
export class AttachmentService {
  shouldSaveSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  shouldSaveAttachment$ = this.shouldSaveSource.asObservable();

  constructor(private request: RequestService, private authService: AuthService) {}

  storeFiles(file: File): Observable<any> {
    const formData = new FormData();
    formData.append("file", file);

    return this.request.postWithStatus('ATTACHMENT_UPLOAD', formData, {
      "Authorization": `Bearer ${this.authService.getToken()}`
    }).pipe(
      map((data: any) => data),
      catchError((error: any) => {
        // TODO: create error handler for errors in training service
        console.log("POST FILE ERROR: ", error);
        return of('');
      })
    );
  }

  deleteAttachedDoc(filename: string): Observable<any> {
    return this.request.delete('ATTACHMENT', {filename}, {
      "Authorization": `Bearer ${this.authService.getToken()}`
    }).pipe(
      map((data: any) => data),
      catchError((error: any) => {
        // TODO: create error handler for errors in training service
        console.log("DELETE FILE ERROR: ", error);
        return of('');
      })
    );
  }

  storeAttachment(attachment: IAttachment): Observable<any> {
    return this.request.post('ATTACHMENT_ADD', attachment, {
      "Authorization": `Bearer ${this.authService.getToken()}`
    }).pipe(
      map((data: any) => data),
      catchError((error: any) => {
        // TODO: create error handler for errors in training service
        console.log("ADD ATTACHMENT ERROR: ", error);
        return of('');
      })
    );
  }

  fetchAttachmentsByParentId(parentId: string): Observable<IAttachment[]> {
    return this.request.get('ATTACHMENT_LIST', {parentId}, {
      "Authorization": `Bearer ${this.authService.getToken()}`
    });
  }

  saveAllAttachments() {
    this.shouldSaveSource.next(true);
  }
}
