import {Component, Input} from "@angular/core";

@Component({
    selector: 'survey-editor-answer-multi',
    templateUrl: './multi.component.html',
    styleUrls: ['./multi.component.scss']
})
export class MultiOptionComponent {
    @Input() disabled: boolean;
}
