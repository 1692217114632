import { NetworkStates } from "../core/const/networkStates";
import {UtilsActions} from "../actions/utils.actions";

export type UtilsState = {
  networkState: NetworkStates
}

export const initialState:UtilsState  = {
  networkState: NetworkStates.ONLINE
};

export const utilsReducer = (state: UtilsState = initialState, action: any) => {
  switch(action.type) {
    case(UtilsActions.SET_NETWORK_STATE):
      return {
        ...state,
        networkState: action.payload
      };

    default:
      return {
        ...state
      };
  }
};
