import { Injectable } from "@angular/core";
import { NgRedux } from "@angular-redux/store";
import {AuthStore} from "../../reducers/auth.reducer";
import {Observable, of} from "rxjs";
import {AuthService} from "./auth.service";
import {map} from "rxjs/operators";
import {IProfile} from "../interfaces/IProfile";
import {StoreService} from "./store.service";

@Injectable()
export class RoleService {

    constructor(private storeService: StoreService, private authService: AuthService) {}

    // Return role
    getRole(): string {
        return this.storeService.getData('authReducer', 'profile').role;
    }

    // Check is role "admin"
    isGlobalAdmin(): boolean {
        return this.storeService.getData('authReducer', 'profile').role === 'GlobalAdministrator';
    }

    // Check is role "admin"
    isRegionAdmin(): boolean {
        return this.storeService.getData('authReducer', 'profile').role === 'RegionalAdministrator';
    }

    // Check is role "trainer"
    isTrainer(): Observable<boolean> {
      return this.storeService.getData('authReducer', 'profile').role
        ? of(this.storeService.getData('authReducer', 'profile').role === 'Trainer')
        : this.authService.fetchProfile().pipe(
          map(() => this.storeService.getData('authReducer', 'profile').role === 'Trainer')
        );
    }

    // Check is role "user"
    isUser(): boolean {
        return this.storeService.getData('authReducer', 'profile').role === 'User';
    }
}
