import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../store';
import { Injectable } from '@angular/core';
import { IComment } from '../core/interfaces/IComment';

@Injectable()
export class TrainerNotesActions {
  static initialTrainerNote = '[Trainer notes] Add init note to initNotes array';
  static updatedTrainerNote = '[Trainer notes] Add/Change note to/in array with updated notes';
  static setInitialState = '[Trainer notes] Set initial state';

  constructor(private ngRedux: NgRedux<IAppState>) {}

  addInitialTrainerNote(initialNote: IComment): void {
    this.ngRedux.dispatch({
      type: TrainerNotesActions.initialTrainerNote,
      payload: initialNote,
    });
  }

  addUpdatedNote(updatedNote: IComment): void {
    this.ngRedux.dispatch({
      type: TrainerNotesActions.updatedTrainerNote,
      payload: updatedNote,
    });
  }

  skipToInitial(): void {
    this.ngRedux.dispatch({
      type: TrainerNotesActions.setInitialState,
    });
  }
}
