import { Injectable } from "@angular/core";
import { IAppState } from "../store";
import { NgRedux } from "@angular-redux/store";
import { IToken } from "../core/interfaces/IToken";
import {IProfile} from "../core/interfaces/IProfile";

@Injectable()
export class AuthAction {
    static LOGIN = '[Auth] Login';
    static LOGOUT = '[Auth] Logout';
    static REGISTER = '[Auth] Register';
    static REFRESH = '[Auth] Refresh';
    static STORE_USER_TOKEN = '[Auth] Store user token';
    static STORE_USER_PROFILE = '[Auth] Store user profile';

    constructor(private ngRedux: NgRedux<IAppState>) {}

    store_user_token_action(token: IToken) {
        this.ngRedux.dispatch({
            type: AuthAction.STORE_USER_TOKEN,
            payload: token
        });
    }

    store_user_profile_action(user: IProfile) {
        this.ngRedux.dispatch({
            type: AuthAction.STORE_USER_PROFILE,
            payload: user,
        });
    }

    logout() {
      this.ngRedux.dispatch({
        type: AuthAction.LOGOUT
      });
    }
}
