import { Component, EventEmitter, Input, Output } from "@angular/core";
import { $E } from "codelyzer/angular/styles/chars";

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  current = 0;
  mobile: boolean = false;

  @Input() total: number;
  @Input() set default(val: number) {
    this.current = val >= 0 ? val : 0;
  }

  @Output() changed: EventEmitter<number> = new EventEmitter();

  get pages(): number[] {

    return !this.total
      ? []
      : Array.from({ length: Math.ceil(this.total / 6) }).map((item: any, i: number) => i);
  }

  get last(): number {
    return Math.ceil(this.total / 6) - 1;
  }

  switchPage($event: Event, page: number): void {
    $event.preventDefault();

    // this.current = page;
    this.changed.emit(page);
  }

  previousPage($event: Event): void {
    $event.preventDefault();

    if (this.current != 0) {
      // this.current--;
      this.changed.emit(this.current - 1);
    }
  }

  nextPage($event: Event): void {
    $event.preventDefault();

    if (this.current < Math.ceil(this.total / 6) - 1) {
      // this.current++;
      this.changed.emit(this.current + 1);
    }
  }

  avgLeft() {
    this.current = Math.ceil(this.current / 2);
    this.changed.emit(this.current);
  }

  avgRight() {
    this.current = Math.ceil(((this.pages.length - 1) + this.current) / 2);
    this.changed.emit(this.current);
  }

  ngOnInit() {
    if (window.screen.width <= 768) {
      this.mobile = true;
    }
  }
}
