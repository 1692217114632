import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { StoreService } from './store.service';

@Injectable()
export class StatisticService {

  constructor(private request: RequestService, private authService: AuthService, private storeService: StoreService) {}

  convertForSelects(obj: any, idField: string, titleField: string): { id: string, title: string } {
    return {
      id: obj[idField],
      title: obj[titleField],
    };
  }

  // Fetch data for overall KPI`s
  fetchOverallKPI(filters: any): Observable<any> {
    return this.request.post('STATISTIC_OVERALL', { period: filters }, {
      Authorization: `Bearer ${this.authService.getToken()}`,
    });
  }

  // Fetch data for consultants KPIs
  fetchConsultantsKPI(filters: any): Observable<any> {
    return this.request.post('STATISTIC_CONSULTANTS', { period: filters }, {
      Authorization: `Bearer ${this.authService.getToken()}`,
    });
  }

  // Get url for excell file with summary
  fetchSummaryUrl(period): Observable<{ filename: string }> {
    return this.request.post('STATISTIC_SUMMARY', period, {
      Authorization: `Bearer ${this.authService.getToken()}`,
    });
  }
}
