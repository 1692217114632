import { Component } from "@angular/core";
import {userMenuOptions} from "./options";
import {StoreService} from "../../service/store.service";
import {IProfile} from "../../interfaces/IProfile";
import {Subscription} from "rxjs";
import { faUser, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import {AuthService} from "../../service/auth.service";
import {WorkbookService} from "../../service/workbook.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-user-menu',
  templateUrl: './userMenu.component.html',
  styleUrls: ['./userMenu.component.scss']
})
export class UserMenuComponent {
  faUser = faUser;
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;

  options = userMenuOptions;
  profile: IProfile;
  isOpened = false;

  profileSubscroption: Subscription;

  constructor(
    private storeService: StoreService,
    private authService: AuthService,
    private workbookService: WorkbookService,
    private router: Router
  ) {
    this.profileSubscroption = this.storeService.getDataSubscription('authReducer', 'profile')
      .subscribe((profile: IProfile) => this.profile = profile);
  }

  togleMenu() {
    this.isOpened = !this.isOpened;
  }

  processClick($event, path) {
    if (path === '/logout'){

      if (this.router.url.indexOf('/edit/workbook') >= 0 && this.workbookService.wbWasChanged()) {
        let confirm = window.confirm('You have unsaved data. Do you want logout without saving?');

        if (confirm) {
          this.authService.logout();
        }
      } else {
        this.authService.logout();
      }
    }
  }
}
