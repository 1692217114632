import { combineReducers, Reducer } from 'redux';
import { authReducer } from './reducers/auth.reducer';
import { commonReducer } from './reducers/common.reducer';
import { trainingReducer } from './reducers/training.reducer';
import { notesReducer } from './reducers/notes.reducer';
import { workbookReducer } from './reducers/workbook.reducer';
import { utilsReducer } from './reducers/utils.reducer';
import { surveyReducer } from './reducers/survey.reducer';
import { filtersReducer } from './reducers/filters.reducer';
import { trainerNotesReducer } from './reducers/trainerNotes.reducer';

export interface IAppState {}

export const INITIAL_STATE: IAppState = {};

export const rootReducer: Reducer = combineReducers<any>({
  authReducer,
  commonReducer,
  trainingReducer,
  notesReducer,
  workbookReducer,
  utilsReducer,
  surveyReducer,
  filtersReducer,
  trainerNotesReducer,
});
