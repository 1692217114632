import { Component, Input } from "@angular/core";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { WorkbookActions } from "../../../actions/workbook.actions";
import { AttachmentService } from "../../service/attachment.service";
import {Subscription} from "rxjs";
import {IAttachment} from "../../interfaces/IAttachment";

@Component({
  selector: 'app-attached-doc',
  templateUrl: './attachedDoc.component.html',
  styleUrls: ['./attachedDoc.component.scss']
})
export class AttachedDocComponent {
  faTrash = faTrashAlt;

  _attachedDoc: FormGroup;
  incommingAttachment: any;

  @Input() set attachedDocModel(value: any) {
    this._attachedDoc = this.createAttachedFileModel(value.filename, value.name, value.description, value.parentId, value.workbookId);
    this.incommingAttachment = {
      filename: value.filename,
      name: value.name,
      description: value.description,
      parentId: value.parentId,
      workbookId: value.workbookId,
      shouldSave: value.shouldSave,
    };
  }

  get attachedDocModel():any {
    return this._attachedDoc;
  }

  shouldSaveSubscription: Subscription;

  constructor(
    private _fb: FormBuilder,
    private workbookActions: WorkbookActions,
    private attachmentService: AttachmentService
  ) {
    this.shouldSaveSubscription = this.attachmentService.shouldSaveAttachment$
      .subscribe((shouldSave: boolean) => {

        if (shouldSave && this.wasChanged() || this.incommingAttachment && this.incommingAttachment.shouldSave) {
          this.attachmentService.storeAttachment(this._attachedDoc.value)
            .subscribe((attachment: IAttachment) => {
              this.incommingAttachment.filename = attachment.filename;
              this.incommingAttachment.name = attachment.name;
              this.incommingAttachment.description = attachment.description;
              this.incommingAttachment.parentId = attachment.parentId;
              this.incommingAttachment.shouldSave = false;
              this.incommingAttachment.workbookId = attachment.workbookId;
            });
        }
      })
  }

  createAttachedFileModel(server_name: string, file_name: string, description: string, parentId: string, workbookId: string): FormGroup {
    let _fb = this._fb.group({
      filename: [''],
      name: ['', Validators.maxLength(255)],
      description: ['', Validators.maxLength(255)],
      parentId: [''],
      workbookId: [''],
    });

    _fb.controls['filename'].setValue(server_name);
    _fb.controls['name'].setValue(file_name);
    _fb.controls['description'].setValue(description);
    _fb.controls['parentId'].setValue(parentId);
    _fb.controls['workbookId'].setValue(workbookId);

    return _fb;
  }

  titleChanged($event):void {
    this.workbookActions.changeAttachmentProp({
      attachment: this._attachedDoc.value,
      prop: 'title',
      value: $event.target.value,
    });

    if (this.wasChanged()) {
      this.workbookActions.attachment_changed(this._attachedDoc.value)
    } else {
      this.workbookActions.attachment_not_changed(this._attachedDoc.value)
    }
  }

  descriptionChanged($event) {
    this.workbookActions.changeAttachmentProp({
      attachment: this._attachedDoc.value,
      prop: 'description',
      value: $event.target.value
    });

    if (this.wasChanged()) {
      this.workbookActions.attachment_changed(this._attachedDoc.value)
    } else {
      this.workbookActions.attachment_not_changed(this._attachedDoc.value)
    }
  }

  deleteDoc($event) {
    this.attachmentService.deleteAttachedDoc(this._attachedDoc.controls['filename'].value)
      .subscribe(() => {
        this.workbookActions.del_attachment(this._attachedDoc.value);
      });
  }

  wasChanged() {
    return this.incommingAttachment && Object.keys(this.incommingAttachment).filter(key => key != 'shouldSave').some((key: string) =>
      this.incommingAttachment[key] != this._attachedDoc.controls[key].value
    );
  }

  ngOnDestroy() {
    this.shouldSaveSubscription.unsubscribe();
  }
}
