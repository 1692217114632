export const URLS = {
  LOGIN: 'Auth/login',
  LOGIN_VERIFY: 'auth/loginverify',
  COUNTRIES: 'Dictionary/countries',
  LANGUAGES: 'Dictionary/languages',
  DEALERS: 'Dictionary/dealers',
  INNER_REGIONS: 'dictionary/innerregions',
  PROFILE: 'Account/profile',
  ACCOUNT_REGISTER: 'account/register',
  ACCOUNT_FORGOT: 'account/forgot',
  ACCOUNT_RESET: 'account/reset',
  REFRESH: 'Auth/refresh',
  AUTH_VERIFY: 'auth/verified',
  AUTH_REFRESH: 'auth/refresh',
  TRAINING: 'training',
  TRAINING_CODE: 'Training/newCode',
  TRAINING_ADD: 'training/add',
  TRAINING_LIST: 'training/list',
  TRAINING_END: 'training/end',
  TRAINING_ENTER: 'training/enter',
  TRAINING_UPDATE: 'training/update',
  TRAINING_TOTAL: 'training/count',
  ATTACHMENT: 'attachment',
  ATTACHMENT_UPLOAD: 'attachment/upload',
  ATTACHMENT_ADD: 'attachment/add',
  ATTACHMENT_LIST: 'attachment/list',
  SURVEY: 'survey',
  SURVEY_SAVE: 'survey/save',
  SURVEY_LIST: 'survey/list',
  SURVEY_ANSWER: 'survey/answer',
  SURVEY_RESULTS: 'survey/results',
  SURVEY_CREATE: 'survey/emptysurvey',
  SURVEY_CREATE_QUESTION: 'survey/emptyquestion',
  SURVEY_CREATE_ANSWER: 'survey/emptyoption',
  NOTES: 'notes',
  NOTES_SAVE: 'notes/save',
  WORKBOOK: 'workbook',
  WORKBOOK_LIST: 'workbook/list',
  WORKBOOK_SAVE_CHAPTER: 'workbook/saveChapter',
  WORKBOOK_CREATE_CHAPTER: 'workbook/emptychapter',
  WORKBOOK_CREATE_WB: 'workbook/emptyworkbook',
  WORKBOOK_CREATE_CATEGORY: 'workbook/emptycategory',
  WORKBOOK_UPLOAD_CONTENT: 'workbook/uploadcontent',
  WORKBOOK_SAVE: 'workbook/save',
  WORKBOOK_ADD_REVIEW: 'workbook/add/review',
  STATISTIC_TRACKING: 'statistic/tracking',
  STATISTIC_OVERALL: 'statistic/overall',
  STATISTIC_SUMMARY: 'statistic/fillinsummary',
  STATISTIC_CONSULTANTS: 'statistic/learningTime',
  FILTERS_INIT: 'filter/init',
};
