import {AfterContentInit, Component, Input, OnDestroy} from '@angular/core';
import { IQuestion, ISurvey } from '../../../../core/interfaces/ISurvey';
import { Subscription } from 'rxjs';
import { StoreService } from '../../../../core/service/store.service';
import { SurveyActions } from '../../../../actions/survey.actions';
import { FormBuilder, FormGroup } from '@angular/forms';
import { QuestionTypes } from '../../../../core/const/questionTypes';
import { QuestionAffiliation } from '../../../../core/const/questionAffiliation';

@Component({
  selector: 'survey-editor-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
})
export class QuestionComponent implements OnDestroy, AfterContentInit {
  questionModel: FormGroup;
  isShowed = false;

  @Input() disabled: boolean;

  currentQuestionSubscription: Subscription;

  constructor(private storeService: StoreService, private surveyActions: SurveyActions, private fb: FormBuilder) {
    this.questionModel = this.fb.group({
      id: [],
      title: [],
      pos: [],
      options: [],
      type: [],
      affiliation: [],
    });
  }

  ngAfterContentInit() {
    this.currentQuestionSubscription = this.storeService.getDataSubscription('surveyReducer', 'questions')
          .subscribe((questions: IQuestion[]) => {
            const currentId = this.storeService.getData('surveyReducer', 'currentQuestionId');
            const curQuestion = questions.filter((q: IQuestion) => q.id === currentId)[0];

            this.isShowed = curQuestion && curQuestion.pos >= 0;

            this.questionModel.controls['id'].setValue(curQuestion ? curQuestion.id : '');
            this.questionModel.controls['title'].setValue(curQuestion ? curQuestion.title : '');
            this.questionModel.controls['pos'].setValue(curQuestion ? curQuestion.pos : -1);
            this.questionModel.controls['options'].setValue(curQuestion ? curQuestion.options : []);
            this.questionModel.controls['type'].setValue(curQuestion ? curQuestion.type : QuestionTypes.SINGLE);
            this.questionModel.controls['affiliation'].setValue(curQuestion ? curQuestion.affiliation : QuestionAffiliation.TRAINER);
          });
  }

  questionChanged() {
    this.surveyActions.question_changed(this.questionModel.value);
  }

  delQuestion($event) {
    $event.stopImmediatePropagation();

    const currentSurvey: ISurvey = JSON.parse(
            JSON.stringify(this.storeService.getData('surveyReducer', 'currentSurvey')),
        );

    currentSurvey.questions = currentSurvey.questions.filter((q: IQuestion) =>
            q.id !== this.questionModel.controls['id'].value,
        );

    this.surveyActions.del_question(this.questionModel.controls['id'].value);
  }

  ngOnDestroy() {
    this.currentQuestionSubscription.unsubscribe();
  }
}
