import { Injectable } from "@angular/core";
import {NgRedux} from "@angular-redux/store";
import {IAppState} from "../store";

@Injectable()
export class UtilsActions {
  static SET_NETWORK_STATE = '[Utils] set network state';

  constructor(private ngRedux: NgRedux<IAppState>) {}

  setNetworkState(state: string) {
    this.ngRedux.dispatch({
      type: UtilsActions.SET_NETWORK_STATE,
      payload: state
    })
  }
}
