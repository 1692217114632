import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import {RequestService} from "../service/request.service";
import {Injectable} from "@angular/core";
import {NotificationService} from "../service/notification.service";

@Injectable()
export class OfflineGuard implements CanActivate {

  constructor(private request: RequestService, private notifications: NotificationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<boolean> | boolean{

    if (this.request._isOnline()) {
      return true;
    } else {
      let routes = localStorage.getItem('routes');
      this.notifications.getNotification('error', 'You under offline mode');

      return routes.indexOf(route.url.join('')) > 0;
    }
  }
}
