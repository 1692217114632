import {Component, Input, Output, EventEmitter, ChangeDetectorRef, ElementRef} from '@angular/core';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  host: {
    '(document:click)': 'close($event)',
  },
})

export class MultiselectComponent {
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;
  data: {}[] = [];
  current: any[] = [];
  isOpened = false;

  @Input() title: string;
  @Input() subTitle: string;
  @Input() max = 2;
  @Input() disabled = true;

  @Input()
  set options(value: {}[]) {
    this.data = value;
  }

  @Input() set currentOptions(value: any) {
    this.current = value.values.length ? value.values : [this.data[0]];
  }

  @Output() selected = new EventEmitter<any>();

  constructor(private _eref: ElementRef) {}

  selectItem(item: object) {
    this.current = [item];
    this.selected.emit(this.current);
  }

  removeItem(item: object) {
    this.current = this.current.filter(i => item[this.subTitle] !== i[this.subTitle]);
    this.selected.emit(this.current);
  }

  isChecked(item: object): boolean {

    const prop = item['id'] ? 'id' : item['code'] ? 'code' : 'title';

    return this.current.some(d => {

      return d[prop] === item[prop];
    });
  }

  selectedItem($event, item: object): void {
    $event.stopPropagation();
    this.isOpened = false;

    const prop = item['id'] ? 'id' : item['code'] ? 'code' : 'title';

    if (this.current[0][prop] === item[prop]) {
      return;
    }

    this.selectItem(item);

    // if (this.current[0][this.subTitle] === 'All' && item[this.subTitle] === 'All') {
    //   return;
    // }
    //
    // if (this.current.some(i => i[this.subTitle] === item[this.subTitle])) {
    //   this.removeItem(item);
    // } else {
    //   this.isChecked(item) ? this.removeItem(item) : this.selectItem(item);
    // }
  }

  toggle($event) {
    $event.stopPropagation();
    this.isOpened = !this.isOpened;
  }

  close(event) {
    if (!this._eref.nativeElement.contains(event.target)) // or some similar check
      this.isOpened = false;
  }
}
