import { Injectable } from "@angular/core";
import { NgRedux } from "@angular-redux/store";
import { commonState } from "../reducers/common.reducer";
import {ICountry} from "../core/interfaces/ICountry";
import {ILang} from "../core/interfaces/ILang";
import {IDealer} from "../core/interfaces/IDealer";
import {IInnerRegion} from "../core/interfaces/IInnerRegion";

@Injectable()
export class CommonActions {
  static STORE_COUNTRIES = '[Common] Fetch countries';
  static STORE_LANGUAGES = '[Common] Fetch languages';
  static STORE_DEALERS = '[Common] Fetch dealers';
  static STORE_INNER_REGIONS = '[Common] Fetch inner regions';
  static STORE_CONNECTED = '[Common] indexDB connected';

  constructor(private ngRedux: NgRedux<commonState>) {}

  store_countries_action(countries: ICountry[]) {
    this.ngRedux.dispatch({
      type: CommonActions.STORE_COUNTRIES,
      payload: countries
    });
  }

  store_langs_action(langs: ILang[]) {
    this.ngRedux.dispatch({
      type: CommonActions.STORE_LANGUAGES,
      payload: langs
    });
  }

  store_dealers_action(dealers: IDealer[]) {
    this.ngRedux.dispatch({
      type: CommonActions.STORE_DEALERS,
      payload: dealers
    });
  }

  store_inner_regions(inner_regions: IInnerRegion[]) {
    this.ngRedux.dispatch({
      type: CommonActions.STORE_INNER_REGIONS,
      payload: inner_regions
    });
  }

  store_idb_connected() {
    this.ngRedux.dispatch({
      type: CommonActions.STORE_CONNECTED,
      payload: true,
    });
  }
}
