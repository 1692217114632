import { Injectable } from "@angular/core";
import { RequestService } from "../../core/service/request.service";
import {BehaviorSubject, Observable} from "rxjs";
import { AuthService } from "../../core/service/auth.service";
import { INote } from "../../core/interfaces/INote";
import { StoreService } from "../../core/service/store.service";

@Injectable()
export class NotesService {
  shouldSaveAllSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  shouldSaveAll$ = this.shouldSaveAllSource.asObservable();

  constructor(
    private request: RequestService,
    private authService: AuthService,
    private storeService: StoreService
  ) {}

  fetchNotes(parentId: string): Observable<INote[]> {

    return this.request.get('NOTES', { parentId }, {
      "Authorization": `Bearer ${this.authService.getToken()}`
    });
  }

  saveNotes(chapterId: string, notes: string): Observable<INote[]> {
    let parentId = this.storeService.getData('trainingReducer', 'training').id;
    return this.request.post('NOTES', { chapter: chapterId, notes, parentId }, {
      "Authorization": `Bearer ${this.authService.getToken()}`
    });
  }

  unsavedNotesExisting(): boolean {
    let noteStates = this.storeService.getData('notesReducer', 'notesSaved');

    return noteStates.some((noteState) => !noteState.state);
  }

  selectUnsavedNotes(): { id: string, state: boolean } {
    return this.storeService.getData('notesReducer', 'notes')
      .notesSaved
      .filter((noteSaved) => !noteSaved.state);
  }

  saveNotesTrigger(value: boolean) {
    this.shouldSaveAllSource.next(value);
  }
}
