import { Component, Input } from '@angular/core';
import { IChapter, IChapterCategory } from '../../../../../core/interfaces/IWorkbook';
import { colors } from '../../../../../core/const/colors';
import { Subscription } from 'rxjs';
import { StoreService } from '../../../../../core/service/store.service';
import { WorkbookService } from '../../../../../core/service/workbook.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { NavigationService } from '../../../../../core/service/navigation.service';

@Component({
  selector: 'app-wb-create-menu-opt',
  templateUrl: './wbMenuOption.component.html',
  styleUrls: ['./wbMenuOption.component.scss'],
})
export class WbMenuOptionComponent {
  // @Input() category: IChapterCategory;
  _id: string;
  category: IChapterCategory;
  chapters: IChapter[];
  colors = colors;
  @Input() set id(value: string) {
    this._id = value;

    this.category = this.storeService.getData('workbookReducer', 'categories')
      .filter((cat: IChapterCategory) => cat.id == this._id)[0];

    this.chapters = this.storeService.getData('workbookReducer', 'chapters')
      .filter((ch: IChapter) => ch.parentId == this._id);
  }

  catSubscription: Subscription;
  chapSubscription: Subscription;

  constructor(
    private storeService: StoreService,
    private workbookService: WorkbookService,
    private navService: NavigationService,
  ) {
    this.catSubscription = this.storeService.getDataSubscription('workbookReducer', 'categories')
      .subscribe((categories: IChapterCategory[]) => {
        this.category = categories.filter((cat: IChapterCategory) => cat.id == this._id)[0];
      });

    this.chapSubscription = this.storeService.getDataSubscription('workbookReducer', 'chapters')
      .subscribe((chapters: IChapter[]) => {
        this.chapters = chapters.filter((ch: IChapter) => this._id && ch.parentId == this._id);
      });
  }

  formatOrderPos(pos: number): string {
    return pos < 9
      ? '0' + (pos + 1)
      : '' + (pos + 1);
  }

  dropChapter($event: CdkDragDrop<IChapter[]>) {
    this.workbookService.changeChapterOrderPosition(this._id, $event.previousIndex, $event.currentIndex);
  }

  goTo($event, id: string) {
    $event.stopImmediatePropagation();
    this.navService.navigateToChapter(id);
    this.navService.toggleMenu(false);
  }

  ngOnDestroy(): void {
    this.catSubscription.unsubscribe();
    this.chapSubscription.unsubscribe();
    this.navService.navigateToChapter('');
  }
}
