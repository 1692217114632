import { Injectable } from '@angular/core';
import { TrainerNotesActions } from '../../actions/trainerNotes.actions';
import { IComment } from '../interfaces/IComment';
import { StoreService } from './store.service';
import { RequestService } from './request.service';
import { AuthService } from './auth.service';
import { Subscription } from 'rxjs';
import { WorkbookService } from './workbook.service';
import * as _ from 'lodash';

@Injectable()
export class TrainerNotesService {

  constructor(
      private trainerNotesActions: TrainerNotesActions,
      private storeService: StoreService,
      private requestService: RequestService,
      private authService: AuthService,
      private workbookService: WorkbookService,
  ) {}

  // Create empty note
  emptyNote(chapterId: string): IComment {
    return {
      chapterId,
      id: '',
      content: '',
      created: '',
      author: null,
    };
  }

  // Add initial note to array with initial notes from each chapter/subchapter
  addInitialNote(initialNote: IComment): void {
    this.trainerNotesActions.addInitialTrainerNote(initialNote);
  }

  // Add changed note to list with updated notes if its content changed
  addUpdatedNote(updatedNote: { chapterId: string, content: string }): void {
    const init = this.storeService.getData('trainerNotesReducer', 'initNotes')
        .filter(n => n.chapterId === updatedNote.chapterId)[0];

    this.trainerNotesActions.addUpdatedNote(
          (init ? { ...init, ...updatedNote } : { ...updatedNote, id: '', parentId: '', created: '' }),
      );
  }

  // Save all updated notes
  saveUpdatedNotes(wbId: string): void {
    const initNotes: IComment[] = this.storeService.getData('trainerNotesReducer', 'initNotes');
    const notes: IComment[] = this.storeService.getData('trainerNotesReducer', 'notes')
        .filter((n: IComment) =>
            this.workbookService.prepareContentForComparation(
                initNotes.find((initNote: IComment) => initNote.chapterId === n.chapterId).content || '',
            ) !== this.workbookService.prepareContentForComparation(n.content || ''),
        );
    const subscriptions: Subscription[] = [];

    notes.forEach((n, i) => {
      const params = _.pickBy(Object.assign({},  n, { workbookId: wbId }));

      subscriptions[i] = this.requestService.post(
          'NOTES_SAVE',
          params,
          {
                    Authorization: `Bearer ${this.authService.getToken()}`,
                  })
          .subscribe((data: IComment[]) => {
            this.trainerNotesActions.addInitialTrainerNote(data[0]);
            this.trainerNotesActions.addUpdatedNote(data[0]);
            subscriptions[i].unsubscribe();
          });
    });
  }

  // Compare notes with init notes
  notesWasChanged(notes: IComment[]): boolean {
    const init: IComment[] = this.storeService.getData('trainerNotesReducer', 'initNotes') || [];

    return notes.some((n: IComment) => {

        return this.workbookService.prepareContentForComparation(
            init.find((initNote: IComment) => initNote.chapterId === n.chapterId).content || '',
        ) !== this.workbookService.prepareContentForComparation(n.content || '');
    });
  }

  // Skip trainer notes state to init state
  skipToInitialState() {
    this.trainerNotesActions.skipToInitial();
  }
}
