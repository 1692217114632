import {Component, ElementRef, ViewChild} from "@angular/core";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {ISearchResult} from "../../interfaces/ISearchResult";
import {StoreService} from "../../service/store.service";
import {IChapter, IChapterCategory, IWorkbook} from "../../interfaces/IWorkbook";
import {NavigationService} from "../../service/navigation.service";
import {SearchService} from "../../service/search.service";
import {IAttachment} from "../../interfaces/IAttachment";
import {INote} from "../../interfaces/INote";
import {SearchMenuOptions} from "../../const/searchMenuOptions";

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent {
    faSearch = faSearch;
    results: ISearchResult[];
    tmpResults = [];
    private static stringTrimStart = 15;
    private static stringTrimEnd = 80;
    searchVal = '';
    private static attachments: IAttachment[] = [];
    private static notes: INote[] = [];
    options: { title: SearchMenuOptions, color: string }[] = [
        { title: SearchMenuOptions.ALL, color: '#003468' },
        { title: SearchMenuOptions.CONTENT, color: '#003468' },
        { title: SearchMenuOptions.NOTES, color: '#003468' },
        { title: SearchMenuOptions.FILES, color: '#003468' },
        { title: SearchMenuOptions.VIDEOS, color: '#CEC8C1' },
        { title: SearchMenuOptions.IMAGES, color: '#CEC8C1' }
    ];
    selector = SearchMenuOptions.ALL;

    constructor(
        private storeService: StoreService,
        private navService: NavigationService,
        private searchService: SearchService
    ) {}

    search(value: string) {
        let workbook: IWorkbook = this.storeService.getData('workbookReducer', 'workbook');
        this.tmpResults = [];
        this.results = [];

        SearchComponent.attachments = this.storeService.getData('workbookReducer', 'attachments');
        SearchComponent.notes = this.storeService.getData('notesReducer', 'notes');

        if (workbook && value.length >= 3) {

            workbook.categories
                .forEach((category: IChapterCategory) => {
                    category.chapters.forEach((chapter: IChapter) => {
                        this.searchInChapter(chapter, value);
                    })
                });

            // Reduce to common structure [{type: sting, count: number, results: [{chapter: string, id: string, text: string}]}]
            this.results = this.tmpResults.reduce((results: any[], item: any) => {

                if (results.some((res: any) => res.type == item.type)) {

                    results = results.map((res:any) => {

                        if (res.type == item.type) {
                            res.results = res.results.concat([item]);
                            res.count = res.results.length;
                        }

                        return res;
                    });

                } else {
                    results = results.concat({
                        type: item.type,
                        count: 1,
                        results: [item]
                    });
                }

                return results;
            }, []);


        }

        if (!value || value.length < 3) {
            this.results = [];
        }
    }

    getOptionCount(optionName: string) {
        let count = 0;

        if (optionName == SearchMenuOptions.ALL) {
            count = this.results
                ? this.results.reduce((sum, res) => {
                    sum += res.results.length;

                    return sum;
                }, 0)
                : 0;
        }else {
            count = this.results && this.results.filter(res => res.type == optionName).length
                ? this.results.filter(res => res.type == optionName)[0].count
                : 0;
        }

        return count;
    }

    changeSelector(value: SearchMenuOptions) {
        this.selector = value;
    }

    filterResults() {

        return this.results && this.results.filter(res => res.type == this.selector).length
            ? this.results.filter(res => res.type == this.selector)[0].results
            : this.selector == SearchMenuOptions.ALL
                ? this.results
                    ? this.results.reduce((sum, res) => {
                        sum = sum.concat(res.results);

                        return sum;
                    }, [])
                    : [{title: '', text: 'No results found'}]
                : [{title: '', text: 'No results found'}];
    }

    private static createResultText(index: number, content: string, value: string) {
        return index - SearchComponent.stringTrimStart < 0
            ? content.substring(0, index + value.length + SearchComponent.stringTrimEnd).length + 1 > content.length
                ? content.substring(0, index + value.length + SearchComponent.stringTrimEnd)
                : content.substring(0, index + value.length + SearchComponent.stringTrimEnd) + '...'
            : (content.substring(0, index).length > SearchComponent.stringTrimStart ? '...' : '' ) +
            content.substring(index - SearchComponent.stringTrimStart, index + value.length + SearchComponent.stringTrimEnd) +
            (content.substring(index + value.length).length > SearchComponent.stringTrimEnd ? '...' : '')
    }

    private static cleanContent(content: string): string {

        return content && content.replace(/<[^>]*>/g, '');
    }

    private static searchInString(propertyValue: string, value: string): { text: string, type: string }[] {
        let content = SearchComponent.cleanContent(propertyValue);
        let result = [];
        let index = content.toLowerCase().indexOf(value.toLowerCase());

        // For content
        while(index >= 0) {

            result.push({
                type: SearchMenuOptions.CONTENT,
                text: SearchComponent.createResultText(index, content, value)
            });

            content = content.substring(index + value.length);
            index = content.toLowerCase().indexOf(value.toLowerCase());
        }

        // For images
        var imageAlt = propertyValue
            .match(new RegExp(/alt=".*?"/, 'gi'));

        // If value existing in alt
        if (imageAlt && imageAlt.length > 0) {
            imageAlt.forEach((alt: string) => {
                if (alt.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
                    result.push({
                        type: SearchMenuOptions.IMAGES,
                        text: alt
                    });
                }
            });
        }

        return result;
    }

    private static searchInObject(propertyValue: any, value: string) {

        return propertyValue.length > 0
            ? propertyValue.filter((item: string) => item.toLowerCase().indexOf(value.toLowerCase()) >= 0)
                .map((res: string) => { return { type: SearchMenuOptions.CONTENT, text: res}; })
            : [];
    }

    private static searchInProperty(property: string | object, value: string): {type: string, text: string}[] {

        if (property && typeof(property) == 'string') {
            return SearchComponent.searchInString(property, value);
        } else if (property && typeof(property) == 'object' ) {
            return SearchComponent.searchInObject(property, value);
        }

        return [];
    }

    private static searchInAttachments(chapterId: string, value: string) {
        let results = [],
            attachments: IAttachment[] = [];

        // Get chapter attachments
        attachments = SearchComponent.attachments
            .filter((attachment: IAttachment) => attachment.parentId == chapterId);

        attachments.forEach((attachment: IAttachment) => {
            // Check name
            if (attachment.name.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
                results.push({ type: SearchMenuOptions.FILES, text: attachment.name });
            }

            // Check file name
            if (attachment.filename.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
                results.push({ type: SearchMenuOptions.FILES, text: attachment.filename })
            }

            // Check file description
            let description = attachment.description,
                index = description.toLowerCase().indexOf(value.toLowerCase());

            while (index >= 0) {

                results.push({
                    type: SearchMenuOptions.FILES,
                    text: SearchComponent.createResultText(index, description, value)
                });

                description = description.substring(index + value.length);
                index = description.toLowerCase().indexOf(value.toLowerCase());
            }
        });

        return results;
    }


    private static searchInNotes(chapterId: string, value) {
        let chapterNotesData = SearchComponent.notes.filter((note: INote) => note.chapterId == chapterId)[0];
        let chapterNotes = chapterNotesData ? SearchComponent.cleanContent(chapterNotesData.notes) : '';
        let results = [];

        if (value && chapterNotes && chapterNotes.indexOf(value) >= 0) {
            results.push({
                type: SearchMenuOptions.NOTES,
                text: SearchComponent.createResultText(chapterNotes.indexOf(value), chapterNotes, value)
            });
        }

        return results;
    }

    private searchInChapter(chapter: IChapter, value: string) {
        // let results = [];

        Object.keys(chapter).forEach((key: string) => {
            if (chapter.hasOwnProperty(key) && key != 'chapters') {
                this.tmpResults = this.tmpResults.concat(
                    SearchComponent.searchInProperty(chapter[key], value).map((res) => {
                        res['title'] = chapter.title;
                        res['id'] = chapter.id;

                        return res;
                    })
                );
            }

            if (key == 'chapters') {
                chapter.chapters.forEach((chapter) => {
                    this.searchInChapter(chapter, value);
                })
            }
        });

        // Add attachments results
        this.tmpResults = this.tmpResults.concat(
            SearchComponent.searchInAttachments(chapter.id, value)
                .map((res) => {
                    res['title'] = chapter.title;
                    res['id'] = chapter.id;

                    return res;
                })
        );

        // Add notes results
        this.tmpResults = this.tmpResults.concat(
            SearchComponent.searchInNotes(chapter.id, value)
                .map((res) => {
                    res['title'] = chapter.title;
                    res['id'] = chapter.id;

                    return res;
                })
        );
    }

    prepareRes(res: {title: string, id: string, text: string, type: string}) {
        let preparedText ='';

        if (res.type == SearchMenuOptions.IMAGES) {
            preparedText = res.title.indexOf(this.searchVal) >= 0 && this.searchVal
                ? res.title.replace(new RegExp(this.searchVal, 'gi'), '<span>' + this.searchVal + '</span>')
                : res.title;
        } else if (res.type == SearchMenuOptions.ALL) {
            preparedText = res.title
        } else {
            preparedText = res.text.match(new RegExp(this.searchVal, 'gi')) && this.searchVal
                ? res.text.replace(new RegExp(this.searchVal, 'gi'), '<span>' + this.searchVal + '</span>')
                : res.text;
        }

        return preparedText;
    }

    goTo(chapterId: string) {
        this.searchService.toggleSearchBlock(false);

        let timeout = setTimeout(() => {
            this.navService.navigateToChapter(chapterId);
            clearTimeout(timeout);
        }, 0);

    }

    showCat(index: number) {
        let res = this.filterResults();

        return index < 0
            ? true
            : res[index + 1]
                ? res[index].type != res[index + 1].type
                : false;
    }

    closeSearch($event) {
        $event.preventDefault();

        this.searchService.toggleSearchBlock(false);
    }
}
