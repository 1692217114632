import {Component, ElementRef, Input} from "@angular/core";
import { NavigationService } from "../../../service/navigation.service";

@Component({
  selector: 'app-chapter-nav',
  templateUrl: './chapterNav.component.html',
  styleUrls: ['./chapterNav.component.scss'],
  host: {
    '(document:click)': 'close($event)',
  },
})
export class ChapterNavComponent {
  _pos: string;
  isOpened: boolean;

  @Input() chapterMenu: { title: string, id: string, color: string, chapters: any[]};
  @Input() set number(value: string) {
    this._pos = Number(value) < 9 ? '0' + (value + 1) : value + 1;
  }

  get number(): string {
    return this._pos;
  }

  constructor(private navService: NavigationService, private _eref: ElementRef) {}

  goToChapter(chapterId: string) {
    document.querySelector('.top_part').classList.add('hidePanelUp')
    this.navService.navigateToChapter(chapterId);
    this.navService.toggleMenu(false);
  }

  toggleSubMenu() {
    this.isOpened = !this.isOpened;
  }

  close(event) {
    if (!this._eref.nativeElement.contains(event.target))
      this.isOpened = null;
  }
}
