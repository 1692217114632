import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Message } from '../interfaces/message';

@Injectable()

export class NotificationService {

  newError = new BehaviorSubject<Message>(null);
  $newError = this.newError.asObservable();

  constructor() { }

  getNotification(type, msg) {
    this.newError.next({ type, message: msg });
  }
}
