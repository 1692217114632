import {ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../core/service/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkbookActions } from '../../../actions/workbook.actions';
import { WorkbookService } from '../../../core/service/workbook.service';
import { Observable, Subscription } from 'rxjs';
import { StoreService } from '../../../core/service/store.service';
import { IChapter, IChapterCategory, IWorkbook } from '../../../core/interfaces/IWorkbook';
import { NotificationService } from '../../../core/service/notification.service';
import { WorkbookHelpers } from '../../../core/service/workbookHelpers';
import { AttachmentService } from '../../../core/service/attachment.service';
import { BehaviorSubject } from 'rxjs';
import { NavigationService } from '../../../core/service/navigation.service';
import { VisibilityState } from '../../../core/const/visibilityState';
import { faPlus, faEye, faTimes, faSave, faReplyAll, faFileExport, faCheck } from '@fortawesome/free-solid-svg-icons';
import { ComponentCanDeactivate } from '../../../core/service/IDeactivate';
import { IAttachment } from '../../../core/interfaces/IAttachment';
import {NetworkStates} from "../../../core/const/networkStates";

@Component({
  selector: 'app-workbook-editor',
  templateUrl: './workbookEditor.component.html',
  styleUrls: ['./workbookEditor.component.scss'],
})
export class WorkbookEditorComponent implements ComponentCanDeactivate, OnDestroy {
  categories: IChapterCategory[] = [];
  wbCoverImg = '';
  wb: IWorkbook;
  wbModel: FormGroup;
  notesAlertOpened = false;
  isValid = false;
  tmp = false;
  state = VisibilityState;
  faPlus = faPlus;
  faEye = faEye;
  faTimes = faTimes;
  faSave = faSave;
  faCheck = faCheck;
  alertSubscriber: BehaviorSubject<boolean> = new BehaviorSubject(false);
  openToggle: boolean;
  disabled = false;

  workbookSubscription: Subscription;
  categoriesSubscription: Subscription;
  chaptersSubscription: Subscription;
  WBDataSubscription: Subscription;
  activatedRouteSubscription: Subscription;
  navSubscription: Subscription;
  private offlineSubscription: Subscription;


  @ViewChild('content', { static: false }) content: ElementRef;

  constructor(
    private fb: FormBuilder,
    private commonService: CommonService,
    private router: Router,
    private workbookActions: WorkbookActions,
    private workbookService: WorkbookService,
    private storeService: StoreService,
    private notifications: NotificationService,
    private activatedRoute: ActivatedRoute,
    private attachmentService: AttachmentService,
    private _fb: FormBuilder,
    private navService: NavigationService,
  ) {
    this.wbModel = _fb.group({
      title: ['', Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(255),
      ])],
      description: [''],
    });

    this.navSubscription = this.navService.navToChapter$.subscribe((id: string) => {
      if (id) {
        const el = document.getElementById(id);
        el.scrollIntoView({ block: 'start' });
      }
    });

    this.categoriesSubscription = this.storeService.getDataSubscription('workbookReducer', 'categories')
      .subscribe((categories: IChapterCategory[]) => {
        this.categories = categories;
        this.tmp = this.checkChaptersAndCatsValidation();
      });

    this.chaptersSubscription = this.storeService.getDataSubscription('workbookReducer', 'chapters')
      .subscribe((chapters: IChapter[]) => {
        this.tmp = this.checkChaptersAndCatsValidation();
      });

    this.WBDataSubscription = this.storeService.getDataSubscription('workbookReducer', 'workbook')
      .subscribe((workbook: IWorkbook) => {
        this.wb = workbook;
        this.wbCoverImg = workbook ? workbook.coverImageLink : '';

        if (this.wb) {
          this.wbModel.controls['title'].setValue(this.wb.title);
          this.wbModel.controls['description'].setValue(this.wb.description);
        }

      });

    this.activatedRouteSubscription = activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.initEdit(params.id);
      } else {
        this.initNew();
      }
    });

    this.offlineSubscription = this.storeService.getDataSubscription('utilsReducer', 'networkState')
        .subscribe((status: NetworkStates) => {
          this.disabled = status === NetworkStates.OFFLINE;
        });
  }

  initNew() {
    this.workbookSubscription = this.workbookService.createWB()
      .subscribe((wb: IWorkbook) => {
        window['wbID'] = wb.id;
        this.workbookActions.store_workbook(wb);
        this.workbookActions.store_categories(JSON.parse(JSON.stringify(wb.categories)));
        this.workbookActions.store_chapters(WorkbookHelpers.separateChapters(wb));
      });
  }

  initEdit(workbookId: string) {
    window['wbID'] = workbookId;
    localStorage.setItem('workbookid', workbookId);
    this.workbookService.fetchWorkbookBySomeId(workbookId)
      .subscribe();

    const attachmentSubscription = this.attachmentService.fetchAttachmentsByParentId(workbookId)
        .subscribe((attachments: IAttachment[]) => {
          // Store updated attachments
          this.workbookActions.add_few_attachments(attachments);
          attachmentSubscription.unsubscribe();
        });
  }

  setTitle($event): void {
    this.workbookActions.changeWBProp({ prop: 'title', value: $event.target.value });
    this.tmp = this.checkChaptersAndCatsValidation();
  }

  setDescription($event): void {
    this.workbookActions.changeWBProp({ prop: 'description', value: $event.target.value });
    this.tmp = this.checkChaptersAndCatsValidation();
  }

  addCategory(): void {
    const subscription = this.workbookService.createCategory()
      .subscribe(() => subscription.unsubscribe());

  }

  closeWBEditor(): void {
    this.router.navigate(['/shared/overview']);
  }

  changeCoverImageLink($event): void {
    const file = $event.target.files[0];
    const allowTypes = ['jpg', 'gif', 'bmp', 'jpeg', 'png'];
    const wbId = this.storeService.getData('workbookReducer', 'workbook').id;
    let storeCoverImgSubscription: Subscription;

    if (allowTypes.indexOf(file.name.split('.')[1].toLowerCase()) < 0) {
      this.notifications.getNotification('error', 'File must be image');
    } else {
      storeCoverImgSubscription = this.workbookService.uploadWBFiles(file, wbId)
        .subscribe((res: { filename: string }) => {
          this.workbookActions.changeWBProp({ prop: 'coverImageLink', value: res.filename });
          storeCoverImgSubscription.unsubscribe();
        });
    }
  }

  clearCoverImg($event) {
    $event.stopImmediatePropagation();

    this.workbookActions.changeWBProp({ prop: 'coverImageLink', value: '' });
  }

  saveWB(clearWB?: boolean, toPreview?: boolean): void {
    this.attachmentService.saveAllAttachments();

    const subscription = this.workbookService.saveWB()
        .subscribe(() => {
          subscription.unsubscribe();

          if (clearWB) {
            this.closeWBEditor();
          }

          if (toPreview) {
            this.router.navigate([`/edit/workbook/preview/${this.wb.id}`]);
          }
        });
    // const state = this.storeService.getData('surveyReducer', 'currentSurvey').state;

    // if (this.isValid && !toPreview) {
    //   const subscription = this.workbookService.saveWB()
    //       .subscribe(() => {
    //         subscription.unsubscribe();
    //
    //         if (clearWB) {
    //           this.closeWBEditor();
    //         }
    //
    //         if (toPreview) {
    //           this.router.navigate([`/edit/workbook/preview/${this.wb.id}`]);
    //         }
    //       });
    // } else if (toPreview && !this.wbChanged()) {
    //   this.router.navigate([`/edit/workbook/preview/${this.wb.id}`]);
    // } else {
    //   this.notifications.getNotification('error', 'Workbook is not valid');
    // }
  }

  complete(): void {
    if (this.isValid) {
      this.workbookActions.changeWBProp({ prop: 'state', value: 'published' });
      this.saveWB(true);
    } else {
      this.notifications.getNotification('error', 'Workbook is not valid');
    }
  }

  processUnsavedData($event): void {
    if ($event) {
      this.saveWB(false);
      this.attachmentService.saveAllAttachments();
    }

    this.alertSubscriber.next(true);
    this.notesAlertOpened = false;
    this.closeWBEditor();
  }

  checkUnsavedData(): void {
    if (this.workbookService.wbWasChanged()) {
      this.notesAlertOpened = true;
    } else {
      this.closeWBEditor();
    }
  }

  checkChaptersAndCatsValidation() {
    const categories = this.storeService.getData('workbookReducer', 'categories');
    const chapters = this.storeService.getData('workbookReducer', 'chapters');

    return categories.every(cat => cat.valid) && chapters.every(ch => ch.valid) && this.wbModel.valid;
  }

  toPreview() {
    this.saveWB(false, true);
  }

  ngAfterContentChecked() {
    this.isValid = this.tmp;
  }

  wbChanged(): boolean {
    return this.workbookService.wbWasChanged();
  }

  canDeactivate(): boolean | Observable<boolean> {
    if (this.wbChanged()) {
      this.notesAlertOpened = true;
      return this.alertSubscriber;
    }

    return true;
  }

  openMenu() {
    this.openToggle = !this.openToggle;
  }

  ngOnDestroy() {
    const role = this.storeService.getData('authReducer', 'profile').role;

    if (this.workbookSubscription) { this.workbookSubscription.unsubscribe(); }
    this.navSubscription.unsubscribe();
    this.categoriesSubscription.unsubscribe();
    this.WBDataSubscription.unsubscribe();
    if (this.activatedRouteSubscription) { this.activatedRouteSubscription.unsubscribe(); }
    if (this.offlineSubscription) this.offlineSubscription.unsubscribe();

    // clear storage
    this.workbookActions.store_chapters([]);
    this.workbookActions.store_categories([]);
    this.workbookActions.store_workbook(null);
    this.workbookActions.store_attachments([]);
  }
}
