import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RoleService } from './service/role.service';
import { RequestService } from './service/request.service';
import { AuthService } from './service/auth.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './components/header/header.component';
import { SearchComponent } from './components/search/search.component';
import { WorkbookCardComponent } from './components/workbookCard/workbookCard.component';
import { WBMenuComponent } from './components/menu/WBMenu.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreService } from './service/store.service';
import { LoaderComponent } from './components/loader/loader.component';
import { CommonService } from './service/common.service';
import { CustomSelectComponent } from './components/customSelect/customSelect.component';
import { AttachedDocComponent } from './components/attachedDoc/attachedDoc.component';
import { UploadingDocComponent } from './components/uploadingDoc/uploadingDoc.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserMenuComponent } from './components/userMenu/userMenu.component';
import { NotesComponent } from './components/notes/notes.component';
import { GlobalErrorhandlerService } from './service/globalErrorhandler.service';
import { ValidationErrorComponent } from './components/ValidationError/validationError.component';
import { CookiesPolicyComponent } from './components/coockiesPolicy/cookiesPolicy.component';
import { CookiesPopupComponent } from './components/coockiesPolicy/cookiesPopup/cookiesPopup.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { MessageComponent } from './components/message/message.component';
import { SeparatorComponent } from './components/separator/separator.component';
import { QuillModule } from 'ngx-quill';
import { GdprPopupComponent } from './components/gdprPopup/gdprPopup.component';
import { SearchService } from './service/search.service';
import { WideImgComponent } from './components/wideImg/wideImg.component';
import { SafePipe } from './pipes/safe.pipe';
import { WorkbookService } from './service/workbook.service';
import { AppliedFileComponent } from './components/appliedFile/appliedFile.component';
import { AttachmentService } from './service/attachment.service';
import { UnsavedAlertComponent } from './components/unsavedAlert/unsavedAlert.component';
import { TrackingService } from './service/tracking.service';
import { BotComponent } from './components/bot/bot.component';
import { NavigationService } from './service/navigation.service';
import { WorkbookNavComponent } from './components/workbookNav/workbookNav.component';
import { WorkbookWrapperComponent } from './components/workbookWrapper/workbookWrapper.component';
import { ChapterNavComponent } from './components/workbookNav/chapterNav/chapterNav.component';
import { ChapterComponent } from './components/chapter/chapter.component';
import { OfflineGuard } from './guards/offline.guard';
import { ButtonsService } from './service/buttons.service';
import { ButtonComponent } from './components/button/button.component';
import { SurveyService } from './service/survey.service';
import { SurveyComponent } from './components/survey/survey.component';
import { TrainingService } from './service/training.service';
import { StatisticService } from './service/statistic.service';
import { MultiselectComponent } from './components/multiselect/multiselect.component';
import { HeaderMenuComponent } from './components/headerMenu/headerMenu.component';
import { FilterService } from './service/filter.service';
import { DatePiсkerComponent } from './components/datePicker/datePicker.component';
import { NgDatepickerModule } from 'ng2-datepicker';
import { EntityExpanderComponent } from '../core/components/entityExpander/entityExpander.component';
import { RoleGuard } from './service/guards/role.guard';
import { CommentsComponent } from './components/comments/comments.component';
import { RichtextEditorComponent } from '../screens/editors/workbookEditor/richtextEditor/richtextEditor.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { CommentsListComponent } from './components/commentsList/commentsList.component';
import { CommentComponent } from './components/comment/comment.component';
import { WorkbookPreviewComponent } from './components/workbookPreview/workbookPreview.component';
import { TrainerNotesService } from './service/trainerNotes.service';
import { ExpandBtnComponent } from './components/expandBtn/expandBtn.component';
import { DeactivateGuard } from './service/guards/deactivate.guard';
import {DeferLoadModule} from "@trademe/ng-defer-load";
import {PersistenceService} from "./service/persistence.service";

@NgModule({
  imports: [
    HttpClientModule,
    FontAwesomeModule,
    TranslateModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule,
    NgDatepickerModule,
    CKEditorModule,
    DeferLoadModule
  ],
  providers: [
    RoleService,
    RequestService,
    AuthService,
    StoreService,
    CommonService,
    GlobalErrorhandlerService,
    SearchService,
    WorkbookService,
    AttachmentService,
    TrackingService,
    NavigationService,
    OfflineGuard,
    ButtonsService,
    SurveyService,
    TrainingService,
    StatisticService,
    FilterService,
    RoleGuard,
    DeactivateGuard,
    TrainerNotesService,
    PersistenceService,
  ],
  declarations: [
    HeaderComponent,
    SearchComponent,
    WorkbookCardComponent,
    WBMenuComponent,
    LoaderComponent,
    CustomSelectComponent,
    AttachedDocComponent,
    UploadingDocComponent,
    UserMenuComponent,
    NotesComponent,
    ValidationErrorComponent,
    CookiesPolicyComponent,
    CookiesPopupComponent,
    PaginationComponent,
    MessageComponent,
    SeparatorComponent,
    GdprPopupComponent,
    WideImgComponent,
    SafePipe,
    AppliedFileComponent,
    UnsavedAlertComponent,
    BotComponent,
    WorkbookNavComponent,
    WorkbookWrapperComponent,
    ChapterNavComponent,
    ChapterComponent,
    ButtonComponent,
    SurveyComponent,
    MultiselectComponent,
    HeaderMenuComponent,
    DatePiсkerComponent,
    EntityExpanderComponent,
    WorkbookPreviewComponent,
    CommentsComponent,
    RichtextEditorComponent,
    CommentsListComponent,
    CommentComponent,
    ExpandBtnComponent,
  ],
  exports: [
    FontAwesomeModule,
    HeaderComponent,
    SearchComponent,
    WorkbookCardComponent,
    LoaderComponent,
    CustomSelectComponent,
    AttachedDocComponent,
    UploadingDocComponent,
    UserMenuComponent,
    NotesComponent,
    ValidationErrorComponent,
    CookiesPopupComponent,
    CookiesPolicyComponent,
    PaginationComponent,
    MessageComponent,
    SeparatorComponent,
    GdprPopupComponent,
    WideImgComponent,
    SafePipe,
    CommonModule,
    AppliedFileComponent,
    UnsavedAlertComponent,
    BotComponent,
    WorkbookNavComponent,
    WorkbookWrapperComponent,
    ChapterNavComponent,
    ChapterComponent,
    ButtonComponent,
    SurveyComponent,
    MultiselectComponent,
    HeaderMenuComponent,
    DatePiсkerComponent,
    EntityExpanderComponent,
    CommentsComponent,
    RichtextEditorComponent,
    CommentsListComponent,
    CommentComponent,
    WorkbookPreviewComponent,
    ExpandBtnComponent,
  ],
})
export class CoreModule {}
