import { Component, Input, OnInit } from '@angular/core';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { IComment } from '../../interfaces/IComment';
import * as moment from 'moment';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit {
  faTrashAlt = faTrashAlt;

  @Input() comment: IComment | null;

  ngOnInit() {
    this.comment.created = moment(this.comment.created).format('MMM-DD-YYYY');
  }
}
