import {Roles} from '../../const/roles';
import {IMenuOption} from '../../interfaces/IMenuOption';
import {Kpi} from '../../const/kpi';

export const headerMenuModel: IMenuOption[] = [
  {
    title: 'workbook.main_menu.overview',
    allowed: [Roles.USER, Roles.CONTENT_MANAGER, Roles.TRAINER, Roles.TEAM_LEAD],
    children: [],
    disallowCountries: [],
    href: '/shared/overview',
  },
  {
    title: 'statistic.overall_kpi',
    allowed: [Roles.GLOBAL_ADMIN],
    children: [],
    disallowCountries: [],
    href: `/statistic/${Kpi.OVERALL}`,
  },
  {
    title: 'nav.workbooks',
    allowed: [Roles.TEAM_LEAD, Roles.TRAINER],
    children: [],
    disallowCountries: [],
    href: `/shared/overview/workbooks`,
  },
  { title: 'statistic.reports',
    href: '',
    allowed: [Roles.TRAINER, Roles.REGIONAL_ADMIN, Roles.TEAM_LEAD],
    disallowCountries: [],
    children: [
      {
        title: 'statistic.overall_kpi',
        allowed: [Roles.REGIONAL_ADMIN, Roles.TRAINER, Roles.TEAM_LEAD], children: [],
        href: `/statistic/${Kpi.OVERALL}`,
        disallowCountries: [],
      },
      {
        title: 'statistic.dealer_report',
        allowed: [Roles.REGIONAL_ADMIN],
        children: [],
        href: `/statistic/${Kpi.DEALER}`,
        disallowCountries: [],
      },
      {
        title: 'statistic.consultant_report',
        allowed: [Roles.REGIONAL_ADMIN],
        children: [],
        href: `/statistic/${Kpi.CONSULTANT}`,
        disallowCountries: [],
      },
      {
        title: 'statistic.training_satisfaction_reports',
        allowed: [Roles.REGIONAL_ADMIN, Roles.TEAM_LEAD],
        children: [],
        href: `/statistic/${Kpi.TRAINING}`,
        disallowCountries: [],
      },
      {
        title: 'statistic.trainer_reports',
        allowed: [Roles.REGIONAL_ADMIN, Roles.TEAM_LEAD],
        children: [],
        href: `/statistic/${Kpi.TRAINER}`,
        disallowCountries: [],
      },
    ] },
];
