import {Injectable} from "@angular/core";
import {NgRedux} from "@angular-redux/store";
import {IAppState} from "../store";
import {INote} from "../core/interfaces/INote";

@Injectable()
export class NotesActions {
  static STORE_WB_NOTES = '[Notes] fetch wb notes';
  static SET_NOTE_STATE = '[Notes] set note state by chapter id';
  static UPDATE_NOTE = '[Notes] update note';
  static SKIP_SAVED_NOTES = '[Notes] Skip saved notes';

  constructor(private ngRedux: NgRedux<IAppState>) {}

  store_wb_notes(notes: INote[]) {
    this.ngRedux.dispatch({
      type: NotesActions.STORE_WB_NOTES,
      payload: notes
    });
  }

  set_note_saved(noteSaved: {id: string, state: boolean}) {
    this.ngRedux.dispatch({
      type: NotesActions.SET_NOTE_STATE,
      payload: noteSaved
    });
  }

  updateNote(note: { id: string, text: string }) {
    this.ngRedux.dispatch({
      type: NotesActions.UPDATE_NOTE,
      payload: note
    });
  }

  skip_saved_notes() {
    this.ngRedux.dispatch({
      type: NotesActions.SKIP_SAVED_NOTES,
    })
  }
}
