import { FilterTypes } from '../core/const/filterTypes';
import { FiltersActions } from '../actions/filters.actions';
import { IKPIFilter } from '../core/interfaces/IFilter';
import * as moment from 'moment';
import { FilterService } from '../core/service/filter.service';

export interface FiltersStore {
  filters: IKPIFilter;
  currentFilters: object;
  currentSubfilter: object;
  period: { dateFrom: string, dateTo: string };
}

export const initialFilters = {};
initialFilters[FilterTypes.GR] = { property: 'title', values: [] };
initialFilters[FilterTypes.COUNTRY] = { property: 'code', values: [] };
initialFilters[FilterTypes.CR] = { property: 'code', values: [] };
initialFilters[FilterTypes.DEALER] = { property: 'code', values: [] };
initialFilters[FilterTypes.WORKBOOK] = { property: 'id', values: [] };
initialFilters[FilterTypes.TRAINING] = { property: 'id', values: [] };

export const initialFiltersState = {
  filters: null,
  currentFilters: { ...initialFilters },
  currentSubfilter: {},
  period: {
    dateFrom: `${moment().subtract(1, 'month').format('YYYY-MM-DD')}T00:00:00Z`,
    dateTo: `${moment().format('YYYY-MM-DD')}T00:00:00Z`,
  },
};

export const filtersReducer = (state: FiltersStore = initialFiltersState, action: any) => {
  switch (action.type) {
    case FiltersActions.INIT_FILTERS:

      return {
        ...state,
        filters: action.payload,
      };

    case FiltersActions.CHANGE_CURRENT_VALUES:

      return {
        ...state,
        currentFilters: updateCurrentFilters({ ...state.currentFilters }, action.payload.filterType, [...action.payload.values]),
      };

    case FiltersActions.CHANGE_CURRENT_SUBFILTER:

      return {
        ...state,
        currentSubfilter: action.payload,
      };

    case FiltersActions.CHANGE_START_DATE:

      return {
        ...state,
        period: {
          ...state.period,
          dateFrom: action.payload,
        },
      };

    case FiltersActions.CHANGE_END_DATE:

      return {
        ...state,
        period: {
          ...state.period,
          dateTo: action.payload,
        },
      };

    case FiltersActions.CHANGE_PERIOD:

      return {
        ...state,
        period: { ...action.payload },
      };

    case FiltersActions.SKIP_TO_INITIAL:

      return {
        ...initialFiltersState,
      };

    default:

      return {
        ...state,
      };
  }
};

const updateCurrentFilters = (currentFilters, changedFilterName: string, changedFilterValues: any[]): object => {
  const updatedCurrentFilters = JSON.parse(JSON.stringify(currentFilters));
  const i = Object.keys(currentFilters).indexOf(changedFilterName);

  if (changedFilterName !== 'period') {
    updatedCurrentFilters[changedFilterName].values = JSON.parse(JSON.stringify(changedFilterValues));

    Object.keys(currentFilters).forEach((key: string, index: number) => {
      if (i < index) {
        updatedCurrentFilters[key].values = [FilterService.initAllValue(key)];
      }
    });
  }

  return updatedCurrentFilters;
};
