import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
  selector: 'app-open-question',
  templateUrl: './openQuestion.component.html',
  styleUrls: ['./openQuestion.component.scss']
})
export class OpenQuestionComponent {
  @Input() value: string;
  @Output() changed: EventEmitter<string> = new EventEmitter<string>();

  questionChanged($event) {
    this.changed.emit($event.target.value);
  }
}
